<template>
  <!-- SECTION Alarms -->
  <div id="AlarmsSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container">

      <!-- HEADER Alarms -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-menu border-1 rounded-5 box sombra">
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
              <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              <span>{{ dictionary["Alarmas"][session.user.language] }}</span>
            </h6>
          </div>
          <div class="p-3 pt-0">
            <!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">
              <hr v-if="this.$route.params.currentDisplay === 'alarms'" class="m-0 p-0">
              <select v-if="this.$route.params.currentDisplay === 'alarms'" :value="currentCompany.nifCif"
                class="form-control mt-3 mb-3 h-100 text-start text-nowrap bg-white"
                style="border: solid 2px white; color: black;" @change="onCurrentCompanyChanged">
                <option class="text-uppercase p-4 bg-clomos tx-bold text-white"
                  v-if="availableCompanies[0] != undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option class="text-capitalize" v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined && company.relation.referenceCompany === this.session.user.nifCif"
                  :value="company.nifCif">
                  {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="p-3 pt-0">
            <!-- Selector de Activo -->
            <div class="col-12 m-0 p-0">
              <hr v-if="this.$route.params.currentDisplay === 'alarms'" class="m-0 p-0">
              <select v-if="this.$route.params.currentDisplay === 'alarms'" v-model="selectedAsset"
                class="form-control mt-3 mb-3 h-100 text-start text-nowrap bg-white pe-2"
                style="border: solid 2px white; color: black;" @change="onAssetSelected">
                <option value="" class="text-uppercase p-4 bg-clomos tx-bold text-white" disabled>
                  {{ dictionary["Seleccione un activo"][session.user.language] }}
                </option>
                <option class="text-capitalize p-1" v-for="asset in assetsList" :key="asset.id">
                  {{ asset.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!-- Main content -->
      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 mb-3 sombra">
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1">
            <ion-icon name="alert-circle-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{ "Alarmas activas" }}
          </div>
          <!-- KPI Row -->
          <div class="row p-0 m-0 justify-content-center">
            <div class="col-lg-5 col-md-12 p-0 pt-0">
              <div class="col-12 p-2 pt-0 mb-3">
                <div class="text-center p-1 pb-2 mt-2 rounded-5 border bg-danger sombra">
                  <span class="col-5 kpi-title tx-18 text-end tx-bold text-white mt-2">Alarmas activas:</span>
                  <span class="col-5 kpi-value tx-24 tx-bold mb-0 text-danger bg-white ps-3 border border-white">

                    {{
                (activeAlarmsList.length > 0 ? activeAlarmsList.length : "0") + "/" + (alarmsList.length > 0
                  ? alarmsList.length : "0")
              }}

                  </span>
                </div>
              </div>

              <div class="table-responsive ps-2" style="overflow: hidden;">
                <table id="ActiveAlarmsDataTable" class="col-12 m-0 p-0 dataTables_wrapper no-footer">
                  <thead class="gradient-tablas tx-14 text-white text-center"
                    style="position: -webkit-sticky; position: sticky; top: 0; background-color: #343a40; z-index: 1;">
                    <tr>
                      <th v-for="column in activeAlarmColumns" :key="column.data" :width="column.width"
                        class="fw-medium text-nowrap p-1">
                        {{ dictionary[column.text][session.user.language] }}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="col-12 mb-3 mt-5 ps-4 pe-3">
                <p class="text-start tx-16 tx-bold ps-3 pe-3 mb-2 m-0 p-0 w-100 rounded-top text-decoration-underline">
                  {{ dictionary["Evolucion"][session.user.language] + ": " + (
                targetActiveAlarm.label ? targetActiveAlarm.label : "") }}</p>
                <VueEcharts :option="historicActiveAlarmlineChartOptions" id="Chart1Canvas" class=""
                  style="height: 168px;"></VueEcharts>
              </div>
            </div>

            <div class="col-lg-7 col-md-12 mt-0">
              <!-- Resumen de la alarma -->
              <div class="col-12 mt-1 pt-0 m-0 p-0">
                <div class="border border-start-1 border-end-0 border-top-0 border-bottom-0 rounded pb-5 pt-2">
                  <span class="tx-18 tx-bold">{{ dictionary["Alarma"][session.user.language] + ' ' +
                targetActiveAlarm.label != undefined ? targetActiveAlarm.label : '-' }}</span>
                  <div class="col-12 mb-0 mt-3">
                    <table class="table table-bordered table-striped text-start">
                      <tbody>
                        <tr class="p-2" style="display: table-row;">
                          <th class="p-2 ps-3" style="width: 25% !important;" scope="row">
                            {{ dictionary["FechaActivacion"][session.user.language] }}</th>
                          <td class="p-2" v-if="targetActiveAlarm.statusDate">
                            {{ formatDateTime(targetActiveAlarm.statusDate) }} </td>
                        </tr>
                        <tr>
                          <th class="p-2 ps-3" style="width: 25% !important;" scope="row">{{ dictionary["Tipo incidencia"][session.user.language]}}</th>
                          <td class="p-2"> {{
                  targetActiveAlarm.incidenceType && targetActiveAlarm.incidenceType != '0' ?
                    targetActiveAlarm.incidenceType : '-'
                }}</td>
                        </tr>
                        <tr>
                          <th class="p-2 ps-3" scope="row">{{ dictionary["IDIncidenciaAbierta"][session.user.language] }}
                          </th>
                          <td class="p-2">
                            <span @click="toggleHistoricIncidenceModal(targetActiveAlarm.incidence)">{{
                targetActiveAlarm.incidence ? targetActiveAlarm.incidence : '-' }}
                              <ion-icon v-if="targetActiveAlarm.incidence" name="link-outline"
                                class="tx-20 ms-4 me-2 ps-1 pe-1 border border-danger align-top text-danger tx-bold md hydrated"></ion-icon>
                            </span>
                            <span
                              class="tx-12 ms-2 me-2 pb-1 ps-2 pe-2 border border-dark align-top text-dark tx-bold md hydrated"
                              v-if="targetActiveAlarm.historicIncidences && targetActiveAlarm.historicIncidences.length > 0"
                              @click="displayhistoricIncidences = !displayhistoricIncidences">
                              {{ displayhistoricIncidences ? 'ver menos' : 'ver más' }}
                            </span>
                          </td>
                        </tr>
                        <template v-if="displayhistoricIncidences">
                          <tr>
                            <td colspan="2">
                              <strong>{{ dictionary["IncidenciasAbiertasUltimas48Horas"][session.user.language] }}</strong>
                            </td>
                          </tr>
                          <tr>
                            <th>{{ dictionary["Fecha"][session.user.language] }}</th>
                            <th>{{ dictionary["NumeroIncidencia"][session.user.language] }}</th>
                          </tr>
                          <tr v-for="(item, index) in targetActiveAlarm.historicIncidences" :key="index">
                            <td>
                              {{ formatDateTime(item[0]) }}
                            </td>
                            <td  @click="toggleHistoricIncidenceModal(item[1])" style="cursor: pointer;">
                              {{ item[1] }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 mb-3 mt-5">
                    <span class="text-center tx-18 mb-2">
                      {{ (targetActiveAlarm.variableLabel ?
                targetActiveAlarm.variableLabel : "") + " : " + (targetActiveAlarm.valueNow ?
                  targetActiveAlarm.valueNow
                  : '-') }}
                    </span>
                    <VueEcharts :option="activeAlarmLineChartOptions" id="Chart2Canvas" class="border"
                      style="height: 400px;">
                    </VueEcharts>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- BLOQUE SEGUNDO -->

        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 mb-3 sombra" style="overflow: hidden;">
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1">
            <ion-icon name="alert-circle-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{ "Todas las alarmas" }}
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-12 mt-0 p-2 ps-4">
              <div class="text-center p-1 mt-2 mb-3 rounded-5 border bg-success sombra">
                <button class="col-5 rounded-5 bg-success border border-white p-2">
                  <span @click="toggleNewCondition">
                    <ion-icon name="push" class="text-white pt-0 me-2 tx-24 align-bottom hydrated"></ion-icon>
                    Nueva Condición</span>
                </button>
              </div>

              <div class="table-responsive">
                <table id="AlarmsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                  <thead class="gradient-tablas tx-14 text-white text-center"
                    style="position: sticky; top: 0; background-color: #343a40; z-index: 1;">
                    <tr>
                      <th v-for="column in alarmColumns" :key="column.data" :width="column.width"
                        class="fw-medium text-nowrap p-2">
                        {{ dictionary[column.text][session.user.language] }}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="col-12 mb-3 mt-5">
                <p class="text-start tx-16 tx-bold ps-3 pe-3 mb-2 m-0 p-0 w-100 rounded-top text-decoration-underline">
                  {{
                dictionary["Evolucion"][session.user.language] + ": " + (
                  targetAlarm.label ? targetAlarm.label : "") }}
                </p>
                <VueEcharts :option="historicalarmlineChartOptions" id="Chart2Canvas" class="mt-3"
                  style="height: 168px;">
                </VueEcharts>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 mt-0 pt-0">
              <div class="col-12 mt-1">
                <div class=" rounded pb-5 pt-2">
                  <span class="tx-18 tx-bold"> {{ dictionary["AlarmaSeleccionada"][session.user.language] + ':' +
                targetAlarm.label
                    }}</span>
                  <div class="row border border-start-1 border-end-0 border-top-0 border-bottom-0 p-3">
                    <div class="col-md-12 " :class="firefox ? 'col-lg-2' : 'col-lg-4'">
                      <input type="datetime-local" v-model="targetAlarm.startDateTime"
                        class=" form-control border p-2 pt-3 pb-3 rounded-2 text-nowrap bg-white"
                        style="border: solid 1px white ; color: black;" :disabled="!targetAlarm">
                    </div>
                    <div v-if="firefox" class="col-lg-2 col-md-12 ">
                      <input type="time" v-model="targetAlarm.startTime"
                        class=" form-control border p-2 pt-3 pb-3 rounded-2 text-nowrap bg-white"
                        style="border: solid 1px white ; color: black;" :disabled="!targetAlarm">
                    </div>
                    <div class="col-md-12 " :class="firefox ? 'col-lg-2' : 'col-lg-4'">
                      <input type="datetime-local" v-model="targetAlarm.endDateTime"
                        class=" form-control  border rounded-2 p-2 pt-3 pb-3 text-nowrap bg-white"
                        style="border: solid 2px white ; color: black;" :disabled="!targetAlarm">
                    </div>
                    <div v-if="firefox" class="col-lg-2 col-md-12">
                      <input type="time" v-model="targetAlarm.endTime"
                        class=" form-control border p-2 pt-3 pb-3 rounded-2 text-nowrap bg-white"
                        style="border: solid 1px white ; color: black;" :disabled="!targetAlarm">
                    </div>
                    <div class="col-lg-4 col-md-12 m-0 p-0">
                      <button id="btnDisplayNewMachineModal"
                        :disabled="!targetAlarm.startDateTime && !targetAlarm.endDateTime" type="button"
                        class="button-ok gradient-tablas rounded-3" @click="searchSelectedFilter">
                        <span class="button-text text-white">
                          <ion-icon name="search-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                          <span class="text-white mt-0 text-nowrap">{{ dictionary["Buscar"][session.user.language]
                            }}</span>
                        </span>
                      </button>
                    </div>
                    <div class="col-12 mb-3 mt-5">
                      <span class="text-center tx-18 mb-2">
                        {{ (targetAlarm.variableLabel ?
                targetAlarm.variableLabel : "-") + ": " + (targetAlarm.valueNow ?
                  targetAlarm.valueNow :
                  '-') }}
                      </span>
                      <VueEcharts :option="alarmLineChartOptions" id="Chart2Canvas" class="border"
                        style="height: 440px;">
                      </VueEcharts>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- MODAL Historic Modal -->
  <HistoricIncidenceModal v-if="displayHistoricIncidence" :session="session" :dictionary="dictionary"
    :countries="countries" :timeZones="timeZones" :currentCompany="currentCompany"
    :availableCompanies="availableCompanies" :incidenceId="incidenceNumber"
    v-on:toggleHistoricIncidenceModal="toggleHistoricIncidenceModal">

  </HistoricIncidenceModal>

  <!-- MODAL New Condition Modal -->

  <NewConditionModal v-if="displayNewCondition" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :incidenceId="incidenceNumber" :dcl="dcl[0] ? dcl[0].id : ''" v-on:toggleCreateConditionModal="toggleNewCondition">

  </NewConditionModal>
  <!-- MODAL Edit conditon  -->

  <EditConditionModal v-if="displayEditCondition" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :idCondition="targetConditionId" :idDcl="dcl[0] ? dcl[0].id : ''" v-on:toggleEditCondition="toggleEditCondition">

  </EditConditionModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="displayDeleteCondition" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Condicion a eliminar'][session.user.language] + ': '" :alertTarget="targetConditionId"
    v-on:acceptAlert="onDeleteCondition" v-on:dismissAlert="toggleDeleteConditionModal">
  </TextAlertModal>

</template>

<script>
import { VueEcharts } from 'vue3-echarts';
// Clomos
import api from '@/services/api';
import $ from 'jquery';

import HistoricIncidenceModal from '@/components/incidences/HistoricIncidenceModal'
import NewConditionModal from '@/components/conditions/NewConditionModal'
import EditConditionModal from '@/components/conditions/EditConditionModal'
import TextAlertModal from '@/components/other/TextAlertModal';

export default {
  name: "AlarmsComponent",
  components: { VueEcharts, HistoricIncidenceModal, NewConditionModal, EditConditionModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      dcl: '',
      alarmColumns: [
        {
          text: "Seleccione Alarma", data: "alarmId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<input type='radio' name='select-alarmFromList' value='" + data + "' class='radio-select-alarm btnDetailAlarmFromList'>";
          }
        },
        {
          text: "Alarma", data: "alarmName", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            return data;
          }
        },
        {
          text: "Estado", data: "alarmStatus", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let text;
            switch (data) {
              case "0":
                text = this.dictionary["Desactivado"][this.session.user.language];
                break;
              case "1":
                text = ("pre" + this.dictionary["Activado"][this.session.user.language].toLowerCase());
                break;
              case "2":
                text = this.dictionary["Activado"][this.session.user.language];
                break;
              case "3":
                text = ("post" + this.dictionary["Activado"][this.session.user.language].toLowerCase());
                break;
              default:
                text = data.status;
            }
            return text
          }
        },
        {
          text: "Configuración", data: "alarmId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id=" + data + " class='btnEditCondition btn tx-24 tx-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "alarmId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteCondition btn tx-24 tx-danger'></ion-icon>"
          }
        },

      ],
      activeAlarmColumns: [
        {
          text: "Seleccione Alarma", data: "activeAlarmId", width: "20%", className: "align-middle",
          render: (data) => {
            return "<input type='radio' name='select-alarm' value='" + data + "' class='radio-select-alarm btnDetailAlarm p-0'>";
          }
        },
        {
          text: "Alarma", data: "activeAlarmName", width: "40%", className: "tx-left align-middle p-0",
          render: (data) => {
            return data;
          }
        },
        {
          text: "Fecha Activacion", data: "activationDate", width: "27%", className: "tx-left align-middle p-0",
          render: (data) => {

            return (api.parseDateUserTimezone(data, this.session.user, this.$dayjs))
          }
        },
        {
          text: "Silenciar Alarma",
          data: "activeAlarmSilenced",
          width: "5%",
          className: "align-middle",
          render: (data) => {
            const iconName = data.isMuted === "true" ? 'volume-high-outline' : 'volume-mute-outline';

            return (
              `<ion-icon name="${iconName}" data-id="${data.id}" class="btnChangeIsMuted btn tx-18 text-black m-0 p-0"></ion-icon>`
            );
          }
        },

      ],
      incidenceNumber: null,
      incidenceList: {},
      targetActiveAlarmId: null,
      selectedAsset: '',
      selectedCompany: null,
      displayHistoricIncidence: false,
      displayNewCondition: false,
      displayEditCondition: false,
      displayDeleteCondition: false,
      firefox: false,
      assetsList: {},
      alarmsList: {},
      incidence: {},
      activeAlarmsList: {},
      preActive: {},
      postActive: {},
      targetEmplacementData: {},
      targetActiveAlarm: "",
      targetConditionId: "",
      targetAlarm: "",
      alarmLineChartOptions: {
        toolbox: {
          feature: {
            dataView: {
              readOnly: true
            },
            dataZoom: {
              show: true,
              yAxisIndex: null
            },
            magicType: {
              type: ['line', 'bar']
            },
            saveAsImage: {
              pixelRatio: 2
            }
          }
        }
      },
      historicalarmlineChartOptions: {
        toolbox: {
          feature: {
            dataView: {
              readOnly: true
            },
            dataZoom: {
              show: true,
              yAxisIndex: null
            },
            magicType: {
              type: ['line', 'bar']
            },
            saveAsImage: {
              pixelRatio: 2
            }
          }
        }
      },
      activeAlarmLineChartOptions: {
        toolbox: {
          feature: {
            dataView: {
              readOnly: true
            },
            dataZoom: {
              show: true,
              yAxisIndex: null
            },
            magicType: {
              type: ['line', 'bar']
            },
            saveAsImage: {
              pixelRatio: 2
            }
          }
        }
      },
      historicActiveAlarmlineChartOptions: {
        toolbox: {
          feature: {
            /*dataView: {
                readOnly: true 
            },
            dataZoom: {
                show: true,
                yAxisIndex: null
            },
            magicType: {
                type: ['line', 'bar'] 
            },
            saveAsImage: {
                pixelRatio: 2 
            }*/
          }
        }
      },
      displayhistoricIncidences: false,
    }
  },
  watch: {

    'targetAlarm.startTime': {
      handler(newTime) {
        if (newTime) {
          let currentStartDate = this.targetAlarm.startDateTime || new Date().toISOString();
          currentStartDate = new Date(currentStartDate);
          const datePart = currentStartDate.toISOString().split('T')[0];
          this.targetAlarm.startDateTime = `${datePart}T${newTime}`;

        }
      },
      deep: true,
    },


    'targetAlarm.startDateTime': {
      handler(newDateTime) {
        if (newDateTime) {
          const dateObject = new Date(newDateTime);
          const timePart = dateObject.toTimeString().split(' ')[0];
          this.targetAlarm.startTime = timePart;
        }
      },
      deep: true,
    },
    'targetAlarm.endDateTime': {
      handler(newDateTime) {
        if (newDateTime) {
          const dateObject = new Date(newDateTime);
          const timePart = dateObject.toTimeString().split(' ')[0];
          this.targetAlarm.endTime = timePart;

        }
      },
      deep: true,
    },
    'targetAlarm.endTime': {
      handler(newTime) {
        if (newTime) {
          let currentEndDate = this.targetAlarm.endDateTime || new Date().toISOString();
          currentEndDate = new Date(currentEndDate);

          const datePart = currentEndDate.toISOString().split('T')[0];
          this.targetAlarm.endDateTime = `${datePart}T${newTime}`;

        }
      },
      deep: true,
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {

    //DISPLAY
    async toggleDeleteConditionModal() {
      this.displayDeleteCondition = !this.displayDeleteCondition
      if (this.dcl && this.dcl[0].id && !this.displayDeleteCondition) {
        await this.getAlarmsbyDcl(this.dcl)
        this.displayActiveAlarmsDataTable()
        this.displayAlarmsDataTable()
      }
    },
    async toggleNewCondition() {
      this.displayNewCondition = !this.displayNewCondition
      if (this.dcl && this.dcl[0].id && !this.displayNewCondition) {
        await this.getAlarmsbyDcl(this.dcl)
        this.displayActiveAlarmsDataTable()
        this.displayAlarmsDataTable()
      }

    },
    async toggleEditCondition() {
      this.displayEditCondition = !this.displayEditCondition
      if (!this.displayEditCondition) {
        await this.getAlarmsbyDcl(this.dcl)
        this.displayActiveAlarmsDataTable()
        this.displayAlarmsDataTable()
      }

    },
    toggleHistoricIncidenceModal(idIncidence) {
      this.incidenceNumber = idIncidence
      this.displayHistoricIncidence = !this.displayHistoricIncidence;
    },
    async displayVariablesChartOptions(displayData) {
      let variableHistoricData = [];
      try {
        let response = await api.getMachineVariableHistory(displayData);
        if (response["status-code"] === "200") {
          variableHistoricData = api.parseVariableDataFromHistoryResponse(response);
          let value = parseFloat(variableHistoricData.value).toFixed(2);
          let unit = variableHistoricData.unit;
          let resultado = `${value} ${unit}`;
          if (displayData.active === true) {
            this.targetActiveAlarm.valueNow = resultado
          } else {
            this.targetAlarm.valueNow = resultado
          }
        } else {
          throw new Error(`Unexpected status code: ${response["status-code"]}`);
        }
      } catch (error) {
        //console.error(error);
      }
      const dates = variableHistoricData.history.map(item => item[0]);
      const statusDateTimestamp = new Date(displayData.statusDate).getTime();
      if (variableHistoricData.type == "1") {
        const dataValues = variableHistoricData.history.map(item => item[1]);
        const lowerLimit = displayData.hasLowerLimit === "true" ? parseInt(displayData.lowerLimit) : null;
        const upperLimit = displayData.hasUpperLimit === "true" ? parseInt(displayData.upperLimit) : null;
        const margin = 10;
        const minValue = Math.floor(
          Math.min(
            ...dataValues,
            lowerLimit !== null ? lowerLimit : Infinity
          ) - margin
        );

        const maxValue = Math.ceil(
          Math.max(
            ...dataValues,
            upperLimit !== null ? upperLimit : -Infinity
          ) + margin
        );



        const lineChartOptions = {
          toolbox: {
            feature: {
              dataView: {
                readOnly: true
              },
              dataZoom: {
                show: true,
                yAxisIndex: null
              },
              magicType: {
                type: ['line', 'bar']
              },
              saveAsImage: {
                pixelRatio: 2
              }
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'time',
            data: dates.map(date => new Date(date).getTime()),
            axisLabel: {
              formatter: value => {
                const date = new Date(value);
                // Formateo de fecha y hora con ceros a la izquierda
                return String(date.getDate()).padStart(2, "0") + "/" +
                  String(date.getMonth() + 1).padStart(2, "0") + "/" +
                  String(date.getFullYear()) + " \n " +
                  String(date.getHours()).padStart(2, "0") + ":" +
                  String(date.getMinutes()).padStart(2, "0");
              },
              margin: 10,
              fontSize: 10,
            },
            axisTick: {
              alignWithLabel: true
            }
          },
          yAxis: {
            type: 'value',
            min: minValue,
            max: maxValue,
            axisLabel: {
              formatter: `{value} ${variableHistoricData.unit}`
            }
          },
          grid: {
            top: '10%',
            left: '16%',
            right: '16%'
          },
          series: [
            {
              name: displayData.variableLabel,
              type: 'line',
              data: dataValues.map((value, index) => [new Date(dates[index]).getTime(), value]),
              symbol: 'none',
              markLine: {
                data: [
                  ...(displayData.hasUpperLimit === "true" ? [{
                    yAxis: parseInt(displayData.upperLimit),
                    name: 'Límite superior',
                    lineStyle: {
                      type: 'dashed',
                      color: 'red',
                      width: 2
                    }
                  }] : []),
                  ...(displayData.hasLowerLimit === "true" ? [{
                    yAxis: parseInt(displayData.lowerLimit),
                    name: 'Límite inferior',
                    lineStyle: {
                      type: 'dashed',
                      color: 'red',
                      width: 2
                    }
                  }] : []),
                  {
                    xAxis: statusDateTimestamp,
                    name: 'Alarma',
                    lineStyle: {
                      type: 'solid',
                      color: 'red',
                      width: 2
                    }
                  }
                ],
                symbol: 'none',
                label: {
                  formatter: params => params.name
                }
              }
            }
          ]

        };

        return lineChartOptions;
      } else if (variableHistoricData.type == "0") {
        const dataValuesDigital = variableHistoricData.history.map(item => item[1] === "true" ? 1 : 0);
        const lineChartOptions = {
          toolbox: {
            feature: {
              dataView: {
                readOnly: true
              },
              dataZoom: {
                show: true,
                yAxisIndex: null
              },
              magicType: {
                type: ['line', 'bar']
              },
              saveAsImage: {
                pixelRatio: 2
              }
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'time',
            boundaryGap: false,
            data: dates.map(date => new Date(date).getTime()),
            axisLabel: {
              formatter: value => {
                const date = new Date(value);
                return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}/n ${date.getHours()}:${date.getMinutes()}`;
              },

            }
          },
          yAxis: {
            type: 'value',
            min: 0,
            max: 1,
            axisLabel: {
              formatter: `{value} ${variableHistoricData.unit}`
            }
          },
          series: [
            {
              name: displayData.variableLabel,
              type: 'line',
              data: dataValuesDigital.map((value, index) => [new Date(dates[index]).getTime(), value]),
              symbol: 'none',
              markLine: {
                data: [
                  {
                    xAxis: statusDateTimestamp,
                    name: 'Alarma',
                    lineStyle: {
                      type: 'solid',
                      color: 'red',
                      width: 2
                    }
                  }
                ],
                symbol: 'none',
                label: {
                  formatter: params => params.name
                }
              }
            }
          ]
        };
        return lineChartOptions;
      }

    },
    displayHistoricChartOptions(chartVariableDisplayData) {

      const stateMapping = {
        0: 'Desactivada',
        1: 'Preactivada',
        2: 'Activada',
        3: 'Postactivada',
      };


      const orderedStates = [0, 1, 3, 2];


      const dates = chartVariableDisplayData.history.map(item => {
        const date = new Date(item[0]);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
      });


      const dataValues = chartVariableDisplayData.history.map(item => Number(item[1]));


      const lineChartOptions = {

        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dates,
          axisLabel: {
            rotate: 15,
            margin: 10,
            fontSize: 9
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: orderedStates.map(state => stateMapping[state]),
          axisLabel: {
            formatter: value => value,
            margin: 10,
            fontSize: 12
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          left: '16%',
          right: '3%',
          top: '1%',
          bottom: '19%'
        },
        series: [
          {
            name: 'Estado',
            type: 'line',
            data: dataValues.map((value, index) => [dates[index], stateMapping[value]]),
            smooth: false,
            step: 'start',
            itemStyle: {
              color: 'red'
            },
            lineStyle: {
              color: '#CCCCCC'
            }
          }
        ],
      };

      return lineChartOptions;
    },
    displayActiveAlarmsDataTable() {
      const table = $("#ActiveAlarmsDataTable").DataTable();

      table.clear();

      for (let i in this.activeAlarmsList) {
        table.rows.add([{
          activeAlarmId: this.activeAlarmsList[i].id,
          activeAlarmName: this.activeAlarmsList[i].label,
          activationDate: this.activeAlarmsList[i].statusDate,
          activeAlarmSilenced: {
            isMuted: this.activeAlarmsList[i].isMuted,
            id: this.activeAlarmsList[i].id
          }
        }]);
      }

      table.draw();
      this.setButtonFunctions();

      setTimeout(() => {
        const firstRadioBtn = $("#ActiveAlarmsDataTable input[type='radio']").first();
        if (firstRadioBtn.length) {
          const firstRadioValue = firstRadioBtn.val();
          firstRadioBtn.prop("checked", true).trigger('click');
          this.onDetailActiveAlarm(firstRadioValue);
        }
      }, 300);
      if ($("#ActiveAlarmsDataTable_paginate").get()[0] != undefined) {
        $("#ActiveAlarmsDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
      }
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    displayAlarmsDataTable() {
      $("#AlarmsDataTable").DataTable().clear()
      for (let i in this.alarmsList) {
        $("#AlarmsDataTable").DataTable().rows.add([{
          alarmId: this.alarmsList[i].id,
          alarmName: this.alarmsList[i].label,
          alarmStatus: this.alarmsList[i].status,
        }]);
      }


      $("#AlarmsDataTable").DataTable().draw();
      this.setButtonFunctions();
      setTimeout(() => {
        const firstRadioBtn = $("#AlarmsDataTable input[type='radio']").first();
        if (firstRadioBtn.length) {
          const firstRadioValue = firstRadioBtn.val();
          firstRadioBtn.prop("checked", true).trigger('click');
          this.onDetailAlarm(firstRadioValue);
          const now = new Date();
          this.targetAlarm.startDateTime = new Date(now.getTime() - 48 * 60 * 60 * 1000)
            .toISOString().replace('T', ' ').substr(0, 23);
          this.targetAlarm.endDateTime = now.toISOString().replace('T', ' ').substr(0, 23);
          this.searchSelectedFilter()
        }
      }, 300);
      if ($("#AlarmsDataTable_paginate").get()[0] != undefined) $("#AlarmsDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });

    },

    onEditCondition(event) {
      this.targetConditionId = event.target.getAttribute('data-id');
      this.toggleEditCondition()
    },
    async onDeleteCondition(event) {
      if (!this.displayDeleteCondition) {
        this.targetConditionId = event.target.getAttribute('data-id');
      } else {
        let response = await api.deleteCondition(this.targetConditionId)
        if (response["status-code"] === "200") {
          window.alert(this.dictionary["La condicion ha sido eliminada satisfactoriamente."][this.session.user.language]);
        }
        else {
          window.alert(this.dictionary["Error al eliminar la condicion."][this.session.user.language]);
        }
      }
      await this.toggleDeleteConditionModal()




    },
    async onDetailActiveAlarm(activeAlarmId) {
      this.targetActiveAlarm = "";
      this.targetActiveAlarmId = activeAlarmId;
      this.targetActiveAlarm = this.activeAlarmsList.find((item) => item.id === this.targetActiveAlarmId);
      const startDataTimeHistorical = new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() - 48)).toISOString().replace('T', ' ').substr(0, 23)
      const endDateTimeHistorical = new Date().toISOString().replace('T', ' ').substr(0, 23)
      const dataforHistorical = {
        id: this.targetActiveAlarm.id,
        startDataTimeHistorical: startDataTimeHistorical,
        endDateTimeHistorical: endDateTimeHistorical
      }
      const filteredIncidence = [...this.incidence].filter((item) => item.id === this.targetActiveAlarm.incidenceType);
      if (filteredIncidence.length > 0) {
        this.targetActiveAlarm.incidenceType = filteredIncidence[0].label
      }

      const getHistoricalofCondition = await this.getHistoricalofCondition(dataforHistorical)
      this.targetActiveAlarm.historicIncidences = getHistoricalofCondition
        .filter(item => item[2].length > 0 && item[2] !== this.targetActiveAlarm.incidence)
        .reduce((uniqueItems, item) => {
          if (!uniqueItems.some(existingItem => existingItem[1] === item[2])) {
            uniqueItems.push([item[0], item[2]]);
          }
          return uniqueItems;
        }, []);
      let idDCL = this.assetsList.filter((item) => item.label === this.selectedAsset);
      let chartVariableDisplayData = {
        active: true,
        variableLabel: this.targetActiveAlarm.variableLabel,
        variableType: this.targetActiveAlarm.type === "0" ? "1" : "0", //modificar cuando se añadan las condiciones dinamicas      
        status: this.targetActiveAlarm.status,
        statusDate: this.targetActiveAlarm.statusDate,
        id: idDCL[0].id,
        startDateTime: new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() - 1)).toISOString().replace('T', ' ').substr(0, 23),
        endDateTime: new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() + 47)).toISOString().replace('T', ' ').substr(0, 23),
        startDataTimeHistorical: startDataTimeHistorical,
        endDateTimeHistorical: endDateTimeHistorical,
        limit: 0,
        history: getHistoricalofCondition
      };


      if (this.targetActiveAlarm.type === "0") {
        chartVariableDisplayData.upperLimit = this.targetActiveAlarm.upperLimit;
        chartVariableDisplayData.lowerLimit = this.targetActiveAlarm.lowerLimit;
        chartVariableDisplayData.hasUpperLimit = this.targetActiveAlarm.hasUpperLimit;
        chartVariableDisplayData.hasLowerLimit = this.targetActiveAlarm.hasLowerLimit;
      } else if (this.targetActiveAlarm.type === "1") {
        chartVariableDisplayData.isActive = this.targetActiveAlarm.isActive;
      }
      this.activeAlarmLineChartOptions = await this.displayVariablesChartOptions(chartVariableDisplayData);
      this.historicActiveAlarmlineChartOptions = await this.displayHistoricChartOptions(chartVariableDisplayData);

    },
    async onDetailAlarm(alarmId) {
      const copyTargetAlarm = JSON.parse(JSON.stringify(this.targetAlarm));

      this.targetAlarm = {};

      this.targetAlarmId = alarmId;
      const newTargetAlarm = this.alarmsList.find((item) => item.id === this.targetAlarmId);

      this.targetAlarm = { ...newTargetAlarm };

      this.targetAlarm.active = false;
      const filteredIncidence = [...this.incidence].filter((item) => item.id === this.targetAlarm.incidenceType);
      if (filteredIncidence.length > 0) {
        this.targetAlarm.incidenceType = filteredIncidence[0].label;
      }
      this.targetAlarm.startDateTime = copyTargetAlarm.startDateTime;
      this.targetAlarm.endDateTime = copyTargetAlarm.endDateTime;
      this.searchSelectedFilter()
    },
    async searchSelectedFilter() {
      const startDateTime = new Date(this.targetAlarm.startDateTime);
      const endDateTime = new Date(this.targetAlarm.endDateTime);
      let startDataTimeHistorical, endDateTimeHistorical;
      if (!isNaN(startDateTime) && !isNaN(endDateTime)) {
        startDataTimeHistorical = new Date(startDateTime.setHours(startDateTime.getHours()))
          .toISOString().replace('T', ' ').substr(0, 23);
        endDateTimeHistorical = new Date(endDateTime.setHours(endDateTime.getHours()))
          .toISOString().replace('T', ' ').substr(0, 23);
      } else {
        //console.error('Invalid date detected:', { startDateTime, endDateTime });
        return;
      }
      const dataforHistorical = {
        id: this.targetAlarm.id,
        startDataTimeHistorical: startDataTimeHistorical,
        endDateTimeHistorical: endDateTimeHistorical
      };

      try {
        const getHistoricalofCondition = await this.getHistoricalofCondition(dataforHistorical);
        this.targetAlarm.historicIncidences = getHistoricalofCondition
          .filter(item => item[2].length > 0)
          .map(item => [item[0], item[2]]);
        const idDCL = this.assetsList.find((item) => item.label === this.selectedAsset);
        if (!idDCL) {
          //console.error('Asset not found with label:', this.selectedAsset);
          return;
        }
        let chartVariableDisplayData = {
          variableLabel: this.targetAlarm.variableLabel,
          variableType: this.targetAlarm.type === "0" ? "1" : "0", // Adjust type cuando se implemente dinamicas
          status: this.targetAlarm.status,
          statusDate: this.targetAlarm.statusDate,
          id: idDCL.id,
          startDateTime: startDataTimeHistorical,
          endDateTime: endDateTimeHistorical,
          limit: 0,
          history: getHistoricalofCondition,
          active: false
        };
        if (this.targetAlarm.type === "0") {
          chartVariableDisplayData.upperLimit = this.targetAlarm.upperLimit;
          chartVariableDisplayData.lowerLimit = this.targetAlarm.lowerLimit;
          chartVariableDisplayData.hasUpperLimit = this.targetAlarm.hasUpperLimit;
          chartVariableDisplayData.hasLowerLimit = this.targetAlarm.hasLowerLimit;
        } else if (this.targetAlarm.type === "1") {
          chartVariableDisplayData.isActive = this.targetAlarm.isActive;
        }
        this.alarmLineChartOptions = await this.displayVariablesChartOptions(chartVariableDisplayData);
        this.historicalarmlineChartOptions = await this.displayHistoricChartOptions(chartVariableDisplayData);
      } catch (error) {
        //console.error('Error occurred during data retrieval or chart options setup:', error);
      }
    },
    async onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
      this.selectedCompany = value;
      this.incidenceList = {},
        this.targetActiveAlarmId = null
      this.selectedAsset = null
      this.alarmsList = {}
      this.incidence = {},
        this.activeAlarmsList = {}
      this.preActive = {}
      this.postActive = {}
      this.targetActiveAlarm = ""
      this.targetAlarm = ""
      this.clearTables()
      this.displayAlarmsDataTable()
      this.displayActiveAlarmsDataTable()
      await this.getAssets();
    },
    async onAssetSelected(event) {
      this.targetActiveAlarmId = null
      this.alarmsList = {}
      this.activeAlarmsList = {}
      this.preActive = {}
      this.postActive = {}
      this.targetActiveAlarm = ""
      this.targetAlarm = ""
      this.dcl = '';
      this.clearTables()
      this.displayAlarmsDataTable()
      this.displayActiveAlarmsDataTable()
      let value = event.target.value;
      if (value && this.assetsList.length > 0) {
        const asset = this.assetsList.filter((item) => item.label === value);
        if (asset.length > 0) {
          this.dcl = asset;
          await this.getAlarmsbyDcl(asset)
        }
      }
    },
    clearTables() {
      let data = {
        toolbox: {
          feature: {
            dataView: {
              readOnly: true
            },
            dataZoom: {
              show: true,
              yAxisIndex: null
            },
            magicType: {
              type: ['line', 'bar']
            },
            saveAsImage: {
              pixelRatio: 2
            }
          }
        }
      }
      this.alarmLineChartOptions = data;
      this.historicalarmlineChartOptions = data;
      this.activeAlarmLineChartOptions = data;
      this.historicActiveAlarmlineChartOptions = data;
    },
    //GET
    async getIncidencebyId(incidence) {
      let request = {
        identificadorIncidencia: incidence

      };
      let response = await api.getIncidencebyId(request);
      return api.parseIncidenceFromResponse(response);
    },
    async getHistoricalofCondition(data) {
      try {
        let response = await api.getHistoricalofCondition(data);
        if (response["status-code"] === "200") {
          return api.parseConditionHistoryFromResponse(response)

        }
      } catch (error) {
        //console.error("Error fetching historical:", error);
      }

    },
    async getAlarmsbyDcl(selectedDcl) {
      this.activeAlarmsList = {}
      let data = {
        idDcl: selectedDcl[0].id ? selectedDcl[0].id : selectedDcl
      };
      try {
        let response = await api.getConditionsByDcl(data)

        if (response["status-code"] === "200") {

          this.alarmsList = api.parseAlarmListFromResponse(response);
          this.activeAlarmsList = [...this.alarmsList].filter((item) => item.status === "2" && item.isAlarm === "true");
          this.preActive = [...this.alarmsList].filter((item) => item.status === "1");
          this.postActive = [...this.alarmsList].filter((item) => item.status === "3");
          const data = {
            nifCif: this.selectedCompany ? this.selectedCompany : this.currentCompany.nifCif
          };
          let incidenceResponse = await api.getIncidencesByCompany(data)
          this.incidence = api.parseIncidenceListFromResponse(incidenceResponse)
        } else {
          if (this.alarmsList) {
            this.alarmsList = {}
            this.activeAlarmsList = {}
          }
          throw new Error("No existen alarmas para este activo.");
        }
        this.displayActiveAlarmsDataTable()
        this.displayAlarmsDataTable()
      } catch (error) {
        window.alert(error.message);
      }
    },
    async getMachine(id) {
      try {
        let response = await api.getLockerData(id);

        return api.parseMachineFromResponse(response)

      } catch (error) {
        //console.error(error)
      }

    },
    async getAssets() {
      this.assetsList = [];
      let response;
      const data = {
        nifCif: this.selectedCompany ? this.selectedCompany : this.currentCompany.nifCif
      };

      const moduleType = this.captureModuleFromUrl();
      try {
        switch (moduleType) {
          case 2:
            response = await api.getMachineListByCompany(data);
            if (response["status-code"] === "200") {
              this.assetsList = api.parseMachinesFromResponse(response);
            }
            break;
          case 3:
            response = await api.getDeviceListByCompany(data);
            if (response["status-code"] === "200") {
              this.assetsList = api.parseDevicesFromResponse(response)
            }
            break;
          case 7:
            response = await api.getStockTypeList(data);

            break;
          default:
          //console.warn("Unknown module type:", moduleType);

        }
      } catch (error) {
        //console.error("Error fetching assets:", error);

      }
    },
    captureModuleFromUrl() {
      try {
        const url = new URL(window.location.href);
        const pathParts = url.pathname.split('/');

        switch (true) {
          case pathParts[3].includes("smartLockers"):
            return 2;
          case pathParts[3].includes("devices"):
            return 3;
          case pathParts[3].includes("fabric"):
            return 2;
          case pathParts[3].includes("storage"):
            return 7;
          default:
            return null;
        }
      } catch (error) {
        //console.error("Error parsing URL:", error);
        return null;
      }
    },
    detectBrowser() {
      const aKeys = ["MSIE", "Firefox", "Safari", "Chrome", "Opera"];
      const sUsrAg = navigator.userAgent;
      let nIdx = aKeys.length - 1;
      for (; nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1; nIdx--);

      this.firefox = nIdx === 1 || nIdx === 2 ? true : false;

    },
    formatDateTime(dateTime) {

      return api.parseDateUserTimezone(dateTime, this.session.user, this.$dayjs);
    },
    //SET

    async setChangeIsMuted() {
      let idCondition = event.target.getAttribute('data-id');
      let data = {
        id: idCondition
      }
      try {
        let response = api.setChangeIsMuted(data);
        if (response["status-code"] === "200") {
          await this.getAlarmsbyDcl(this.dcl)
          this.displayActiveAlarmsDataTable()
        }
      } catch (error) {
        //console.error(error)
      }
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnDetailAlarm").get().forEach(btn => {
          btn.addEventListener('click', (event) => {
            const radioValue = event.target.value;
            this.onDetailActiveAlarm(radioValue);
          });
        });
        $(".btnDetailAlarmFromList").get().forEach(btn => {
          btn.addEventListener('click', (event) => {
            const radioValue = event.target.value;
            this.onDetailAlarm(radioValue)
          }
          );
        });
        $(".btnChangeIsMuted").get().forEach(btn => {
          btn.addEventListener('click', this.setChangeIsMuted);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
        $(".btnEditCondition").get().forEach(btn => {
          btn.addEventListener('click', this.onEditCondition);
        });
        $(".btnDeleteCondition").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteCondition);
        });
      }, 300);
    }


  }, mounted() {
    $("#ActiveAlarmsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.activeAlarmColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[2, 'desc']]
      }
    );
    $("#AlarmsDataTable").DataTable({
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'colvis', className: 'btn' },
        { extend: 'copy', className: 'btn' },
        { extend: 'csv', className: 'btn' },
        { extend: 'print', className: 'btn me-0' },
      ],
      columns: this.alarmColumns,
      select: this.dataTableOptions.select,
      language: this.dataTableOptions.language[this.session.user.language],
      order: [[2, 'desc']]
    });

    $(".dataTables_filter").get().forEach((filter) => { filter.getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions) });

  },
  async created() {
    this.getAssets()
    this.detectBrowser()
  },
}
</script>