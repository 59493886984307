<template>
  <!-- MODAL New Incidence -->
  <div id="newIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">

      <!-- HEAD New Incidence -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Abrir incidencia'][session.user.language] }}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleOpenNewIncidence')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT New Incidence -->
      <div class="bg-caja col-12 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">
            <form class="p-1 m-0 border rounded form-material">
              <div class="row">
                <!-- incidencia -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Tipo incidencia'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <select v-model="openNewIncidenceData.idIncidence" id="inputNewIncidence" @change="updateValues"
                      class="form-control form-control-line" style="color:black;">
                      <option v-for="item in incidenceList" :key="item.id" :value="item.id">
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- descripción incidencia -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Descripción'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <textarea class="form-control form-control-line" style="color:black;" v-model="descriptionText"
                      readonly></textarea>
                  </div>
                </div>
              </div>
              <!--Lockers-->
              <div class="row">
                <!-- incidencia -->
                <div v-if="entityData" class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Activo'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <select v-model="openNewIncidenceData.idDcl" id="inputLocker"
                      :disabled="openNewIncidenceData.idDcl && incidenceLocker" class="form-control form-control-line"
                      style="color:black;">
                      <option v-for="item in entityData" :key="item.id" :value="item.id">
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="incidenceLockingOrder" class="row">
                <!-- orden -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Pedido'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <span id="inputLocking" :disabled="openNewIncidenceData.idDcl && incidenceLocker"
                      class="form-control form-control-line" style="color:black;">
                      {{ incidenceLockingOrder }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="!incidenceLockingOrder" class="row">
                <!-- orden -->
                <div v-if="emailAvailable" class="form-group col-12 p-1 ps-4 row">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Notificar a'][session.user.language] }}
                  </label>
                  <div class="col-lg-10 col-12">
                    <input v-model="newEmail" type="email" id="inputEmail" class="form-control form-control-line"
                      style="color:black;" :placeholder="dictionary['Introduce email'][session.user.language]" />
                  </div>
                  <div class="col-lg-2 col-12">
                    <button type="button" id="btnSubmitValidateEmail"
                      class="button-ok tx-14 text-white rounded-3 text-center gradient-tablas" @click="addEmail"
                      style="vertical-align: middle; width: 80%; height: 2.2rem;">
                      {{ dictionary['Validar'][session.user.language] }}
                    </button>
                  </div>
                </div>
                <div v-if="emails.length > 0" class="col-12 p-1 ps-4 row mt-3">
                  <div v-for="(email, index) in emails" :key="index" class="col-10 mb-2 d-flex align-items-center">
                    <span class="form-control form-control-line col-10" style="color:black;">
                      {{ email }}
                    </span>
                    <div class="form-group col-lg-2 col-12 m-0 p-0">
                      <ion-icon name="remove-circle-outline"
                        class="bg-danger text-white c-pointer targets-icons mt-2 m-0 p-0 border rounded-circle tx-36 text-bold"
                        style="vertical-align: middle;" @click="removeEmail(index)">
                      </ion-icon>
                    </div>
                  </div>
                </div>
              </div>

              <!-- level -->
              <!--  <div class="row" v-if="openNewIncidenceData.idIncidence">
              

                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Nivel de gravedad'][session.user.language] }}
                  </label>
                  <div class="col-12 d-flex flex-wrap justify-content-around pt-2">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="muyBaja" value="0"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="muyBaja">{{ dictionary['Muy baja'][session.user.language] }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="baja" value="1"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="baja">{{ dictionary['Baja'][session.user.language]
                        }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="media" value="2"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="media">{{
              dictionary['media'][session.user.language][0].toUpperCase() +
              dictionary['media'][session.user.language].slice(1) }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="alta" value="3"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="alta">{{ dictionary['Alta'][session.user.language]
                        }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="critica" value="4"
                        v-model="openNewIncidenceData.level" />
                      <label class="form-check-label tx-13" for="critica">{{
              dictionary['Critica'][session.user.language] }}</label>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="row">
                <!-- DESCRIPCIÓN -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Observaciones'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <textarea v-model="openNewIncidenceData.description" id="inputNewProductDescription" type="text"
                      rows="1" class="form-control form-control-line" style="color:black;" />
                  </div>
                </div>

              </div>


              <div class="row">

                <!-- SUBMIT -->
                <div class="form-group col-12 p-1">
                  <div class="col-12 mt-2 mb-2">
                    <button type="button" @click="onCreateOpenIncidence()" id="btnSubmitCreateNewProduct"
                      class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">
                          {{ dictionary['Abrir incidencia'][session.user.language] }}</span>
                      </span>
                    </button>
                  </div>
                </div>

              </div>

            </form>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "OpenNewIncidenceModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    incidenceTypes: {
      type: Object,

    },
    incidenceId: {
      type: String,

    },
    incidenceLocker: {
      type: String
    },
    incidenceLockingId: {
      type: String
    },
    incidenceLockingOrder: {
      type: String
    },
    incidenceStatus: {
      type: String
    }
  },
  data() {
    return {
      openNewIncidenceData: {
        idIncidence: "",
        observations: "",
        level: "",

      },
      entityData: {},
      incidenceList: null,
      descriptionText: "",
      emailAvailable: '',
      newEmail: '',
      emails: [],
    }
  },
  emits: ["toggleOpenNewIncidence"],
  methods: {
    async onCreateOpenIncidence() {
      let valid = true;
      if (this.openNewIncidenceData.idIncidence === "" || this.openNewIncidenceData.idIncidence === undefined) {
        $("#inputNewIncidence").addClass("missing");
        valid = false;
      }
      if (this.openNewIncidenceData.description === "" || this.openNewIncidenceData.description === undefined) {
        $("#inputNewProductDescription").addClass("missing");
        valid = false;
      }
      if (this.openNewIncidenceData.idDcl === "" || this.openNewIncidenceData.idDcl === undefined) {
        $("#inputLocker").addClass("missing");
        valid = false;
      }



      if (!valid) {
        setTimeout(() => {
          $(".missing").removeClass("missing");
        }, 3000);
        return;
      }


      this.openNewIncidenceData.observations = this.openNewIncidenceData.description
      if (this.incidenceLockingId) {
        this.openNewIncidenceData.incidenceLockingId = this.incidenceLockingId
      }
      if (this.emails) {
        this.openNewIncidenceData.emails = this.emails
      }
      try {

        let response = await api.postNewHistoricIncidence(this.openNewIncidenceData);
        if (response["status-code"] === "201") {

          this.$emit('toggleOpenNewIncidence');
          window.alert(this.dictionary["La incidencia ha sido creada satisfactoriamente"][this.session.user.language]);
        } else {
          window.alert("Error al crear la incidencias.");
        }
      } catch (error) {
        console.error("Error creating incidence:", error);
        window.alert(this.dictionary["Error al crear la incidencia"][this.session.user.language]);
      }
    },
    updateValues() {
      const selectedIncidence = this.incidenceList.find(item => item.id === this.openNewIncidenceData.idIncidence);
      if (selectedIncidence) {
        this.openNewIncidenceData.level = selectedIncidence.level;
        this.descriptionText = selectedIncidence.description,
          this.emailAvailable = selectedIncidence.email === 'true' ? true : false
      }
    },
    addEmail() {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (emailPattern.test(this.newEmail) && !this.emails.includes(this.newEmail)) {
        this.emails.push(this.newEmail);
        this.newEmail = '';
      } else {
        alert(this.dictionary['Por favor, introduce un email válido.'][this.session.user.language]);
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    async getEntityData() {
      let data = {
        nifCif: this.currentCompany.nifCif
      };
      let machine = null;

      try {       
          const machineResponse = await api.getMachineListByCompany(data);
          if (machineResponse && machineResponse.maquinas?.length > 0) {
            machine = api.parseMachinesFromResponse(machineResponse);
          } else {            
            const deviceResponse = await api.getDeviceListByCompany(data);
            if (deviceResponse && deviceResponse.dispositivos?.length > 0) {
              machine = api.parseDevicesFromResponse(deviceResponse);
            }
          
        }
        return machine;
      } catch (error) {
        console.error("Error fetching machine data:", error);
        return null; // O cualquier valor que desees devolver en caso de error
      }
    },
    async getIncidencesByCompany() {
      let request = {
        nifCif: this.session.user.nifCif,
        limit: 0,
        offset: 0,

      };
      let response = await api.getIncidencesByCompany(request);
      this.incidenceList = api.parseIncidenceListFromResponse(response);
      console.log(response)
    },

  },
  async created() {

    if (!this.incidenceTypes) {
      await this.getIncidencesByCompany();
    } else {
      this.incidenceList = this.incidenceTypes;
    }

    if (this.incidenceList) {
      const defaultItem = this.incidenceList.find(item => item.id === this.incidenceId);
      if (defaultItem) {
        this.openNewIncidenceData.idIncidence = this.incidenceId;
        this.updateValues();
      }

      if (["0", "1"].includes(this.incidenceStatus)) {
        this.openNewIncidenceData.idIncidence = "5";
        this.updateValues();
      } else if (["2", "3", "4"].includes(this.incidenceStatus)) {
        this.openNewIncidenceData.idIncidence = "6";
        this.updateValues();
      }

      this.entityData = await this.getEntityData();

      if (this.incidenceLocker && this.entityData) {
        const defaultLocker = this.entityData.find(item => item.id === this.incidenceLocker);
        if (defaultLocker) {
          this.openNewIncidenceData.idDcl = this.incidenceLocker;
        }
      }
    }
  }

}
</script>


<style>
#newIncidenceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>