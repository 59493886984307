<template>
  <!-- SECTION SiteMap -->
  <div id="siteMapSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

      <!-- HEADER Display Type -->
      <div class="col-lg-2 col-md-4 col-12 m-0 pt-0 ps-0 text-white rounded-5">
        <div class="rounded-5 bg-lat box shadow h-auto" style="border-bottom: solid 1px #FFF;">
          <div class="col-lg-12 overflow-hidden col-md-12 col-12 justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 pt-0">
              <ion-icon name="location" class="tx-16 align-bottom"></ion-icon>
              {{ dictionary["Empresas"][session.user.language] }}
            </h6>
          </div>

          <div class="p-3 pt-0 bg-lat rounded-bottom-5">
            <!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">
              <hr v-if="this.$route.params.currentDisplay === 'emplacements'" class="m-0 mb-3 p-0">

              <select v-if="this.$route.params.currentDisplay === 'emplacements'" :value="currentCompany.nifCif"
                class="form-control mt-3 mb-3 h-100 text-nowrap bg-white border"
                style="border: solid 2px white ; color: black;" @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0] != undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                  {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                </option>
              </select>
            </div>

            <hr class="m-0 p-0 mb-3 bg-dark">

            <div class="row justify-content-around m-0 p-0">
              <div class="col-3 m-0 mb-3 p-0">
                <button @click="onChangeDisplayType('map')"
                  :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn bg-lat rounded-5 p-0 text-nowrap">
                  <ion-icon name="map-outline" :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                </button>
              </div>
              <div class="col-3 m-0 mb-3 p-0">
                <button @click="onChangeDisplayType('table')"
                  :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn bg-lat rounded-5 p-0 text-nowrap">
                  <ion-icon name="list-outline" :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                </button>
              </div>
              <div class="col-3 m-0 mb-3 p-0">
                <button @click="onChangeDisplayType('cards')"
                  :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn bg-lat rounded-5 p-0 text-nowrap">
                  <ion-icon name="documents-outline"
                    :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                </button>
              </div>
            </div>

            <hr v-if="this.session.user.rol === '255'" class="m-0 mb-3 p-0">
            <div class="col-lg-12 overflow-hidden col-md-12 col-12 justify-content-center">
              <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 pt-0">
                <ion-icon name="construct-outline" class="tx-16 align-bottom pe-2"></ion-icon>
                <!--{{dictionary["Empresas"][session.user.language]}}--><span>Configuración</span>
              </h6>
            </div>
            <hr v-if="this.session.user.rol === '255'" class="m-0 mb-3 p-0">
            <button v-if="this.session.user.rol === '255'" id="btnDisplayNewDeviceModal"
              class="button-ok bg-lat mt-0 mb-4 rounded-5" @click="toggleNewEmplacementModal">
              <span>
                <span class="button-text text-white">
                  <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                  <span class="text-white mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>
                </span>
              </span>
            </button>
          </div>

        </div>
      </div>

      <div class="col-lg-10 col-md-8 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3">

          <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
            <span class="tx-left tx-clomos-white text-center mt-1 mb-2">
              <ion-icon name="location-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
              {{ dictionary["Empresas"][session.user.language] }}
            </span>
          </div>

          <!-- CONTENT Map -->
          <div v-show="!displayType.includes('map')" :class="fullSize ? 'col-lg-12' : 'col-lg-4'"
            class=" col-md-12 col-12 m-0 ps-4 p-3 pt-0 pb-0 mt-2 h-auto ">
            <div class="p-0 text-end text-dark rounded" style="z-index: 11;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1" @click="togglechangeDisplaySize()">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>
            <div id="emplacementsMapContent" class="col-12 bg-caja rounded-5 shadow"
              style="min-height: 324px; z-index: 0; height: auto;border-top: solid 5px #005793;border-bottom: solid 2px #008db4; margin-top:-20px;">
            </div>
          </div>
          <!-- CONTENT Cards -->
          <div v-show="!displayType.includes('cards')" id="siteCardsContent" :class="fullSize ? 'col-lg-12' : 'col-lg-8'"
            class="row  col-md-12 col-12 ps-4 p-3 pt-0 pb-0 mt-2 h-auto">
            <div class="p-0 text-end text-dark" style="z-index: 1;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1" @click="togglechangeDisplaySize()">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>
            <div class="row bg-caja rounded-5 p-0 me-0 ms-0 shadow"
              style="min-height: 320px; z-index: 0; height: auto; border-top: solid 5px #005793;border-bottom: solid 2px #008db4; margin-top:-20px;">
              <div v-for="emplacement in emplacementCardsByPages[emplacementCardsPage]" :key="emplacement"
                class="col-xl-2 col-md-6 col-12 m-0 p-2 pt-2">
                <h6 class="tx-left text-white text-center text-nowrap m-0 p-2 ps-1 pt-2 bg-lat rounded-top-5">
                  <span id="cardLabel" :data-id="emplacement.id"
                    class="m-auto tx-11 c-pointer btnTargetEmplacement">{{ (emplacement.label.length > 18) ? (emplacement.label.substring(0, 18) + '...') : emplacement.label }}</span>
                </h6>

                <div class="col-12 border-1 rounded-bottom m-0 p-2 h-auto" style="border-color: #ccc !important; ">
                  <img :src="displayMyEmplacementImage(emplacement.imgSrc)" :data-id="emplacement.id"
                    alt="Emplacement Card Picture" class="btnTargetEmplacement col-12 m-0 p-0 mb-2">
                  <div class="col-12 m-0 p-0 d-flex space-between">
                    <ion-icon name="create-outline" :data-id="emplacement.id"
                      class="btnEditEmplacement col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="emplacement.id"
                      class="btnDeleteEmplacement col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                  </div>
                  <hr class="col-12 mt-2 mb-2">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 5rem;">
                    <span class="tx-11 tx-bold">{{ dictionary['Localización'][session.user.language] + ": " }}</span>
                    <span
                      class="tx-10 m-auto">{{ (emplacement.location.length > 100) ? (emplacement.location.substring(0, 100) + '...') : emplacement.location }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 pt-0 p-2">
                <div v-for="page, index in emplacementCardsByPages" :key="page"
                  class="border rounded c-pointer m-0 me-2 tx-13"
                  :class="(emplacementCardsPage === index) ? 'border-secondary' : ''" style="height:1.5rem; width:1.5rem;"
                  @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index + 1 }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- CONTENT table -->
          <div v-show="!displayType.includes('table')"
            class="row col-lg-12 col-md-12 col-12 ps-4 p-3 pt-0 pb-0  mt-2 h-auto">
            <div id="tableContent" class="col-12 bg-caja m-0 p-3 mt-3 mb-1 ms-2 rounded-5 shadow"
              style="border-top: solid 5px #005793;border-bottom: solid 2px #008db4;">
              <table id="emplacementsDataTable" class="table table-striped p-0 pb-3 bg-caja overflow-auto">
                <thead class="bg-lat tx-14 p-0 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in emplacementsColumns" :key="column.data" :width="column.width"
                      class="fw-medium p-1">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>



          <div v-show="!displayType.includes('cards') && this.targetEmplacementId === ''" class="p-3">
            <span class="tx-13">
              {{ dictionary['No hay ' + ((this.$route.params.currentDisplay === "emplacements") ? 'emplazamientos' : 'centros de mando')+' disponibles'][session.user.language]}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  

  <!-- MODAL New Emplacement -->
  <NewEmplacementModal v-if="display.newEmplacementModal" :session="session" :dictionary="dictionary"
    :countries="countries" :currentCompany="currentCompany" v-on:toggleNewEmplacementModal="toggleNewEmplacementModal">
  </NewEmplacementModal>

  <!-- MODAL Edit Emplacement -->
  <EditEmplacementModal v-if="display.editEmplacementModal" :session="session" :dictionary="dictionary"
    :countries="countries" :currentCompany="currentCompany" :emplacementId="targetEmplacementId"
    :editRequest="editRequest" v-on:toggleEditEmplacementModal="toggleEditEmplacementModal">
  </EditEmplacementModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteEmplacementModal" :session="session" :dictionary="dictionary"
    :countries="countries" :alertHeader="dictionary['Emplazamiento a eliminar'][session.user.language] + ': '"
    :alertTarget="targetEmplacementId" v-on:acceptAlert="onDeleteEmplacement"
    v-on:dismissAlert="toggleDeleteEmplacementModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import NewEmplacementModal from '@/components/emplacements/NewEmplacementModal';
import EditEmplacementModal from '@/components/emplacements/EditEmplacementModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "EmplacementsComponent",
  components: {
    NewEmplacementModal, EditEmplacementModal, TextAlertModal
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      await this.getEmplacementList();
      this.displayEmplacementsMap();
      this.displayEmplacementsDataTable();
      if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
      else this.targetEmplacementId = "";
      this.setButtonFunctions();
    }
  },
  data() {
    return {
      // MAP
      emplacementsColumns: [
        {text:"Identificador", data: "emplacementId", width: "20%",className:"tx-left align-middle dt-nowrap",
        render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
            +"<span data-id="+data+" class='btnTargetEmplacement c-pointer tx-12 tx-bold tx-clomos-midtone align-middle'>"+data+"</span>";
          }
        },
        {text:"Nombre", data: "emplacementLabel", width: "20%",className:"tx-left align-middle",
        render: (data) => {
            return "<span data-id="+data.id+" class='text-start tx-12 tx-bold w-100 btnTargetEmplacement c-pointer'>"+data.label+"</span>"
          }
        },
        {text:"Localización", data: "emplacementLocation", width: "20%",className:"tx-left align-middle",
        render: (data) => {
            return "<span data-id="+data+" class='text-start tx-12 w-100'>"+data+"</span>"
          }
        },
        {text:"Tipo", data: "emplacementType", width: "15%",className:"tx-left align-middle",
        render: (data) => {
            return "<span data-id="+data+" class='text-start tx-12 w-100'>"+data+"</span>"
          }
        },
        {text:"Coordenadas", data: "emplacementGeoJSON", width: "15%",className:"tx-left align-middle",
        render: (data) => {
            if (data.geometry!=undefined && data.geometry.type==="Point") return "<div class='d-flex pt-1'><span class='me-2 ms-2 tx-primary'>Lat:</span> <span>"+Number(data.geometry.coordinates[0]).toFixed(3)+"</span> <span class='me-2 ms-2 tx-primary'>Lon:</span> <span>"+Number(data.geometry.coordinates[1]).toFixed(3) 
            else return "<span class='me-2 ms-2 tx-primary'>Lat:</span> <span>"+0+"</span>  <span class='me-2 ms-2 tx-primary'> Lon:</span></div> "+0
          }
        },
        {text:"Editar", data: "emplacementId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id="+data+" class='btnEditEmplacement btn tx-18 p-0 tx-16 text-primary'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "emplacementId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteEmplacement btn tx-18 p-0 tx-16 text-danger'></ion-icon>"
          }
        }
      ],
      targetEmplacementId: this.$route.params.targetEntity,
      emplacementList: [],
      emplacementsMap: undefined,
      displayType: [],
      emplacementCardsPage: 0,
      display: {
        newEmplacementModal: false,
        editEmplacementModal: false,
        deleteEmplacementModal: false
      },
      fullSize: false
    }
  },
  computed: {
    viewpoint() {
      let viewpoint = {
        center: { lng: 0, lat: 0 },
        zoom: 5,
      }
      let l = undefined;
      let r = undefined;
      let t = undefined;
      let b = undefined;
      if (this.emplacementList[1] != undefined) { // Multiple emplacements
        for (let i in this.emplacementList) if (this.emplacementList[i].geoJSON.geometry != undefined && this.emplacementList[i].geoJSON.geometry.type === "Point") {
          if (this.emplacementList[i].geoJSON.geometry.coordinates[0] >= t || t === undefined) {
            t = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0])
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[0] <= b || b === undefined) {
            b = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0]);
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[1] >= r || r === undefined) {
            r = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1])
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[1] <= l || l === undefined) {
            l = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1]);
          }
        }
        // CENTER
        viewpoint.center.lng = (r + l) / 2;
        viewpoint.center.lat = (t + b) / 2;
      }
      else if (this.emplacementList[0] != undefined) { // Single emplacement
        viewpoint.center = { lng: parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[0]), lat: parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[1]) };
      }
      return viewpoint;
    },
    emplacementCardsByPages() {
      let emplacementCardsByPages = []
      let i = 0;
      while (i * 6 < this.emplacementList.length) {
        emplacementCardsByPages.push(this.emplacementList.slice(i * 6, i * 6 + 6));
        i++;
      }
      return emplacementCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY   
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onChangeDisplayType(type) {
      const index = this.displayType.indexOf(type);
      if (index !== -1) {
        this.displayType.splice(index, 1);
        if (type !== 'table') this.togglechangeDisplaySize()
        if (type === "map") {
          this.$nextTick(() => {
            this.displayEmplacementsMap();
          });
        } else if (type === "table") {

          this.$nextTick(() => {
            this.displayEmplacementsDataTable();
          });
        }
      } else {
        if (type !== 'table') this.togglechangeDisplaySize()
        this.displayType.push(type);


      }

      setTimeout(() => {
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.emplacementCardsPage = index;
      this.setButtonFunctions();
    },
    displayEmplacementsDataTable() {
      $("#emplacementsDataTable").DataTable().clear();
      for (let i in this.emplacementList) {
        $("#emplacementsDataTable").DataTable().rows.add([{
          emplacementId: this.emplacementList[i].id,
          emplacementLabel: this.emplacementList[i],
          emplacementLocation: this.emplacementList[i].location,
          emplacementType: this.emplacementList[i].type,
          emplacementGeoJSON: this.emplacementList[i].geoJSON,
        }]);
      }
      $("#emplacementsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    displayEmplacementsMap() {
      if (this.emplacementsMap != undefined) {
        this.emplacementsMap.invalidateSize();
        this.emplacementsMap.remove();
        this.emplacementsMap = undefined;
      }

      var emplacementsMap = Leaflet.map("emplacementsMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 19 }).addTo(emplacementsMap);

      var siteMarkersGroup = new MarkerClusterGroup();
      emplacementsMap.addLayer(siteMarkersGroup);

      let emplacementsMapListForGroup = [];
      siteMarkersGroup.addLayers(this.emplacementList.map(e => {
        let options = { title: e.label, clickable: true, draggable: false };
        var marker = Leaflet.marker([e.geoJSON.geometry.coordinates[0], e.geoJSON.geometry.coordinates[1]], options);
        marker.on("click", this.setButtonFunctions);
        marker.bindPopup('<div class="d-flex flex-column" style="min-width: 9rem;">'
          + '<span data-id="' + e.id + '" class="btnTargetEmplacement c-pointer tx-bold tx-grey m-0 p-0">' + e.label + '</span>'
          + '<div class="m-0 p-0 d-flex flex-row justify-content-between">'
          + '<text class="w-100">' + e.location + '</text>'
          + '<ion-icon name="create-outline" data-id="' + e.id + '" class="btnEditEmplacement col-2 m-0 mt-auto ps-1 p-0 btn tx-24 tx-primary float-end"></ion-icon>'
          + '<ion-icon name="trash-outline" data-id="' + e.id + '" class="btnDeleteEmplacement col-2 m-0 mt-auto p-0 btn tx-24 tx-danger float-end"></ion-icon>'
          + '</div></div>');
        emplacementsMapListForGroup.push(marker);
        return marker;
      }))
      if (emplacementsMapListForGroup[0] != undefined) {
        var emplacementsMapGroup = Leaflet.featureGroup(emplacementsMapListForGroup);
        emplacementsMap.fitBounds(emplacementsMapGroup.getBounds());
        var currentZoom = emplacementsMap.getZoom();
        emplacementsMapListForGroup.length>1?emplacementsMap.setZoom(currentZoom - 2):emplacementsMap.setZoom(currentZoom - 6)
        
      } else {
        emplacementsMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
      }

      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();

      this.emplacementsMap = emplacementsMap;
    },
    togglechangeDisplaySize() {
      this.fullSize = !this.fullSize;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.emplacementsMap) {
            this.emplacementsMap.invalidateSize();
          }
        }, 300);

      });
    },
    async toggleNewEmplacementModal() {
      if (this.display.newEmplacementModal) {
        await this.getEmplacementList();

      }
      this.display.newEmplacementModal = !this.display.newEmplacementModal;
    },
    async toggleEditEmplacementModal() {
      if (this.display.editEmplacementModal) {
        await this.getEmplacementList();
        this.editRequest = false;
        setTimeout(() => {
        this.setButtonFunctions();
      }, 100);
      }
      this.display.editEmplacementModal = !this.display.editEmplacementModal;
    },
    async toggleDeleteEmplacementModal() {
      if (this.display.deleteEmplacementModal) {
        await this.getEmplacementList();
        setTimeout(() => {
        this.setButtonFunctions();
      }, 100);
        if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
        else this.targetEmplacementId = "";
      }
      this.display.deleteEmplacementModal = !this.display.deleteEmplacementModal;
    },
    displayMyEmplacementImage(src) {
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    // BUTTONS 
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetEmplacement);
        });
        $(".btnShowEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onShowEmplacement);
        });
        $(".btnEditEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onEditEmplacement);
        });
        $(".btnDeleteEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteEmplacement);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    // TARGET 
    onTargetEmplacement(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      if (this.$route.path.includes('electricalMonitoring')) {
        const currentPath = this.$route.path;
        const commandCenterPath = 'commandCenters/' + this.targetEmplacementId;
        if (!currentPath.includes(commandCenterPath)) {
          let newPath = currentPath.replace('emplacements', 'commandCenters/' + this.targetEmplacementId);
          this.$router.replace({ path: newPath });
        } 
      } else {
        this.$router.replace({ params: { targetEntity: this.targetEmplacementId } });
      }
    },
    // SHOW
    onShowEmplacement(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetEmplacementId } });
      this.editRequest = false;
      this.toggleEditEmplacementModal();
    },
    // SET
    onEditEmplacement(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetEmplacementId } });
      this.editRequest = true;
      this.toggleEditEmplacementModal();
    },
    // DELETE
    async onDeleteEmplacement(event) {
      if (!this.display.deleteEmplacementModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetEmplacementId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetEmplacementId } });
      }
      else {
        let response = await api.deleteEmplacement(this.targetEmplacementId);
        console.log(response);
      }
      await this.toggleDeleteEmplacementModal();
    }
  },
  async created() {
    await this.getEmplacementList()
    this.$emit('getAvailableCompanies', this.displayEmplacementsMap(), this.displayEmplacementsDataTable());
    if (this.emplacementList[0] != undefined && this.targetEmplacementId === "") this.targetEmplacementId = this.emplacementList[0].id;
    this.setButtonFunctions();
  },
  mounted() {
    $("#emplacementsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.emplacementsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    )
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover,
.btnTargetEmplacement:hover,
.btnEditEmplacement:hover,
.btnDeleteEmplacement:hover {
  color: var(--main-dark) !important;
}
#cardLabel:hover{
  color: var(--main-white) !important;
}
.leaflet-popup-content {
  margin: 0 !important;
  padding: 0.5rem !important;
}

/* esto pasar a clomos assets */
.bg-lat {
  background: rgb(0, 25, 113);
  background: linear-gradient(90deg, rgba(0, 25, 113, 1) 35%, rgba(0, 37, 119, 1) 100%);
}

.bg-head {
  background: rgb(0, 40, 120);
  background: linear-gradient(90deg, rgba(0, 40, 120, 1) 35%, rgba(0, 148, 183, 1) 100%);
}
table.dataTable tbody th, table.dataTable tbody td {
    padding: 1px 3px !important; /* e.g. change 8x to 4px here */
}
</style>