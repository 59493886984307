<template>
  <!-- MODAL Edit Company -->
    <div id="editCompanyModal" class="col-12 justify-content-center pe-6" style="padding: 15vh 25%">
      <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
  <!-- HEAD -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
          <div class="col-10">
            <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
              <ion-icon name="briefcase-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary['Editar empresa'][session.user.language]+": "+editCompanyData.name}}
            </h6>
          </div>

          <div class="col-2 m-0 p-0 d-flex justify-content-end">	
            <ion-icon :name="(editMode)?'lock-closed':'lock-open'" class="tx-18 btn m-0 mt-1 p-1 border-2 rounded tx-white align-bottom" @click="editMode=!editMode"></ion-icon>
            <ion-icon name="close" class="tx-30 btn m-0 p-1 pb-0 tx-white align-bottom" @click="$emit('toggleEditCompanyModal')"></ion-icon>
          </div>
        </div>
  
        <div class="col-12 bg-caja w-100 m-0 p-0 rounded-bottom-5 pe-3">
          <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
            <div class="row m-0 p-2 card-body">
 
  <!-- FORM Edit Company -->
              <div class="col-xl-8 col-lg-7 col-12 m-0 p-2 overflow-hidden">
                <form class="col-12 border border rounded overflow-hidden m-0 p-1">
                  <div class="bg-menu text-white p-1 m-0 rounded"> 
                  {{dictionary['Datos de la empresa'][session.user.language]}}
                  </div>
                  <div class="col-12 m-0 p-1">
                    <div class="row form-group p-2 pt-0">

                      <div class="col-lg-6 col-12 text-start">
                        <label class="p-0 pt-2 text-dark tx-bold tx-13 text-nowrap">
                          {{dictionary["NIF/CIF"][session.user.language]}}
                        </label><br>
                        <span v-if="!editMode" class="tx-13"> 
                          {{editCompanyData.nifCif}}
                        </span>
                        <input v-if="editMode" v-model="editCompanyData.nifCif" id="inputEditCompanyNifCif" type="text" disabled
                        class="form-control p-1 text-black">
                      </div>
  
  <!-- NOMBRE -->
                      <div class="col-lg-6 col-12" style="text-align: left;">								
                        <label class="p-0 pt-2 text-dark tx-bold tx-13 text-nowrap">
                          {{dictionary["Nombre de la empresa"][session.user.language]}}
                        </label><br>
                        <span v-if="!editMode" class="tx-13"> 
                          {{editCompanyData.name}}
                        </span>
                        <input v-if="editMode" v-model="editCompanyData.name" id="inputEditCompanyName" type="text"
                        class="form-control text-dark tx-13 p-1">
                      </div>
  
  <!-- CORREO -->
                      <div class="col-lg-6 col-12" style="text-align: left;">
                        <label class="p-0 pt-2 text-dark tx-bold tx-13 text-nowrap">
                          {{dictionary["E-mail de la empresa"][session.user.language]}}
                        </label><br>
                        <span v-if="!editMode" class="tx-13"> 
                          {{editCompanyData.email}}
                        </span>
                        <input v-if="editMode" v-model="editCompanyData.email" id="inputEditCompanyEmail" type="email"
                        class="form-control p-1 text-dark">
                      </div>
  
  <!-- TELÉFONO -->
                      <div class="col-lg-6 col-12" style="text-align: left;">
                        <label class="p-0 pt-2 text-dark tx-bold tx-13 text-nowrap">
                          {{dictionary["Teléfono de la empresa"][session.user.language]}}
                        </label><br>
                        <div class="col-12 d-flex flex-row p-0">
                          <div class="w-100">
                            <span v-if="!editMode" class="tx-13"> 
                              {{editCompanyData.telephone}}
                            </span>
                            <input v-if="editMode" v-model="editCompanyData.telephone" id="inputEditCompanyTelephone" type="text"
                            class="form-control p-1 text-dark">
                          </div>
                        </div>
                      </div>
                    
  <!-- PAÍS -->
                      <div class="col-lg-6 col-12" style="text-align: left;">
                        <label class="p-0 pt-2 text-dark tx-bold tx-13 text-nowrap">
                          {{dictionary["País"][session.user.language]}}
                        </label><br>
                        <span v-if="!editMode" class="tx-13"> 
                          {{editCompanyData.country}}
                        </span>
                        <select v-if="editMode" v-model="editCompanyData.country" id="selectCountry"
                        class="form-control p-1 text-dark">
                          <option v-for="country in countries" :key="country['code']" :value="country['code']">
                            {{country['name'][session.user.language]}}
                          </option>
                        </select>
                      </div>
  
  <!-- SECTOR -->
                      <div class="col-lg-6 col-12" style="text-align: left;">								
                        <label class="p-0 pt-2 text-dark tx-bold tx-13 text-nowrap">
                          {{dictionary["Sector"][session.user.language]}}
                        </label><br>
                        <span v-if="!editMode" class="tx-13"> 
                          {{(editCompanyData.sector[0]!=undefined)?editCompanyData.sector[0].toUpperCase()+editCompanyData.sector.substring(1,editCompanyData.sector.length):''}}
                        </span>
                        <select v-if="editMode" v-model="editCompanyData.sector" id="selectEditCompanySector"
                        class="form-control p-1 text-dark">
                          <option value="aeronautica">
                            {{dictionary["Aeronáutica"][session.user.language]}}
                          </option>
                          <option value="agroalimentario">
                            {{dictionary["Agroalimentario"][session.user.language]}}
                          </option>
                          <option value="automotriz">
                            {{dictionary["Automotriz"][session.user.language]}}
                          </option>
                          <option value="construccion">
                            {{dictionary["Obra civil"][session.user.language]}}
                          </option>
                          <option value="quimica">
                            {{dictionary["Química"][session.user.language]}}
                          </option>
                          <option value="militar">
                            {{dictionary["Defensa/Militar"][session.user.language]}}
                          </option>
                          <option value="electronica">
                            {{dictionary["Electrónica"][session.user.language]}}
                          </option>
                          <option value="energia">
                            {{dictionary["Energía"][session.user.language]}}
                          </option>
                          <option value="it">
                            {{dictionary["Tecnologías de la información"][session.user.language]}}
                          </option>
                          <option value="industria 4.0">
                            {{dictionary["Industria 4.0"][session.user.language]}}
                          </option>
                          <option value="farma-medico">
                            {{dictionary["Farma-médico"][session.user.language]}}
                          </option>
                          <option value="logistica">
                            {{dictionary["Transporte y logística"][session.user.language]}}
                          </option>
                          <option value="almacenamiento">
                            {{dictionary["Manejo y almacenamiento de materiales"][session.user.language]}}
                          </option>
                          <option value="canteras">
                            {{dictionary["Canteras, cemento, mármoles"][session.user.language]}}
                          </option>
                          <option value="papeleras">
                            {{dictionary["Fábricas de papel y celulosa"][session.user.language]}}
                          </option>
                          <option value="gas">
                            {{dictionary["Petróleo y gas"][session.user.language]}}
                          </option>
                          <option value="puertos">
                            {{dictionary["Industria Portuaria"][session.user.language]}}
                          </option>
                          <option value="puertos deportivos">
                            {{dictionary["Puertos deportivos"][session.user.language]}}
                          </option>
                          <option value="astilleros">
                            {{dictionary["Astilleros"][session.user.language]}}
                          </option>
                          <option value="telecomunicaciones">
                            {{dictionary["Telecomunicaciones"][session.user.language]}}
                          </option>
                          <option value="hierro y acero">
                            {{dictionary["Hierro y acero"][session.user.language]}}
                          </option>
                          <option value="reciclaje">
                            {{dictionary["Tratamiento de residuos/Reciclaje"][session.user.language]}}
                          </option>
                          <option value="otro">
                            {{dictionary["Otro"][session.user.language]}}
                          </option>
                        </select>
                      </div>
  
  <!-- DIRECCIÓN -->
                      <div class="col-lg-6 col-md-12 col-12 text-start">
                        <label class="p-0 pt-2 text-dark tx-bold tx-13 text-nowrap">
                          {{dictionary["Dirección"][session.user.language]}}
                        </label><br>
                        <span v-if="!editMode" class="tx-13"> 
                          {{editCompanyData.adress}}
                        </span>
                        <textarea v-if="editMode" v-model="editCompanyData.adress" id="inputEditCompanyAdress" name="adress" rows="1"
                        class="form-control p-0 text-dark"/> 
                      </div>
    
                      <div v-if="editMode" class="col-lg-6 col-md-12 col-12 mt-auto">	
                        <button type="button" @click="setEditCompanyData" class="button-ok gradient-tablas mt-3 rounded-3">
                          <span class="button-text text-white">
                            <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                            <span class="text-white text-nowrap mt-0">{{dictionary['Actualizar empresa'][session.user.language]}}</span>
                          </span>
                        </button>
                      </div>
    
                    </div>
                  </div>
                  
                </form>
              </div>
  
  <!-- FORM Company Logo-->
              <div class="col-xl-4 col-lg-5 col-12 m-0 p-2 overflow-hidden">
  
                <div class="justify-content-center bg-menu rounded-5 text-center m-0">
                  
                  <label class="col-12 p-3 pb-0 tx-white tx-bold">
                    {{dictionary["Logotipo de la empresa"][session.user.language].toUpperCase()}}
                  </label>
                  <label class="col-12 p-3 pt-0 pb-0 tx-white tx-light tx-12">
                    {{dictionary["Tamaño recomendado"][session.user.language]+': 200x200px'}}
                  </label>
                  
                  <img id="logo" class="w-100" style="max-width: 200px; width: auto; padding: 1rem;" :src="displayCompanyImage(editCompanyData.logoSrc)">
  
                  <div v-if="editCompanyImageForm.file!=''" class="myCompanyImagePreview w-100 ps-3 pe-3 pt-0 pb-2">
                    <div class="d-flex flex-column">
                      <button type="button" @click="onRemoveEditCompanyImage()" title="Remove file" style="line-height: 0; width: 25px; height: 25px" class="p-1 m-0 bg-clomos-white rounded ms-auto">
                        <ion-icon name="close" class="tx-18 tx-black align-bottom"></ion-icon>
                      </button>

                      <img style="max-width: 200px; width: auto; padding: 1rem; margin: auto;" :src="displayEditCompanyThumbnail(editCompanyImageForm.file)" />
                    </div>
                  </div>
  
                  <div v-if="editMode" id="logoUploadFormContainer" class="p-3 pt-0">
  
                    <input type="file" id="logoUploadSrc" class="w-100 file-button btn btn-secondary rounded" style="height: 3rem;"
                    @change="onChangeEditCompanyImage" accept=".png">
                    
                    <button type="button" id="logoUploadSubmit" @click="setEditCompanyImage" class="button-ok gradient-tablas mt-3 rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="image-outline" class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                        <span class="text-white text-nowrap mt-0">{{dictionary['Guardar archivo'][session.user.language]}}</span>
                      </span>
                    </button>
  
                  </div>
  
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
  
      </div>
    </div>
  
  </template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditCompanyModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
    editRequest: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      updated: false,
      editCompanyData: {
        nifCif: "",
        name: "",
        email: "",
        telephone: "",
        country: "",
        adress: "",
        sector: "",
        logoSrc: "",
      },
      editCompanyImageForm: {
        nifCif: "",
        src: "",
        file: ""
      }
    }
  },
  emits: ["toggleEditCompanyModal"],
  methods: {
    // DISPLAY
    displayCompanyImage(src) {
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/logo-sm.png');
    },
    displayEditCompanyThumbnail(file) {
      if (file === '' || file === undefined) return "";
      let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
      return fileSrc;
    },
    onChangeEditCompanyImage(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.editCompanyImageForm.nifCif = this.editCompanyData.nifCif;
      this.editCompanyImageForm.file = event.target.files[0];
      this.editCompanyImageForm.src = event.target.files[0].name;
    },
    onRemoveEditCompanyImage() {
      $("#logoUploadSrc").get()[0].value="";
      this.editCompanyImageForm.nifCif = "";
      this.editCompanyImageForm.file = "";
      this.editCompanyImageForm.src = "";
    },
    // GET
    async getEditCompanyData(callback) {
      let response = await api.getCompanyData(this.companyId);
      this.editCompanyData = api.parseCompanyFromResponse(response);
      if (callback && typeof callback === "function") callback(this.editCompanyData);
    },
    // SET
    async setEditCompanyData() {
      let response = await api.setCompanyData(this.editCompanyData);
      if (response["status-code"] === "200") {
        window.alert("La empresa ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar la empresa.");
      }
      this.getEditCompanyData();
    },
    async setEditCompanyImage() {
      let response = await api.setCompanyImage(this.editCompanyImageForm);
      console.log(response);
      this.onRemoveEditCompanyImage();
      this.getEditCompanyData();
    }
  },
  created() {
    this.editMode = (this.editRequest)?true:false;
    this.getEditCompanyData();
  },
}
</script>

<style>
#editCompanyModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.myCompanyImagePreview {
  max-width: 100%;
  margin: auto; 
}

@media (max-width: 767px) {
  #logoUploadFormContainer {
    flex-direction: column;
  }
}
</style>