<template>
<!-- MODAL Link Device -->
  <div id="textAlertModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 bg-caja sombra-modal">
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon :name="(linkAction==='link')?'log-in-outline':'log-out-outline'" class="tx-18 me-2 align-bottom"></ion-icon>
              {{ (linkAction==='link') ?dictionary['Vincular'][session.user.language]:dictionary['Desvincular'][session.user.language] }}
          </h6>
        </div>
        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="this.$emit('dismissAlert')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <div class="form-group col-12 p-0 pe-3">
        <div id="linkDeviceScroll" class="col-12 p-3" style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="row col-12 border border rounded m-0 p-1 pb-0">
            <div class="col-lg-4 col-12">
              <div class="col-12 m-0 p-2 d-flex flex-column tx-left">
                <span class="col-12 m-0 p-0 tx-bold tx-14">
                  {{ dictionary['Dispositivo'][session.user.language]+": "}}
                </span>
                <span class="col-12 m-0 p-0 tx-12">
                  {{ targetDeviceId }}
                </span>
              </div>
            
              <div v-if="linkAction === 'link'" 
              class="col-12 m-0 p-2 d-flex flex-column tx-left">
                <span class="col-12 m-0 p-0 tx-bold tx-14">
                  {{dictionary['Tipo'][session.user.language]+": "}}
                </span>
                <select class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                v-model="parentType"
                @change="onChangeParentType">
                  <option v-if="this.$route.params.currentModule==='devices'
                  ||this.$route.params.currentModule==='electricalMonitoring'"
                  value="device">
                    {{ dictionary['Dispositivo'][session.user.language] }}
                  </option>
                  <option v-if="this.$route.params.currentModule==='fabric'
                  ||this.$route.params.currentModule==='smartLockers'"
                  value="bundle">
                    {{ dictionary['Conjunto'][session.user.language] }}
                  </option>
                  <option v-if="this.$route.params.currentModule==='fabric'
                  ||this.$route.params.currentModule==='smartLockers'
                  ||this.$route.params.currentModule==='electricalMonitoring'"
                  value="machine">
                    {{ dictionary['Máquina'][session.user.language] }}
                  </option>
                  <option v-if="this.$route.params.currentModule==='devices'
                  ||this.$route.params.currentModule==='fabric'
                  ||this.$route.params.currentModule==='storage'
                  ||this.$route.params.currentModule==='smartLockers'
                  ||this.$route.params.currentModule==='electricalMonitoring'"
                  value="emplacement">
                    {{ dictionary['Emplazamiento'][session.user.language] }}
                  </option>
                </select>

                <span v-if="parentType==='device'" class="col-12 m-0 p-0 tx-bold tx-14">
                  {{dictionary['Dispositivo'][session.user.language]+": "}}
                </span>
                <select v-if="parentType==='device'"
                class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                v-model="targetEntityId"
                @change="onTargetEntity">
                  <option v-for="device in availableDevices" :key="device"
                  :value="device.id">
                    {{ device.label }}
                  </option>
                </select>

                <span v-if="parentType==='bundle'" class="col-12 m-0 p-0 tx-bold tx-14">
                  {{dictionary['Conjuntos'][session.user.language]+": "}}
                </span>
                <select v-if="parentType==='bundle'"
                class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                v-model="targetEntityId"
                @change="onTargetEntity">
                  <option v-for="bundle in availableBundles" :key="bundle"
                  :value="bundle.id">
                    {{ bundle.label }}
                  </option>
                </select>

                <span v-if="parentType==='machine'" class="col-12 m-0 p-0 tx-bold tx-14">
                  {{dictionary['Máquinas'][session.user.language]+": "}}
                </span>
                <select v-if="parentType==='machine'"
                class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                v-model="targetEntityId"
                @change="onTargetEntity">
                  <option v-for="machine in availableMachines" :key="machine"
                  :value="machine.id">
                    {{ machine.label }}
                  </option>
                </select>

                <span v-if="parentType==='emplacement'" class="col-12 m-0 p-0 tx-bold tx-14">
                  {{dictionary['Emplazamiento'][session.user.language]+": "}}
                </span>
                <select v-if="parentType==='emplacement'"
                class="col-12 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                v-model="targetEntityId"
                @change="onTargetEntity">
                  <option v-for="emplacement in availableEmplacements" :key="emplacement"
                  :value="emplacement.id">
                    {{ emplacement.label }}
                  </option>
                </select>

              </div>
              <div class="col-lg-12 col-md-12 col-12 p-2 ps-1 pe-1">
<!-- SUBMIT -->
                <button type="button" id="btnSubmitTargetDevice" class="button-ok gradient-tablas w-100"
                @click="linkDeviceToEntity">
                  {{dictionary['Aceptar'][session.user.language]}}
                </button>
              </div>

              <div class="col-lg-12 col-md-12 col-12 p-2 ps-1 pe-1">
<!-- DISMISS -->
                <button type="button" id="btnCancelTargetDevice" class="button-ok gradient-tablas w-100"
                @click="this.$emit('dismissAlert')">
                  {{dictionary['Cancelar'][session.user.language]}}
                </button>
              </div>
            </div>

            <div v-if="linkAction === 'link'" 
            class="col-lg-8 col-md-8 col-12 m-0 p-2 d-flex flex-column tx-left">
              <div class="col-12 d-flex flex-wrap justify-content-center bg-menu rounded-5 text-center m-0 p-3">
                
                <div class="blueprintCanvas w-100 m-0 p-0">
                  <img id="emplacementBlueprintForDevice" class="w-100 m-0 p-0 rounded-3"
                  @click="onSetNewCoordinates"
                  :src="displayBlueprint()">
                  <div id="linkDeviceBlueprintMarker" style="color: red;">
                    <ion-icon name="locate-outline" class="tx-20"></ion-icon>
                  </div>
                </div>
<!-- LATITUD -->
                <div class="col-lg-6 col-12 m-0 p-2" style="text-align: left;">
                  <label class="m-0 p-0 tx-13 tx-clomos-white">
                    {{dictionary['Latitud'][session.user.language]}} *
                  </label><br>
                  <input v-model="newGeoJSON.geometry.coordinates[0]" 
                  id="inputLinkDevciceLat" type="number" step=0.00001
                  class="form-control form-control-line" 
                  @change="onPositionChanged">
                </div>

<!-- LONGITUD -->
                <div class="col-lg-6 col-12 m-0 p-2" style="text-align: left;">
                  <label class="m-0 p-0 tx-13 tx-clomos-white">
                    {{dictionary['Longitud'][session.user.language]}} *
                  </label><br>
                  <input v-model="newGeoJSON.geometry.coordinates[1]" 
                  id="inputLinkDevciceLng" type="number" step=0.00001
                  class="form-control form-control-line" 
                  @change="onPositionChanged">
                </div>
                
              </div>
            </div>
            
            <div v-else
            class="col-lg-8 col-md-8 col-12 m-0 p-2 d-flex flex-column tx-left">
              <!--<span class="col-12 m-0 p-0 tx-bold tx-clomos-midtone">
                {{dictionary['Posición'][session.user.language]+": "}}
              </span>-->

              <div class="col-12 d-flex flex-wrap justify-content-center bg-menu rounded-5 text-center m-0 p-3">
                <div id="deviceMapContent" class="m-0 p-3 rounded-3" style="width: 100%; height: 275px; index: 0;">
                </div>
<!-- LATITUD -->
                <div class="col-lg-6 col-12 m-0 p-2" style="text-align: left;">
                  <label class="m-0 p-0 tx-13 tx-clomos-white">
                    {{dictionary['Latitud'][session.user.language]}} *
                  </label><br>
                  <input v-model="newGeoJSON.geometry.coordinates[0]" 
                  id="inputLinkDeviceLat" type="number" step=0.00001 
                  class="form-control form-control-line" 
                  @change="onPositionChanged">
                </div>

<!-- LONGITUD -->
                <div class="col-lg-6 col-12 m-0 p-2" style="text-align: left;">
                  <label class="m-0 p-0 tx-13 tx-clomos-white">
                    {{dictionary['Longitud'][session.user.language]}} *
                  </label><br>
                  <input v-model="newGeoJSON.geometry.coordinates[1]" 
                  id="inputLinkDeviceLng" type="number" step=0.00001 
                  class="form-control form-control-line" 
                  @change="onPositionChanged">
                </div>
              </div>
            </div>

            <div class="row m-0 p-2">
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
// Clomos
import api from '@/services/api';

export default {
  name: "TargetDeviceModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    targetDeviceId: {
      type: String,
      required: true,
    },
    linkAction: {
      type: String,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      targetDeviceData: {},
      parentType: "emplacement",
      targetEntityId: "",
      targetEntity: {},
      availableDevices: [],
      availableBundles: [],
      availableMachines: [],
      availableEmplacements: [],
      newGeoJSON: { 
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [0,0]
        }
      },
      deviceMap: undefined,
      deviceMarker: undefined
    }
  },
  computed: {
    bluePrintMarkerPosition() {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForDevice');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;
        
        let domX = this.newGeoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 25 ;
        let domY = this.newGeoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 25;
  
        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "color: red; position: absolute; top: " + markerY +"px; left: " + markerX + "px;"
    }
  },
  emits: ["dismissAlert"],
  methods: {
    // DISPLAY
    displayMap() {
      if (this.deviceMap != undefined) {
        this.deviceMap.invalidateSize();
        this.deviceMap.remove();
      }
      var deviceMap = Leaflet.map("deviceMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang='+this.session.user.language,{maxZoom: 19}).addTo(deviceMap);
      deviceMap.on('click', this.onSetNewCoordinates)
      
      this.deviceMap = deviceMap;
      
      let deviceMapListForGroup = []; 
      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
      var deviceMarker = Leaflet.marker([this.newGeoJSON.geometry.coordinates[0],this.newGeoJSON.geometry.coordinates[1]]);
      deviceMarker.addTo(deviceMap);
      deviceMapListForGroup.push(deviceMarker);
      var deviceMapGroup = Leaflet.featureGroup(deviceMapListForGroup);
      this.deviceMap.fitBounds(deviceMapGroup.getBounds());

      this.deviceMarker = deviceMarker;
    },
    displayBlueprint() {
      let src = "";
      if (this.targetEntity != undefined
      && this.targetEntity.variables != undefined
      && this.targetEntity.variables.dynamic != undefined
      && this.targetEntity.variables.dynamic.urlPlano != undefined)
        src = this.targetEntity.variables.dynamic.urlPlano.value;
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    onPositionChanged() {
      if (this.linkAction==="link")
        document.getElementById('linkDeviceBlueprintMarker').style = this.bluePrintMarkerPosition;
      else {
        this.deviceMap.removeLayer(this.deviceMarker);
        this.deviceMarker = Leaflet.marker([this.newGeoJSON.geometry.coordinates[0],this.newGeoJSON.geometry.coordinates[1]]).addTo(this.deviceMap);
      }
    },
    onChangeParentType() {
      this.targetEntity = {};
      if (this.parentType === 'device') {
        this.getDeviceList();
      }
      if (this.parentType === 'bundle') {
        this.getBundleList();
      }
      if (this.parentType === 'machine') {
        this.getMachineList();
      }
      if (this.parentType === 'emplacement') {
        this.getEmplacementList();
      }
    },
    // GET
    async getTargetDeviceData(callback) {
      let response = await api.getDeviceData(this.targetDeviceId);
      this.targetDeviceData = api.parseDeviceFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetDeviceData);
    },
    async getDeviceList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      let allAvailableDevices = api.parseDevicesFromResponse(response);
      this.availableDevices = [];
      for (let i in allAvailableDevices) {
        if (allAvailableDevices[i].id != this.targetDeviceId) this.availableDevices.push(allAvailableDevices[i])
      }
      if (this.availableDevices[0] != undefined) {
        this.targetEntityId = this.availableDevices[0].id;
        this.targetEntity = this.availableDevices[0];
      }
      if (callback && typeof callback === "function") callback(this.availableDevices);
    },
    async getBundleList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.availableBundles = api.parseBundlesFromResponse(response);
      if (this.availableBundles[0] != undefined) {
        this.targetEntityId = this.availableBundles[0].id;
        this.targetEntity = this.availableBundles[0];
      }
      if (callback && typeof callback === "function") callback(this.availableBundles);
    },
    async getMachineList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.availableMachines = api.parseMachinesFromResponse(response);
      if (this.availableMachines[0] != undefined) {
        this.targetEntityId = this.availableMachines[0].id;
        this.targetEntity = this.availableMachines[0];
      }if (callback && typeof callback === "function") callback(this.availableMachines);
    },
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.availableEmplacements = api.parseEmplacementsFromResponse(response);
      if (this.availableEmplacements[0] != undefined) {
        this.targetEntityId = this.availableEmplacements[0].id;
        this.targetEntity = this.availableEmplacements[0];
      }if (callback && typeof callback === "function") callback(this.availableEmplacements);
    },
    // TARGET
    onTargetEntity(event) {
      this.targetEntityId = event.target.value;
      if (this.parentType==='device') for (let i in this.availableDevices) {
        if (this.availableDevices[i].id === this.targetEntityId) 
        this.targetEntity = this.availableDevices[i];
        if (this.targetEntity != undefined
        && this.targetEntity.variables != undefined
        && this.targetEntity.variables.dynamic != undefined
        && this.targetEntity.variables.dynamic.urlPlano != undefined) {  
          this.displayBlueprint();
          this.newGeoJSON.properties = {srcId: this.targetEntity.variables.dynamic.urlPlano}
        }
      }
      else if (this.parentType==='bundle') for (let i in this.availableBundles) {
        if (this.availableBundles[i].id === this.targetEntityId) 
        this.targetEntity = this.availableBundles[i];
        if (this.targetEntity != undefined
        && this.targetEntity.variables != undefined
        && this.targetEntity.variables.dynamic != undefined
        && this.targetEntity.variables.dynamic.urlPlano != undefined) {  
          this.displayBlueprint();
          this.newGeoJSON.properties = {srcId: this.targetEntity.variables.dynamic.urlPlano}
        }
      }
      else if (this.parentType==='machine') for (let i in this.availableMachines) {
        if (this.availableMachines[i].id === this.targetEntityId) 
        this.targetEntity = this.availableMachines[i];
        if (this.targetEntity != undefined
        && this.targetEntity.variables != undefined
        && this.targetEntity.variables.dynamic != undefined
        && this.targetEntity.variables.dynamic.urlPlano != undefined) {  
          this.displayBlueprint();
          this.newGeoJSON.properties = {srcId: this.targetEntity.variables.dynamic.urlPlano}
        }
      }
      else if (this.parentType==='emplacement') for (let i in this.availableEmplacements) {
        if (this.availableEmplacements[i].id === this.targetEntityId) 
        this.targetEntity = this.availableEmplacements[i];
        if (this.targetEntity != undefined
        && this.targetEntity.variables != undefined
        && this.targetEntity.variables.dynamic != undefined
        && this.targetEntity.variables.dynamic.urlPlano != undefined) {  
          this.displayBlueprint();
          this.newGeoJSON.properties = {srcId: this.targetEntity.variables.dynamic.urlPlano}
        }
      }
    },
    // SET 
    onSetNewCoordinates(event) {
      if (this.linkAction==="link") {
        let target = event.target;
        let totalOffsetLeft = target.offsetLeft;
        let totalOffsetTop = target.offsetTop;
        let totalScrollLeft = target.scrollLeft;
        let totalScrollTop = target.scrollTop;
        while (target.offsetParent != undefined) {
          target = target.offsetParent;
          totalOffsetLeft += target.offsetLeft;
          totalOffsetTop += target.offsetTop;
          totalScrollLeft += target.scrollLeft;
          totalScrollTop += target.scrollTop;
        }
        let ratioX = event.target.naturalWidth / event.target.offsetWidth;
        let ratioY = event.target.naturalHeight / event.target.offsetHeight;

        let domX = event.x + totalScrollLeft - totalOffsetLeft;
        let domY = event.y + totalScrollTop - totalOffsetTop;

        this.newGeoJSON.geometry.coordinates[0] = Math.floor(domX * ratioX);
        this.newGeoJSON.geometry.coordinates[1] = Math.floor(domY * ratioY);

        this.onPositionChanged();
      }
      else {
        this.newGeoJSON.geometry.coordinates = [ 
          Number(event.latlng.lat),
          Number(event.latlng.lng)
        ]
        this.deviceMap.removeLayer(this.deviceMarker);
        this.deviceMarker = Leaflet.marker([this.newGeoJSON.geometry.coordinates[0],this.newGeoJSON.geometry.coordinates[1]]).addTo(this.deviceMap);
      }
    },
    async linkDeviceToEntity(callback) {
      let response = {};
      let request = {
        deviceId : this.targetDeviceId,
        parentId : this.targetEntityId,
        geoJSON : this.newGeoJSON
      }
      if (this.parentType === 'device') {
        response = await api.setDeviceParentAsDevice(request);
      }
      else if (this.parentType === 'bundle') {
        response = await api.setDeviceParentAsBundle(request);
      }
      else if (this.parentType === 'machine') {
        response = await api.setDeviceParentAsMachine(request);
      }
      else if (this.parentType === 'emplacement') {
        response = await api.setDeviceParentAsEmplacement(request);
      }
      if (response["status-code"]==="200") this.$emit('dismissAlert');
      if (callback && typeof callback === "function") callback(this.availableEmplacements);
    }
  },
  async created() {
    await this.getTargetDeviceData();
    if (this.linkAction === "link") {
      await this.getEmplacementList();
      if (this.availableEmplacements[0]!=undefined)
        this.targetEntityId = this.availableEmplacements[0].id;
      setTimeout(()=>{this.onPositionChanged()}, 300);
    }
    else {
      this.targetEntityId = "";
      setTimeout(()=>{this.displayMap()}, 300);
    } 
  }
}
</script>

<style>
#textAlertModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>