<template>
  <!-- MODAL New Incidence -->
  <div id="newIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">

      <!-- HEAD New Incidence -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Nueva Incidencia'][session.user.language] }}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewIncidenceModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT New Incidence -->
      <div class="bg-caja col-12 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="p-1 m-0 border rounded form-material">
              <div class="bg-menu text-white p-1 m-0 rounded">
                {{ dictionary['Datos de la incidencia'][session.user.language] }}
              </div>

              <div class="row ">

                <!-- NOMBRE -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Nombre'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <input v-model="newIncidenceData.label" id="inputNewIncidenceLabel" type="text"
                      class="form-control form-control-line" style="color:black;">
                  </div>
                </div>
              </div>

              <div class="row">

                <!-- DESCRIPCIÓN -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Descripción'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <textarea v-model="newIncidenceData.description" id="inputNewIncidenceDescription" type="text"
                      rows="1" class="form-control form-control-line" style="color:black;" />
                  </div>
                </div>

              </div>

              <div class="row">
                <!-- roles -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Rol usuario aviso'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <select v-model="newIncidenceData.rol" id="inputNewIncidenceRol"
                      class="form-control form-control-line" style="color:black;">
                      <option value=128>
                        {{ dictionary["Administrador de la empresa"][session.user.language] }}
                      </option>
                      <option value=32>
                        {{ dictionary["Autorizado/Editor"][session.user.language] }}
                      </option>
                      <option value=2>
                        {{ dictionary["Administrador general"][session.user.language] }}
                      </option>
                    </select>
                  </div>
                </div>


              </div>
              <div class="row">
                <!-- estado -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Estado envio aviso'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <select v-model="newIncidenceData.status" id="inputNewIncidenceStatus"
                      class="form-control form-control-line" style="color:black;">
                      <option value=0>
                        {{ dictionary["Abierta"][session.user.language] }}
                      </option>
                      <option value=1>
                        {{ dictionary["En investigación"][session.user.language] }}
                      </option>
                      <option value=2>
                        {{ dictionary["En proceso de resolucion"][session.user.language] }}
                      </option>
                      <option value=3>
                        {{ dictionary["Solucionada"][session.user.language] }}
                      </option>
                      <option value=4>
                        {{ dictionary["Cerrada"][session.user.language] }}
                      </option>
                    </select>
                  </div>
                </div>


              </div>
              <div class="row">

                <!-- mail -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Aviso email'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <div id="inputNewIncidencemail" class="form-control border-0 tx-bold w-100"
                      :class="newIncidenceData.email.toString()"
                      @click="(newIncidenceData.email === true) ? newIncidenceData.email = false : newIncidenceData.email = true">
                      {{ dictionary[newIncidenceData.email][session.user.language] }}
                    </div>
                  </div>
                </div>
                <!-- sms -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Aviso sms'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <div id="inputNewProductsms" class="form-control border-0 tx-bold w-100"
                      :class="newIncidenceData.sms.toString()"
                      @click="(newIncidenceData.sms === true) ? newIncidenceData.sms = false : newIncidenceData.sms = true">
                      {{ dictionary[newIncidenceData.sms][session.user.language] }}
                    </div>
                  </div>
                </div>




              </div>
              <div class="row">

                <!-- Nivel -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Nivel de gravedad'][session.user.language] }}
                  </label>
                  <div class="col-12 d-flex flex-wrap justify-content-around pt-2">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="muyBaja" value="0"
                        v-model="newIncidenceData.level" />
                      <label class="form-check-label tx-13" for="muyBaja">{{ dictionary['Muy baja'][session.user.language] }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="baja" value="1"
                        v-model="newIncidenceData.level" />
                      <label class="form-check-label tx-13" for="baja">{{ dictionary['Baja'][session.user.language] }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="media" value="2"
                        v-model="newIncidenceData.level" />
                      <label class="form-check-label tx-13" for="media">{{
                    dictionary['media'][session.user.language][0].toUpperCase() +
                      dictionary['media'][session.user.language].slice(1) }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="alta" value="3"
                        v-model="newIncidenceData.level" />
                      <label class="form-check-label tx-13" for="alta">{{ dictionary['Alta'][session.user.language] }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="critica" value="4"
                        v-model="newIncidenceData.level" />
                      <label class="form-check-label tx-13" for="critica">{{ dictionary['Critica'][session.user.language] }}</label>
                    </div>
                  </div>
                </div>



              </div>

              <div class="col-12 p-0 ps-1 pe-1">
                <hr class="tx-clomos-dark ms-2 me-2">
              </div>

              <div class="row m-0 ps-1">
                <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-2">
                  {{ dictionary['Tiempo de repetición del aviso'][session.user.language] }}
                </label>
              </div>

              <div class="row">
                <!-- DIAS -->
                <div class="form-group col-md-4 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Dias'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <input v-model="newIncidenceData.days" id="inputNewIncidenceDays" type="number" min=0
                      class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

                <!-- MINUTOS -->
                <div class="form-group col-md-4 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['minutos'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <input v-model="newIncidenceData.minutes" id="inputNewIncidenceMinutes" type="number" min=0
                      class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

                <!-- SEGUNDOS -->
                <div class="form-group col-md-4 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Segundos'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <input v-model="newIncidenceData.seconds" id="inputNewIncidenceSeconds" type="number" min=0
                      class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

              </div>
              <div class="row">

                <!-- SUBMIT -->
                <div class="form-group col-12 p-1">
                  <div class="col-12 mt-2 mb-2">
                    <button type="button" @click="onCreateNewIncidence()" id="btnSubmitCreateNewProduct"
                      class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{ dictionary['Crear incidencia'][session.user.language] }}
                        </span>
                      </span>
                    </button>
                  </div>
                </div>

              </div>

            </form>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewIncidenceModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      newIncidenceData: {
        nifCif: this.$route.params.currentCompanyNifCif,
        label: "",
        description: "",
        rol: null,
        sms: "false",
        email: "false",
        level: "",
        days: null,
        minutes: null,
        seconds: null,
        repetitionTime: null,
        status: null,
      },
    }
  },
  emits: ["toggleNewIncidenceModal"],
  methods: {
    async onCreateNewIncidence() {
      let valid = true;
      if (this.newIncidenceData.label === "") {
        $("#inputNewIncidenceLabel").addClass("missing");
        valid = false;
      }
      if (this.newIncidenceData.description === "") {
        $("#inputNewIncidenceDescription").addClass("missing");
        valid = false;
      }
      if (this.newIncidenceData.rol === "") {
        $("#inputNewIncidenceRol").addClass("missing");
        valid = false;
      }
      if (this.newIncidenceData.status === "") {
        $("#inputNewIncidenceStatus").addClass("missing");
        valid = false;
      }
      if (this.newIncidenceData.level === "") {
        $("#inputNewIncidenceLevel").addClass("missing");
        valid = false;
      }
      if (this.newIncidenceData.days === null && this.newIncidenceData.minutes === null && this.newIncidenceData.seconds === null) {
        $("#inputNewIncidenceDays").addClass("missing"); ~
          $("#inputNewIncidenceMinutes").addClass("missing");
        $("#inputNewIncidenceSeconds").addClass("missing");
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $(".missing").removeClass("missing");
        }, 3000);
        return;
      }

      const totalSeconds = (this.newIncidenceData.days !== null || this.newIncidenceData.days === "" ? this.newIncidenceData.days * 86400 : 0) + (this.newIncidenceData.minutes !== null || this.newIncidenceData.minutes === "" ? this.newIncidenceData.minutes * 60 : 0) + (this.newIncidenceData.seconds !== null || this.newIncidenceData.seconds === "" ? this.newIncidenceData.seconds : 0);
      if (totalSeconds>60&&totalSeconds<172800){
        this.newIncidenceData.repetitionTime = totalSeconds;
      } else {
        window.alert(this.dictionary['El tiempo de repeticion tiene que ser mayor a 1 minuto y menor de 48 horas'][this.session.user.language])
        return
      }
      
      

      try {
        console.log(this.newIncidenceData)
        let response = await api.postNewIncidence(this.newIncidenceData);
        if (response["status-code"] === "201") {
          this.$emit('toggleNewIncidenceModal');
          window.alert("La incidencia ha sido creado satisfactoriamente.");
        } else {
          window.alert("Error al crear la incidencia.");
        }
      } catch (error) {
        console.error("Error creating incidence:", error);
        window.alert("Error al crear la incidencia.");
      }
    },

  }
}
</script>


<style>
#newIncidenceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>