<template>
    <!-- MODAL Historic Incidence -->
    <div id="historicIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
      <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
        <!-- HEAD -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
          <div class="col-10">
            <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
              <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              {{ editableCondition.label }}
            </h6>
          </div>
          <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditCondition')">
            <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
          </div>
        </div>
        <!-- CONTENT Edit Condition -->
        <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
          <div id="editDeviceScroll" style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
            <div class="col-12 p-2 pe-0 pb-0">
              <form class="p-0 overflow-hidden d-flex flex-wrap pe-2">
                <div class="col-12 m-0 p-2 border rounded overflow-hidden">
                  <div class="bg-menu text-white p-1 m-0 rounded">
                    {{ dictionary['Condicion'][session.user.language] + ": " + idCondition }}
                    <span v-if="!editView" @click="toggleEditMode()" class="pe-3 text-decoration-underline" style="float: right; cursor: pointer;">Editar</span>
                    <div v-if="editView" style="float: right;" class="pe-3">
                      <span @click="toggleEditMode()" class="pe-3 text-decoration-underline" style="cursor: pointer;">Descartar</span>
                    </div>
                  </div>
                  
                  <div class="row pt-3">
                    <div class="form-group col-6 p-1">
                      <label class="col-12 tx-13 text-dark text-nowrap">{{ dictionary['Nombre condición'][session.user.language] }}</label>
                      <div class="col-12">
                        <input class="form-control" type="text" style="color:black;" id="nameCondition" v-model="editableCondition.label" :disabled="!editView" />
                      </div>
                    </div>
                    <div class="form-group col-6 p-1">
                      <label class="col-12 tx-13 text-dark text-nowrap">{{ dictionary['Variable'][session.user.language]  }}</label>
                      <div class="col-12">
                        <select v-model="editableCondition.variableLabel" disabled id="variableId" class="form-control form-control-line" style="color:black;">
                          
                          <option v-for="item in variableList" :key="item.variableId" :value="item.label">
                            {{ item.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="editableCondition.type==='1'" class="form-group col-lg-12 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                      {{ dictionary['Activado'][session.user.language]  }}
                                    </label>
                                    <div class="col-12">
                                        <select v-model="editableCondition.isActiveWhen" :disabled="!editView" id="inputNewIncidence"
                                            class="form-control form-control-line" style="color:black;">
                                            <option value="true">{{ dictionary['Si'][session.user.language]  }}</option>
                                            <option value="false">{{ dictionary['No'][session.user.language]  }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="editableCondition.type==='0'" class="form-group col-lg-6 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                        {{ dictionary['Límite superior'][session.user.language]  }}
                                    </label>
                                    <div class="col-12">
                                        <input class="form-control" :disabled="!editView" type="number" style="color:black;" id="upperLimit"
                                            v-model="editableCondition.upperLimit" />
                                    </div>
                                </div>
                                <div v-if="editableCondition.type==='0'" class="form-group  col-lg-6 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                      {{ dictionary['Límite inferior'][session.user.language]  }}
                                    </label>
                                    <div class="col-12">
                                        <input class="form-control" :disabled="!editView" type="number" style="color:black;" id="lowerLimit"
                                            v-model="editableCondition.lowerLimit" />
                                    </div>
                                </div>
                                <div class="form-group col-lg-6 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                      {{ dictionary['Alarma'][session.user.language]  }}
                                    </label>
                                    <div class="col-12">
                                        <select v-model="editableCondition.isAlarm"  :disabled="!editView" id="inputNewIncidence"
                                            class="form-control form-control-line" style="color:black;">
                                            <option value="true">{{ dictionary['Si'][session.user.language]  }}</option>
                                            <option value="false">{{ dictionary['No'][session.user.language]  }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6 p-1"
                                   >
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                      {{ dictionary['Alarma Silenciada'][session.user.language]  }}
                                    </label>
                                    <div class="col-12">
                                        <select v-model="editableCondition.isMuted" id="inputNewIncidence"
                                            class="form-control form-control-line" style="color:black;"
                                            :disabled="!editView||(editView&&editableCondition.isAlarm==='false')">
                                            <option value="true">{{ dictionary['Si'][session.user.language]  }}</option>
                                            <option value="false">{{ dictionary['No'][session.user.language]  }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row justify-content-center p-2">
                                <div class="form-group col-6 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                      {{ dictionary['Seleccione incidencia'][session.user.language]  }}
                                    </label>
                                    <div class="col-12">
                                        <select  :disabled="!editView" v-model="editableCondition.incidenceType" id="incidenceId"
                                            class="form-control form-control-line" style="color:black;">
                                            <option value="" disabled> {{ dictionary['Seleccione una opción'][session.user.language]  }}</option>
                                            <option v-for="item in incidenceList" :key="item.id" :value="item.id">
                                                {{ item.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                </div>


                                <div class="col-6">
                                    <label class="col-12 tx-13 ps-4 text-dark text-nowrap">
                                        <p class="tx-16 text-decoration-underline">{{ dictionary['Tiempo de activacion'][session.user.language]  }}</p>
                                    </label>
                                    <!-- DIAS -->
                                    <div class="form-group col-md-12 col-12 p-1">
                                        <label class="col-12 tx-13 text-dark text-nowrap">
                                          {{dictionary['Dias'][session.user.language]}}
                                    </label>
                                        <div class="col-12">
                                            <input v-model="editableCondition.activationTime.days"
                                                id="inputactivationTimeDays" type="number" min=0
                                                class="form-control form-control-line" style="color:black;"
                                                :placeholder="dictionary['Dias'][session.user.language]" :disabled="!editView">
                                        </div>
                                    </div>

                                    <!-- MINUTOS -->
                                    <div class="form-group col-md-12 col-12 p-1">
                                        <label class="col-12 tx-13 text-dark text-nowrap">
                                          {{dictionary['minutos'][session.user.language]}}
                                    </label>
                                        <div class="col-12">
                                            <input v-model="editableCondition.activationTime.minutes"
                                                :placeholder="dictionary['minutos'][session.user.language]"
                                                id="inputactivationTimeMinutes" type="number" min=0
                                                class="form-control form-control-line" style="color:black;" :disabled="!editView">
                                        </div>
                                    </div>

                                    <!-- SEGUNDOS -->
                                    <div class="form-group col-md-12 col-12 p-1">
                                        <label class="col-12 tx-13 text-dark text-nowrap">
                                          {{dictionary['Segundos'][session.user.language]}}
                                    </label>
                                        <div class="col-12">
                                            <input v-model="editableCondition.activationTime.seconds"
                                                id="inputactivationTimeSeconds" type="number" min=0
                                                class="form-control form-control-line" style="color:black;"
                                                :placeholder="dictionary['Segundos'][session.user.language]" :disabled="!editView">
                                        </div>
                                    </div>

                                </div>
                                <div class="col-6">
                                    <label class="col-12 tx-13 ps-4 text-dark text-nowrap">
                                        <p class="tx-16 text-decoration-underline">Tiempo de postactivacion</p>
                                    </label>
                                    <!-- DIAS -->
                                    <div class="form-group col-md-12 col-12 p-1">
                                        <label class="col-12 tx-13 text-dark text-nowrap">
                                          {{dictionary['Dias'][session.user.language]}}
                                    </label>
                                        <div class="col-12">
                                            <input v-model="editableCondition.postActivationTime.days"
                                                id="inputPostActivationTimeDays" type="number" min=0
                                                class="form-control form-control-line" style="color:black;"
                                                :placeholder="dictionary['Dias'][session.user.language]" :disabled="!editView">
                                        </div>
                                    </div>

                                    <!-- MINUTOS -->
                                    <div class="form-group col-md-12 col-12 p-1">
                                        <label class="col-12 tx-13 text-dark text-nowrap">
                                          {{dictionary['minutos'][session.user.language]}}
                                    </label>
                                        <div class="col-12">
                                            <input v-model="editableCondition.postActivationTime.minutes"
                                                :placeholder="dictionary['minutos'][session.user.language]"
                                                id="inputPostActivationTimeMinutes" type="number" min=0
                                                class="form-control form-control-line" style="color:black;" :disabled="!editView">
                                        </div>
                                    </div>

                                    <!-- SEGUNDOS -->
                                    <div class="form-group col-md-12 col-12 p-1">
                                        <label class="col-12 tx-13 text-dark text-nowrap">
                                         {{ dictionary['Segundos'][session.user.language]}}
                                    </label>
                                        <div class="col-12">
                                            <input v-model="editableCondition.postActivationTime.seconds"
                                                id="inputPostActivationTimeSeconds" type="number" min=0
                                                class="form-control form-control-line" style="color:black;"
                                                :placeholder="dictionary['Segundos'][session.user.language]" :disabled="!editView">
                                        </div>
                                    </div>

                                </div>

                  </div>
                  <div class="form-group col-lg-12 p-1 pt-3">
                                    <button v-if="editView" type="button" id="btnSubmitCreateNewLocking"
                                        class="button-ok tx-14 tx-clomos-dark rounded-3 text-center"
                                        :class="view ? 'bg-clomos' : 'bg-primary'" @click="editCondition"
                                        style="vertical-align: middle; width: 80%; height: 2.2rem;">
                                        {{ dictionary['Enviar'][session.user.language] }}
                                    </button>
                                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  
  export default {
    name: "EditConditionModal",
    props: {
      idCondition: {
        type: String,
        required: true
      },
      idDcl : {
        type: String,
        required: true
      },      
      session: {
        type: Object,
        required: true,
      },
      dictionary: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        incidenceType: {}, 
        incidenceId: "", 
        editView: false, 
        editableCondition: {
          name: '',
          selectedVariable: '',
          isActiveWhen: false,
          upperLimit: null,
          lowerLimit: null,
          alarm: false,
          silencedAlarm: false,
          selectedIncidence: '',
          activationTime: {
            days: 0,
            minutes: 0,
            seconds: 0
          },
          postActivationTime: {
            days: 0,
            minutes: 0,
            seconds: 0
          },
          
        },
        variableList: [], 
        incidenceList: [],       
        
        
      }
    },
    emits: ["toggleEditConditionModal"],
    methods: {
      async toggleEditMode() {
        await this.getCondition()
        await this.getMachine()
        this.editView = !this.editView;
      },
      parseTime(totalSeconds) {
    let days = Math.floor(totalSeconds / (24 * 3600)); 
    let remainingSecondsAfterDays = totalSeconds % (24 * 3600);
    let minutes = Math.floor(remainingSecondsAfterDays / 60);
    let seconds = remainingSecondsAfterDays % 60;

    return {
        days: days,
        minutes: minutes,
        seconds: seconds
    };
        },
      //get
      async getCondition(){
        let response = await api.getCondition(this.idCondition)
        this.editableCondition = api.parseConditionFromResponse(response);  
        if(this.editableCondition.hasUpperLimit==='false')    this.editableCondition.upperLimit=''
        if(this.editableCondition.hasLowerLimit==='false')    this.editableCondition.lowerLimit=''
        let totalActivationTime = Number(this.editableCondition.activationTime);
        let totalPostActivationTime = Number(this.editableCondition.postActivationTime);
         this.editableCondition.activationTime ={}
            this.editableCondition.postActivationTime ={}       
        let parsedActivationTime = this.parseTime(totalActivationTime);
        this.editableCondition.activationTime.days = parsedActivationTime.days;
        this.editableCondition.activationTime.minutes = parsedActivationTime.minutes;
        this.editableCondition.activationTime.seconds = parsedActivationTime.seconds;        
        let parsedPostActivationTime = this.parseTime(totalPostActivationTime);
        this.editableCondition.postActivationTime.days = parsedPostActivationTime.days;
        this.editableCondition.postActivationTime.minutes = parsedPostActivationTime.minutes;
        this.editableCondition.postActivationTime.seconds = parsedPostActivationTime.seconds;
        
      },
      async getIncidencesByCompany() {
            const url = new URL(window.location.href);
            const pathParts = url.pathname.split('/');
            let request = {
                nifCif: pathParts[2] === this.session.user.nifCif ? this.session.user.nifCif : pathParts[2],
                limit: 0,
                offset: 0,

            };
            let response = await api.getIncidencesByCompany(request);
            this.incidenceList = api.parseIncidenceListFromResponse(response);
        },
        async getMachine() {
            try {
                let response = await api.getLockerData(this.idDcl);             
               
                this.machineList=api.parseMachineFromResponse(response)
                
                this.getVariables()
            } catch (error) {
                console.error(error)
            }

        },
        getVariables() {
            const selectedMachine = this.machineList

            if (selectedMachine && selectedMachine.variables) {
                this.variableList = [];
                for (const type in selectedMachine.variables) {
                    if (type === 'dynamic') continue;

                    const variablesOfType = selectedMachine.variables[type];
                    for (const variableName in variablesOfType) {
                        const variable = variablesOfType[variableName];                        
                        if( this.editableCondition.type!= variable.type){
                            this.variableList.push({
                            label: variable.label,
                            variableId: variable.variableId,
                            type: type
                        });
                        } 
                        
                    }
                }
            } else {
                this.variableList = [];
            }
        },
        //set
        async editCondition() {
    try {
        
        const selectedVariable = this.variableList.find(v => v.label === this.editableCondition.variableLabel);
        if (selectedVariable) {
            this.editableCondition.idVariable = selectedVariable.variableId;
        } else {
            throw new Error("Variable no encontrada");
        }
        
        const calculateTotalTime = (time) => (
            time.seconds + (time.minutes * 60) + (time.days * 86400)
        );
        this.editableCondition.activationTime.total = calculateTotalTime(this.editableCondition.activationTime);
        this.editableCondition.postActivationTime.total = calculateTotalTime(this.editableCondition.postActivationTime);
        
       
       let response = this.editableCondition.type === '0' ? await api.setAnalogCondition(this.editableCondition) : await api.setDigitalCondition(this.editableCondition)
       
        if (response["status-code"] === '200'|| response["status-code"] === '201') {
          window.alert(this.dictionary['Actualizado con exito'][this.session.user.language]);
           this.toggleEditMode()
        } else {
            window.alert(this.dictionary['Error al actualizar la condición'][this.session.user.language]);
        }
    } catch (error) {
        console.error("Error en editCondition:", error);
        window.alert(error.message || "Error desconocido");
    }
      }
    },
    async created() {
        await this.getCondition()
        await this.getMachine()
        await this.getIncidencesByCompany()
         
    }
  };
  </script>
<style>
</style>