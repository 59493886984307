<template>
  <!-- SECTION Orders-->
  <div id="dashboardSection" class="col-12 justify-content-center" :class="'section-container-lg'">
    <div class="row col-12 p-2 m-0 section-container justify-content-cente">
      <div class="row col-12 p-2 m-0 section-container justify-content-center">
        <!-- HEADER Orders -->

        <div class="col-lg-3 col-12 text-white pb-4">
          <div class="col-12 bg-lat rounded-top-5 justify-content-center">
            <h4 class="tx-left text-white text-nowrap mb-0 ps-1 pt-1">
              <ion-icon name="alert-circle-outline" class="tx-18 me-2 align-bottom"></ion-icon>
               <span class="tx-16">Incidencias</span>
            </h4>
          </div>
          <div class="col-12 bg-lat rounded-bottom-5 pb-3 justify-content-center">

            <div class="col-lg-12 col-md-12 col-12 p-2 pt-3">
              <div class="kpi-card bg-light text-center p-3 rounded sombra">
                <span class="kpi-title tx-14 text-dark">Total de incidencias abiertas:</span>
                <p class="kpi-value tx-24 mt-2 text-dark tx-bold">0</p>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 p-2">
              <div class="kpi-card bg-light text-center p-3 rounded sombra">
                <span class="kpi-title tx-14 text-dark">Tiempo promedio de resolución (días): </span>
                <p class="kpi-value tx-24 mt-2 text-dark tx-bold">0.00</p>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 p-2">
              <div class="kpi-card bg-light text-center p-3 rounded sombra">
                <span class="kpi-title tx-14 text-dark">Porcentaje de incidencias cerradas: </span>
                <p class="kpi-value tx-24 mt-2 text-dark tx-bold">0%</p>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12 p-2">
              <div class="kpi-card bg-light text-center p-3 rounded sombra">
                <span class="kpi-title tx-14 text-dark">Porcentaje de incidencias abiertas: </span>
                <p class="kpi-value tx-24 mt-2 text-dark tx-bold">0%</p>
              </div>
            </div>

          </div>
        </div>

        <div class="col-lg-9 col-12 text-white pb-1">
          <div class="col-12 bg-head rounded-top-5 justify-content-between d-flex align-items-center"
            style="display: inherit;">
            <h4 class="tx-left tx-clomos-white text-nowrap align-middle mb-0 ps-1 pt-1" style="height:40px;">
              <ion-icon name="arrow-redo-outline" class="tx-18 me-2 align-bottom "></ion-icon>
              <span class="tx-16">{{dictionary["Dashboard"][session.user.language]}}</span>
            </h4>
            <div class="ms-auto">
              <span v-if="editAvailable" class="tx-16 me-3 c-pointer" @click="toggleEditVisualizationPoints">
                {{ editVisualization ? dictionary["Descartar"][session.user.language] : dictionary["Editar"][session.user.language] }}
              </span>
              <span v-if="editVisualization" class="tx-16 c-pointer" @click="saveChanges">
                {{  dictionary["Guardar cambios"][session.user.language] }}
              </span>
            </div>
          </div>

          <div class="image-container position-relative" ref="imageContainer">
            <img class="img-fluid" :src="displayedImage" />

            <!-- Interactive points -->
            <div v-for="(machine, index) in machineList" :key="index" class="point" :style="getPointStyle(machine)">
              <img src="@/assets/img/mas-verde.png" alt="Punto de interés" class="point-image"
                @click="!editVisualization ? toggleTooltip(index) : null"
                @mousedown="editVisualization ? startDrag(machine, $event) : null" />

              <!-- Individual Tooltip -->
              <div v-if="machine.tooltipVisible" class="custom-popup bg-lat" :style="getPointStyle(machine)">
                <span :data-id="machine.id" class="c-pointer tx-bold tx-12" @click="goToLink(machine)">
                  {{ machine.label }}
                </span>
                <div class="row m-0 p-0 pb-3 justify-content-between">
                  <text class="w-100 tx-12">{{ machine.emplacement }}</text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-3 pb-0 pt-0">
          <hr class="tx-clomos-dark m-0">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// Clomos
import api from '@/services/api';


export default {
  name: "DashboardComponent",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emplacementList: [
      ],
      machineList: [],
      displayedImage: '/src/assets/img/placeholder_image.jpg',
      isDragging: false,
      draggedMachine: null,
      offsetX: 0,
      offsetY: 0,
      editVisualization: false,
      editAvailable: true,
    };
  },
  methods: {
    goToLink(machine) {
      let path = this.$route.path;
      console.log(path);
      let newPath = path.replace(/(dashboard)\/?.*/, 'commandCenter');
      newPath += '/' + machine.id;
      this.$router.replace({
        path: newPath
      });
    },
    startDrag(machine) {
      this.isDragging = true;
      this.draggedMachine = machine;
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },

    onDrag(event) {
      if (!this.isDragging) return;
      const containerRect = this.$refs.imageContainer.getBoundingClientRect();
      const newLeft = ((event.clientX - containerRect.left) / containerRect.width) * 100;
      const newTop = ((event.clientY - containerRect.top) / containerRect.height) * 100;
      this.draggedMachine.geoJSON.visualization.coordinates = [newLeft, newTop];
    },

    stopDrag() {
      this.isDragging = false;
      this.draggedMachine = null;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);
    },

    async toggleEditVisualizationPoints() {
      this.editVisualization = !this.editVisualization;
      if (!this.editVisualization) {
        await this.getMachinesDataByEmplacement()
      }
    },
    async saveChanges() {
      try {
        for (const machine of this.machineList) {
          await api.setMachineData(machine);
        }
        await this.getMachinesDataByEmplacement();
        this.toggleEditVisualizationPoints()
        console.log("Cambios guardados y lista actualizada");
      } catch (error) {
        console.error("Error guardando los cambios:", error);
      }
    },
    getPointStyle(machine) {
      if (
        machine.geoJSON &&
        machine.geoJSON.visualization &&
        Array.isArray(machine.geoJSON.visualization.coordinates) &&
        machine.geoJSON.visualization.coordinates.length >= 2
      ) {
        return {
          left: machine.geoJSON.visualization.coordinates[0] + '%',
          top: machine.geoJSON.visualization.coordinates[1] + '%',
          position: 'absolute',
          cursor: this.editVisualization ? 'move' : 'pointer'
        };
      } else {
        this.editAvailable = false;
        return {
          left: '0%',
          top: '0%',
          position: 'absolute',
          cursor: this.editVisualization ? 'move' : 'pointer',
          display: 'none'
        };
      }

    },
    toggleTooltip(index) {
      this.machineList.forEach((machine, i) => {
        if (i !== index) {
          machine.tooltipVisible = false;
        }
      });
      const clickedPoint = this.machineList[index];
      clickedPoint.tooltipVisible = !clickedPoint.tooltipVisible;
      const event = window.event;
      clickedPoint.tooltipX = event.clientX;
      clickedPoint.tooltipY = event.clientY;
    },
    hideTooltip(index) {
      this.machineList[index].tooltipVisible = !this.machineList[index].tooltipVisible;
    },
    async displayImage(src) {
      if (src != undefined && src != '') {
        try {
          let response = this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
          if (response) this.displayedImage = response

        }
        catch (e) {
          console.warn(e);
        }
      }

    },

    // GET
    async getEmplacementList(callback) {
      const path = this.$route.path;
      const segments = path.split('/');
      let request = {
        nifCif: segments[2],
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      console.log('this.emplacementList[0].imgSrc', this.emplacementList[0].imgSrc)
      await this.displayImage(this.emplacementList[0].imgSrc)
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    async getMachinesDataByEmplacement(callback) {
      let request = {
        emplacementId: this.emplacementList[0].id,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByEmplacement(request);
      this.machineList = api.parseMachinesFromResponse(response);
      this.machineList.forEach((machine) => {
        machine.tooltipVisible = false;

      });
      if (callback && typeof callback === "function") callback(this.machineList);
    },
  },
  async created() {
    await this.getEmplacementList()
    if (this.emplacementList.length > 0) await this.getMachinesDataByEmplacement()

    console.log(this.machineList)
  },
  mounted() {
    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.stopDrag);
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.stopDrag);
  }
};
</script>

<style scoped>
.image-container {
  position: relative;
  display: inline-block;
}

.point-image {
  width: 20px;
  height: auto;
  position: absolute;
  transform: translate(-50%, -100%);
}

.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 14px;
  padding: 5px;
  border-radius: 4px;
  z-index: 100;
  pointer-events: none;
  transform: translate(-50%, -100%);
}
</style>