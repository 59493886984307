<template>
<!-- SECTION Bundles-->
  <div id="bundlesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
  
<!-- HEADER Bundles -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-lat border-1 rounded-5 box sombra">
          <!--<ion-icon v-if="session.user.bookmark==='bundles'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="grid" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Conjuntos"][session.user.language]}}
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr v-if="this.$route.params.currentDisplay==='bundles'" class="m-0 p-0">
              
              <select v-if="this.$route.params.currentDisplay==='bundles'"
              :value="currentCompany.nifCif"
              class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>

              <hr class="m-0 p-0 mb-3">

              <div class="row justify-content-around m-0 p-0">
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('blueprint')"
                  :style="(displayType==='blueprint')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="locate-outline" 
                    :style="(displayType==='blueprint')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('table')"
                  :style="(displayType==='table')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="list-outline" 
                    :style="(displayType==='table')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('cards')"
                  :style="(displayType==='cards')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="documents-outline" 
                    :style="(displayType==='cards')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
              </div>

              <hr class="m-0 p-0">

              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewBundleModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                @click="toggleNewBundleModal">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir conjunto"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>

      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <!--<ion-icon v-if="session.user.bookmark==='bundles'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
            <ion-icon name="grid-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            <span> {{dictionary["Conjuntos"][session.user.language] }} </span>
            <span v-if="this.$route.params.currentDisplay==='bundles' || this.targetMachineId == ''"> 
              {{ ": "+currentCompany.name }} 
            </span>
            <span v-else-if="this.targetMachineData.label != undefined"> 
              {{ ": "+targetMachineData.label }} 
            </span>
          </div>

<!-- BLUEPRINT Bundles -->
          <div v-show="displayType==='blueprint'"
          id="bundlesBlueprintContent" class="col-12 m-0 p-2 h-auto">
            <div class="blueprintCanvas col-9 m-0 p-0">
              <img id="emplacementBlueprintForBundles" class="w-100 m-0 p-2"
              :src="displayBundlesBlueprint()">
              <div v-for="bundle in bundleList" :key="bundle" :data-id="bundle.id"
              class="bg-caja rounded-3 p-2 bundleBlueprintMarker"
              :style="bluePrintMarkerPosition(bundle)">
                <div class="d-flex flex-column" style="min-width: 9rem;">
                  <span :data-id="bundle.id" class="btnTargetBundle c-pointer tx-bold tx-grey m-0 p-0">
                    {{bundle.label}}
                  </span>
                  <div class="m-0 p-0 d-flex flex-row justify-content-between">
                    <text class="w-100"> {{bundle.location}} </text>
                    <ion-icon name="create-outline" :data-id="bundle.id" class="btnEditBundle col-2 m-0 ps-1 p-0 btn tx-24 tx-primary float-end"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="bundle.id" class="btnDeleteBundle col-2 m-0 p-0 btn tx-24 tx-danger float-end"></ion-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

<!-- TABLE Bundles -->
          <div v-show="displayType==='table'" class="m-0 p-3 h-auto">
            <table id="bundlesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in bundlesColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap p-2">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>

<!-- CARDS Bundles -->
          <div v-show="displayType==='cards'&&this.bundleList[0]!=undefined"
          id="bundlesCardsContent" class="col-12 m-0 p-2 h-auto"
          :style="(!display.showEverything)?'height: 15rem':''">
            <div class="col-12 m-0 p-0 d-flex flex-row flex-wrap">
              <div v-for="bundle in bundleCardsByPages[bundleCardsPage]" :key="bundle"
              class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6 class="tx-left tx-clomos-white text-center text-nowrap m-0 p-2 ps-1 pt-2 gradient-tablas rounded-top-3">
                  <span class="m-auto">{{(bundle.label.length>18)?(bundle.label.substring(0,18)+'...'):bundle.label}}</span>
                </h6>

                <div class="col-12 border-1 rounded-bottom-3 m-0 p-3">
                  <div class="col-12 m-0 p-0 d-flex space-between">
                    <ion-icon name="pulse-outline" :data-id="bundle.id" class="btnTargetBundle col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="create-outline" :data-id="bundle.id" class="btnEditBundle col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="bundle.id" class="btnDeleteBundle col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                  </div>
                  <hr class="col-12 mt-2 mb-2">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 5rem;">
                    <span class="tx-13 tx-bold">{{dictionary['Tipo'][session.user.language]+": "}}</span>
                    <span class="tx-11 tx-start">{{bundle.type}}</span>
                    <span class="tx-13 tx-bold">{{dictionary['Identificador'][session.user.language]+": "}}</span>
                    <span class="tx-11 tx-start">{{bundle.id}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 p-2">
                <div v-for="page,index in bundleCardsByPages" :key="page" 
                class="border rounded c-pointer m-0 me-2 tx-13" :class="(bundleCardsPage===index)?'border-dark':''" style="height:2.5rem; width:2.5rem; padding: inherit;"
                @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index+1  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-show="displayType==='cards'&&this.bundleList[0]==undefined" class="m-3 p-3 border rounded">
            <span class="tx-13"> 
              {{dictionary['No hay dispositivos disponibles'][session.user.language]}} 
            </span> 
          </div>

        </div>
      </div>

    </div>
  </div>

<!-- SECTION Bundles -->
  <!-- <StatusComponent v-if="this.$route.params.currentDisplay==='bundles'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :requestedBundleId="targetBundleId"
  :dataTableOptions="dataTableOptions">
  </StatusComponent> -->

<!-- MODAL New Bundle -->
  <NewBundleModal
  v-if="display.newBundleModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :targetMachineId="targetMachineId" 
  v-on:toggleNewBundleModal="toggleNewBundleModal">
  </NewBundleModal>

<!-- MODAL Edit Bundle -->
  <EditBundleModal
  v-if="display.editBundleModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :targetMachineId="targetMachineId"
  :bundleId="targetBundleId"
  v-on:toggleEditBundleModal="toggleEditBundleModal">
  </EditBundleModal> 

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteBundleModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Conjunto a eliminar'][session.user.language] + ': '"
  :alertTarget="targetBundleId"
  v-on:acceptAlert="onDeleteBundle"
  v-on:dismissAlert="toggleDeleteBundleModal">
  </TextAlertModal>
</template>

<script>
import $ from 'jquery';
//import StatusComponent from '@/components/StatusComponent';
import NewBundleModal from '@/components/bundles/NewBundleModal';
import EditBundleModal from '@/components/bundles/EditBundleModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "BundlesComponent",
  components: { /* StatusComponent,*/ EditBundleModal, NewBundleModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetMachineId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getMachineData();
        await this.getBundleListByMachine();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.bundleList[0] != undefined && this.targetBundleId === "")
        this.targetBundleId = this.bundleList[0].id;
    },
    targetMachineId: async function() {
      if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getMachineData();
        await this.getBundleListByMachine();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.bundleList[0] != undefined && this.targetBundleId === "")
        this.targetBundleId = this.bundleList[0].id;
    }
  },
  data() {
    return {
      bundlesColumns: [
        {text:"Identificador", data: "bundleId", width: "25%", className:"tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
            +"<span data-id="+data+" class='btnTargetProduct c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Etiqueta", data: "bundleLabel", width: "15%", className:"tx-left align-middle"},
        {text:"Tipo", data: "bundleType", width: "10%", className:"tx-left align-middle"},
        {text:"Padre", data: "bundleEmplacement", width: "10%", className:"tx-left align-middle",
          render: (data) => {
            let emplacementLabel = data;
            if (this.machineList.length > 0) {
              for (let emplacement in this.machineList)
                if (this.machineList[emplacement].id === data) 
                  emplacementLabel = this.machineList[emplacement].label;
            }
            return emplacementLabel;
          }
        },
        {text:"Token", data: "bundleToken", width: "15%", className:"tx-left align-middle"},
        {text:"Fecha de comunicación", data: "bundleCommunicationDateTime", width: "10%", className:"align-middle",
          render: (data) => {
            return (data!="")
            ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
            :"<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {text:"Coordenadas", data: "bundleGeoJSON", width: "15%",className:"tx-left align-middle",
        render: (data) => {
            if (data.geometry!=undefined && data.geometry.type==="Point") return "<div class='d-flex pt-1'><span class='me-2 ms-2 tx-primary'>Lat:</span> <span>"+Number(data.geometry.coordinates[0])+"</span> <span class='me-2 ms-2 tx-primary'>Lon:</span> <span>"+Number(data.geometry.coordinates[1]) 
            else return "<span class='me-2 ms-2 tx-primary'>Lat:</span> <span>"+0+"</span>  <span class='me-2 ms-2 tx-primary'> Lon:</span></div> "+0
          }
        },
        {text:"Configuración", data: "bundleId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id="+data+" class='btnEditBundle btn tx-24 tx-primary'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "bundleId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteBundle btn tx-24 tx-danger'></ion-icon>"
          }
        },
      ],
      targetMachineData: {},
      targetBundleId: this.$route.params.targetEntity,
      bundleList: [],
      machineList: [],
      displayType : 'table',
      bundleCardsPage: 0,
      display: {
        newBundleModal: false,
        editBundleModal: false,
        linkBundleModal: false,
        deleteBundleModal: false,
      },
      linkAction: "link"
    }
  },
  computed: {
    bundleCardsByPages(){
      let bundleCardsByPages = []
      let i = 0; 
      while (i*12 < this.bundleList.length) {
        bundleCardsByPages.push(this.bundleList.slice(i*12,i*12+12));
        i++;
      }
      return bundleCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(()=>{
        if (type === "table") this.displayBundlesDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.bundleCardsPage = index;
      this.setButtonFunctions();
    },
    displayBundlesBlueprint() {
      setTimeout(()=>{
        $(".bundleBlueprintMarker").get().forEach(marker => {
          let markerId = marker.getAttribute("data-id");
          for (let i in this.deviceList) {
            if (this.deviceList[i].id === markerId) 
              marker.style = this.bluePrintMarkerPosition(this.deviceList[i]);
          }
        })
      },1000);
      let src = "";
      if (this.targetMachineData.variables != undefined
      && this.targetMachineData.variables.dynamic != undefined
      && this.targetMachineData.variables.dynamic.urlPlano != undefined)
        src = this.targetMachineData.variables.dynamic.urlPlano.value;
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    bluePrintMarkerPosition(bundle) {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForBundles');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;
        
        let domX = bundle.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7 ;
        let domY = bundle.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;
  
        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "position: absolute; top: " + markerY +"px; left: " + markerX + "px;"
    },
    displayBundlesDataTable() {
      $("#bundlesDataTable").DataTable().clear()
      for (let i in this.bundleList) {
        $("#bundlesDataTable").DataTable().rows.add([{
          bundleId: this.bundleList[i].id, 
          bundleEmplacement: this.bundleList[i].parent,
          bundleLocation: this.bundleList[i].parent,
          bundleLabel: this.bundleList[i].label, 
          bundleType: this.bundleList[i].type, 
          bundleStatus: (this.bundleList[i].variables.analog.estado!=undefined) ?this.bundleList[i].variables.analog.estado.value :"-", 
          bundleCommunicationDateTime: (this.bundleList[i].communicationDateTime != undefined && this.bundleList[i].communicationDateTime != "") ? new Date(this.bundleList[i].communicationDateTime) : "", 
          bundleGeoJSON: this.bundleList[i].geoJSON,
          bundleToken: this.bundleList[i].token
        }]);
      }
      $("#bundlesDataTable").DataTable().draw();
      this.setButtonFunctions();
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
      if($("#bundlesDataTable_paginate").get()[0] != undefined) $("#bundlesDataTable_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewBundleModal() {
      if (this.display.newBundleModal) {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
      }
      this.display.newBundleModal = !this.display.newBundleModal;
    },
    async toggleEditBundleModal() {
      if (this.display.editBundleModal) {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
      }
      this.display.editBundleModal = !this.display.editBundleModal;
    },
    async toggleLinkBundleModal() {
      if (this.display.linkBundleModal) {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {

          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
      }
      this.display.linkBundleModal = !this.display.linkBundleModal;
    },
    async toggleDeleteBundleModal() {
      if (this.display.deleteBundleModal) {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
        if (this.bundleList[0] != undefined) this.targetBundleId = this.bundleList[0].id;
      }
      this.display.deleteBundleModal = !this.display.deleteBundleModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetBundle);
        });
        $(".btnEditBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onEditBundle);
        });
        $(".btnLinkBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkBundle);
        });
        $(".btnUnlinkBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkBundle);
        });
        $(".btnDeleteBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteBundle);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getBundleListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.bundleList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.bundleList);
    },
    async getBundleListByMachine(callback) {
      let request = {
        machineId: this.targetMachineId,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleListByMachine(request);
      this.bundleList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.bundleList);
    },
    async getMachineData(callback) {
      let response = await api.getMachineData(this.targetMachineId);
      this.targetMachineData = api.parseMachineFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetMachineData);
    },
    async getMachinesDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    // TARGET 
    onTargetBundle(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
    },
    // SET 
    onEditBundle(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
      this.toggleEditBundleModal();
    },
    // LINK 
    onLinkBundle(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
      this.linkAction = "link"
      this.toggleLinkBundleModal();
    },
    // UNLINK 
    onUnlinkBundle(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
      this.linkAction = "unlink"
      this.toggleLinkBundleModal();
    },
    // DELETE
    async onDeleteBundle(event) {
      if (!this.display.deleteBundleModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetBundleId);
        this.targetBundleId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetBundleId}})
      }
      else {
        let response = await api.deleteBundle(this.targetBundleId);
        if (response["status-code"] === "200") {
          window.alert("El conjunto ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el conjunto.");
        }
      }
      await this.toggleDeleteBundleModal();
    }
  },
  async created() {
    await this.getMachinesDataByCompany();
    if (this.$route.params.currentDisplay === 'bundles') {
      await this.getBundleListByCompany();
    }
    else if (this.targetMachineId != ''){
      await this.getMachineData();
      await this.getBundleListByMachine();
    }
    this.$emit('getAvailableCompanies',this.onChangeDisplayType(this.displayType));
    if (this.bundleList[0] != undefined && this.targetBundleId === "")
      this.targetBundleId = this.bundleList[0].id;
  },
  mounted() {
    $("#bundlesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.bundlesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover, .btnTargetBundle:hover, .btnLinkBundle:hover, .btnUnlinkBundle:hover, .btnEditBundle:hover, .btnDeleteBundle:hover {
  color: var(--main-dark) !important;
}
</style>