<template>
  <!-- SECTION Installation Charts -->
  <div id="ChartsSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

      <!-- HEADER Installation Charts -->
      <div class="col-lg-2 col-md-3 col-12 m-0 p-2">
        <div class="m-auto p-auto ps-0 pe-0 bg-lat tx-white rounded-5 border-1">
          <div class="col-12 overflow-hidden justify-content-center">
            <!--<ion-icon v-if="session.user.bookmark==='charts'" name="bookmark" class="bookmark-button"></ion-icon>
            <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 pt-1">
              <ion-icon name="stats-chart" class="tx-18 me-2 align-bottom"></ion-icon>
              {{ dictionary["Gráficas"][session.user.language] }}
            </h6>
          </div>

          <div class="p-3 pt-0">
            <!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">
              <hr class="m-0 p-0">

              <select :value="currentCompany.nifCif" class="form-control mt-3 mb-3 h-100 text-nowrap bg-white"
                style="border: solid 2px white ; color: black;" @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0] != undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                  && company.relation != undefined
                  && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                  {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                </option>
              </select>
            </div>
            <div v-if="this.moduleAvailable === 2">
              <hr class="m-0 p-0">
              <label class="col-12 m-0 p-0 pb-1 tx-white tx-12">
                {{ dictionary['ID del dispositivo'][session.user.language] }}:
              </label>
              <select v-model="targetDeviceId" @change="getDeviceData" id="selectTargetDevice" data-live-search="true"
                class="form-control col-12 m-0 ps-1 text-nowrap" style="padding-bottom: 3px;">
                <option v-for="device in availableDevices" :key="device['id']" :value="device['id']"
                  :selected="device['id'] === targetDeviceId">
                  {{ device['label'] }}
                </option>
              </select>

              <label class="col-12 m-0 p-0 pb-1 tx-white tx-12">
                {{ dictionary['Variable'][session.user.language] }}:
              </label>
              <select v-model="targetVariableId" id="selectTargetDevice" data-live-search="true"
                class="form-control col-12 m-0 ps-1 text-nowrap" style="padding-bottom: 3px;">
                <option v-for="variable in historyVariables" :key="variable['variableId']"
                  :value="variable['variableId']" :selected="variable['variableId'] === targetVariableId">
                  {{ variable['label'][0].toUpperCase() + variable['label'].substring(1, variable['label'].length) }}
                </option>
              </select>
            </div>
            <div v-if="this.moduleAvailable === 1">
              <hr class="m-0 p-0">
              <label class="col-12 m-0 p-0 pb-1 tx-white tx-12">
                {{ dictionary['Activo'][session.user.language] }}:
              </label>
              <select v-model="targetDeviceId" @change="getMachineData" id="selectTargetDevice" data-live-search="true"
                class="form-control col-12 m-0 ps-1 text-nowrap" style="padding-bottom: 3px;">
                <option v-for="machine in machineList" :key="machine['id']" :value="machine['id']"
                  :selected="machine['id'] === targetDeviceId">
                  {{ machine['label'] }}
                </option>
              </select>

              <label class="col-12 m-0 p-0 pb-1 tx-white tx-12">
                {{ dictionary['Variable'][session.user.language] }}:
              </label>
              <select v-model="targetVariableId" id="selectTargetDevice" data-live-search="true"
                class="form-control col-12 m-0 ps-1 text-nowrap" style="padding-bottom: 3px;">
                <option v-for="variable in historyVariables" :key="variable['variableId']"
                  :value="variable['variableId']" :selected="variable['variableId'] === targetVariableId">
                  {{ variable['label'][0].toUpperCase() + variable['label'].substring(1, variable['label'].length) }}
                </option>
              </select>
            </div>
            <div class="col-12 m-0 p-0" style="text-align: left;">
              <label class="col-12 m-0 p-0 pb-1 tx-white tx-12">
                {{ dictionary['Fecha inicio'][session.user.language] }}:
              </label>
              <input v-model="startDateTimeInput" id="inputStartDate"
                :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                class="dateInput form-control form-control-line col-12 m-0 p-1">
            </div>

            <div class="col-12 m-0 p-0" style="text-align: left;">
              <label class="col-12 m-0 p-0 pb-1 tx-white tx-12">
                {{ dictionary['Fecha fin'][session.user.language] }}:
              </label>
              <input v-model="endDateTimeInput" id="inputEndDate"
                :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                class="dateInput form-control form-control-line col-12 m-0 p-1">
            </div>

            <div class="col-12 m-0 mt-1 mb-1 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between"
              style="text-align: left; vertical-align: center;">
              <label class="m-0 p-0 tx-white tx-12">
                {{ dictionary['Eje izquierdo'][session.user.language] }}:
              </label>
              <input v-model="checkboxTargetAxisLeft" @change="checkboxTargetAxisRight = !checkboxTargetAxisLeft"
                id="checkboxTargetAxisLeft" type="checkbox" style="width: 1.25rem"
                class="m-0 p-0 h-100 tx-white d-inline">
            </div>

            <div class="col-12 m-0 mt-1 mb-1 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between"
              style="text-align: left; vertical-align: center;">
              <label class="m-0 p-0 tx-white tx-12">
                {{ dictionary['Eje derecho'][session.user.language] }}:
              </label>
              <input v-model="checkboxTargetAxisRight" @change="checkboxTargetAxisLeft = !checkboxTargetAxisRight"
                id="checkboxTargetAxisRight" type="checkbox" style="width: 1.25rem"
                class="m-0 p-0 h-100 tx-white d-inline">
            </div>

            <div class="col-12 m-0 mt-1 mb-1 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between"
              style="text-align: left; vertical-align: center;">
              <label class="m-0 p-0 tx-white tx-12">
                {{ dictionary['Escalonado'][session.user.language] }}:
              </label>
              <input v-model="checkboxStep" @click="checkboxStep = !checkboxStep" id="checkboxStep" type="checkbox"
                style="width: 1.25rem" class="m-0 p-0 h-100 tx-white d-inline">
            </div>

            <hr class="m-0 p-0">

            <div class="col-12 m-0 p-0">
              <button type="button"
                @click="this.moduleAvailable === 2 ? getDeviceVariableHistory(1) : getMachineVariableHistory(1)"
                class="col-12 button-ok gradient-tablas mt-3 rounded-3 w-100 tx-12" id="btnGuardarPerfil">
                {{ dictionary['Aplicar en gráfica'][session.user.language] }} 1
              </button>
            </div>

            <div class="col-12 m-0 p-0">
              <button type="button"
                @click="this.moduleAvailable === 2 ? getDeviceVariableHistory(2) : getMachineVariableHistory(2)"
                class="col-12 button-ok gradient-tablas mt-3 rounded-3 w-100 tx-12" id="btnGuardarPerfil">
                {{ dictionary['Aplicar en gráfica'][session.user.language] }} 2
              </button>
            </div>

          </div>
        </div>
      </div>

      <div class="row col-lg-10 col-md-9 col-12 m-0 p-0 rounded-end-5 justify-content-center">
        <!-- CARD Chart 1 -->

        <div id="cardChart1" class="p-2" :class="(chartSize['1'].max) ? 'col-lg-6 col-md-6 col-12' : 'col-12'">
          <div class="p-0 rounded-5 sombra">
            <div class="gradient-tablas rounded-top-5 border-1 p-1 mb-0 tx-clomos-white">
              {{ dictionary['Gráfica'][session.user.language] }} 1
              <ion-icon name="refresh-outline" class="tx-18 pe-0 btn btnSizeChart" @click="chartHistory['1'] = {}">
              </ion-icon>
              <ion-icon v-if="chartSize['1'].max" name="expand-outline" class="tx-18 pe-0 btn btnSizeChart"
                @click="chartSize['1'].max = !chartSize['1'].max">
              </ion-icon>
              <ion-icon v-if="!chartSize['1'].max" name="contract-outline" class="tx-18 pe-0 btn btnSizeChart"
                @click="chartSize['1'].max = !chartSize['1'].max">
              </ion-icon>
            </div>

            <div class="bg-white rounded-bottom-5 pt-3 pb-2 m-0">
              <VueEcharts :option="chart1Options" id="Chart1Canvas" class="col-12 w-100 ps-0 pe-0"
                style="height: 30.25rem;">
              </VueEcharts>
            </div>
          </div>
        </div>

        <!-- CARD Chart 2 -->
        <div id="cardChart2" class="p-2" :class="(chartSize['2'].max) ? 'col-lg-6 col-md-6 col-12' : 'col-12'">
          <div class="p-0 rounded-5 sombra">
            <div class="gradient-tablas rounded-top-5 border-1 p-1 mb-0 tx-clomos-white">
              {{ dictionary['Gráfica'][session.user.language] }} 2
              <ion-icon name="refresh-outline" class="tx-18 pe-0 btn btnSizeChart" @click="chartHistory['2'] = {}">
              </ion-icon>
              <ion-icon v-if="chartSize['2'].max" name="expand-outline" class="tx-18 pe-0 btn btnSizeChart"
                @click="chartSize['2'].max = !chartSize['2'].max">
              </ion-icon>
              <ion-icon v-if="!chartSize['2'].max" name="contract-outline" class="tx-18 pe-0 btn btnSizeChart"
                @click="chartSize['2'].max = !chartSize['2'].max">
              </ion-icon>
            </div>

            <div class="bg-white rounded-bottom-5 pt-3 pb-2 m-0">
              <VueEcharts :option="chart2Options" id="Chart2Canvas" class="col-12 w-100 ps-0 pe-0"
                style="height: 30.25rem;">
              </VueEcharts>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import { VueEcharts } from 'vue3-echarts';
import echartsOptionsJson from '@/assets/json/echarts_options.json';
// Clomos
import api from '@/services/api';

export default {
  name: "ChartsComponent",
  components: { VueEcharts },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      this.moduleAvailable = this.getModulesFromUrl()
      if (this.moduleAvailable === 2) {
        await this.getDeviceListByCompany();
        if (this.availableDevices[0] != undefined) {
          this.targetDeviceId = this.availableDevices[0].id;
          await this.getDeviceData();
        }
        else {
          this.targetDeviceId = ""
        }
      } else if (this.moduleAvailable === 1) {
        this.machineList=[];
        await this.getMachineListByCompany()
        if (this.machineList[0] != undefined) {
          this.targetDeviceId = this.machineList[0].id;
          await this.getMachineData();
        }
        else {
          this.targetDeviceId = ""
        }
      }

      if (Object.entries(this.historyVariables)[0] != undefined) {
        this.targetVariableId = Object.entries(this.historyVariables)[0][0];
      }
      else {
        this.targetVariableId = ""
      }
    },
  },
  data() {
    return {
      // LAST DAY CHART
      lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
      deviceData: {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {}
        }
      },
      machineData: {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {}
        }
      },
      availableDevices: [],
      machineList: [],
      targetDeviceId: "",
      targetVariableId: "",
      checkboxTargetAxisLeft: true,
      checkboxTargetAxisRight: false,
      checkboxStep: false,
      startDateTimeInput: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
      endDateTimeInput: api.parseDateTimeToString(new Date()),
      startDateTime: {
        "1": "",
        "2": "",
      },
      endDateTime: {
        "1": "",
        "2": "",
      },
      chartHistory: {
        "1": {},
        "2": {}
      },
      chartSize: {
        "1": { max: true },
        "2": { max: true }
      },
      // COLORS
      colors: ["#9e9e9e",
        "#4e79a7",
        "#f28d2b",
        "#e05759",
        "#76b7b2",
        "#59a14f",
        "#af7aa1",
        "#b1de89",
        "#595055",
        "#edc948",
        "#1c8356",
        "#ff9da7",
        "#8d9fca",
        "#2f451e"]
    }
  },
  computed: {
    fractionalLength() {
      return (localStorage.getItem("ClomosFractionalLength") != undefined) ? localStorage.getItem("ClomosFractionalLength") : 2;
    },
    historyVariables() {
      let historyVariables = {};
      if (this.moduleAvailable === 2) {
        for (let variableType in this.deviceData.variables)
          for (let variable in this.deviceData.variables[variableType])
            if (this.deviceData.variables[variableType][variable].saveHistory === 'true')
              historyVariables[this.deviceData.variables[variableType][variable].variableId] = this.deviceData.variables[variableType][variable];

      } else if (this.moduleAvailable === 1) {
        for (let variableType in this.machineData.variables)
          for (let variable in this.machineData.variables[variableType])
            if (this.machineData.variables[variableType][variable].saveHistory === 'true')
              historyVariables[this.machineData.variables[variableType][variable].variableId] = this.machineData.variables[variableType][variable];

      }
      return historyVariables;
    },
    chart1Options() {
      let chart1Options;
      chart1Options = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId == "") return chart1Options;
      chart1Options.axisPointer = {
        label: {
          formatter: ((dataTarget) => {
            let d = new Date(dataTarget.value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          })
        }
      }
      chart1Options.xAxis = {
        min: new Date(this.startDateTime["1"]).getTime(),
        max: new Date(this.endDateTime["1"]).getTime(),
        axisLabel: {
          formatter: ((value) => {
            let d = new Date(value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          }),
          textStyle: {
            fontSize: 9
          },
          axisLineShow: true
        }
      }
      let i = 0;
      for (let variable in this.chartHistory["1"]) {
        chart1Options.yAxis[i] = {
          type: "value",
          axisLine: {
            lineStyle: {
              color: this.colors[i]
            }
          },
          name: variable.substring(0, 3).toUpperCase(),
          nameTextStyle: {
            fontSize: 10
          },
          axisLabel: {
            fontSize: 9
          },
          offset: Math.floor(i / 2) * 25,
          position: (this.chartHistory["1"][variable][i] != undefined)
            ? this.chartHistory["1"][variable][i][2]
            : (i % 2 === 0)
              ? "left"
              : "right"
        }
        chart1Options.legend.data[i] = variable;
        chart1Options.series[i] = {
          data: this.chartHistory["1"][variable],
          name: variable,
          yAxisIndex: i,
          type: "line",
          symbol: "emptyDiamond",
          symbolSize: 6,
          color: this.colors[i],
          smooth: false,
          step: (this.chartHistory["1"][variable][i] != undefined) ? this.chartHistory["1"][variable][i][3] : false,
          areaStyle: {
            color: this.colors[i],
            opacity: 0.03
          },
        }
        i += 1;
      }
      return chart1Options;
    },
    chart2Options() {
      let chart2Options;
      chart2Options = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId == "") return chart2Options;
      chart2Options.axisPointer = {
        label: {
          formatter: ((dataTarget) => {
            let d = new Date(dataTarget.value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          })
        },
      }
      chart2Options.xAxis = {
        min: new Date(this.startDateTime["2"]).getTime(),
        max: new Date(this.endDateTime["2"]).getTime(),
        axisLabel: {
          formatter: ((value) => {
            let d = new Date(value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          }),
          textStyle: {
            fontSize: 9
          }
        },
      }
      let i = 0;
      for (let variable in this.chartHistory["2"]) {
        chart2Options.yAxis[i] = {
          type: "value",
          axisLabel: {
            fontSize: 9
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: this.colors[i]
            }
          },
          name: variable.substring(0, 3).toUpperCase(),
          nameTextStyle: {
            fontSize: 10
          },
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          offset: Math.floor(i / 2) * 25,
          position: (this.chartHistory["2"][variable][i] != undefined)
            ? this.chartHistory["2"][variable][i][2]
            : (i % 2 === 0)
              ? "left"
              : "right"
        }
        chart2Options.legend.data[i] = variable;
        chart2Options.series[i] = {
          data: this.chartHistory["2"][variable],
          name: variable,
          yAxisIndex: i,
          type: "line",
          symbol: "emptyDiamond",
          symbolSize: 6,
          color: this.colors[i],
          smooth: false,
          step: (this.chartHistory["2"][variable][i] != undefined) ? this.chartHistory["2"][variable][i][3] : false,
          areaStyle: {
            color: this.colors[i],
            opacity: 0.03
          },
        }
        i += 1;
      }
      return chart2Options;
    }
  },
  emits: ["setCurrentCompany"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    // GET
    async getDeviceData(callback) {
      let response = await api.getDeviceData(this.targetDeviceId);
      this.deviceData = api.parseDeviceFromResponse(response);
      if (Object.entries(this.historyVariables)[0] != undefined) {
        this.targetVariableId = Object.entries(this.historyVariables)[0][0];
      }
      else {
        this.targetVariableId = ""
      }
      if (callback && typeof callback === "function") callback(this.deviceData);
    },
    async getDeviceListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.availableDevices = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.availableDevices);
    },
    async getDeviceVariableHistory(n, callback) {
      let targetAxis = (this.checkboxTargetAxisRight) ? 'right' : 'left';
      let step = (this.checkboxStep) ? true : false;
      if (this.startDateTimeInput === "" || this.endDateTimeInput === "") {
        if (this.startDateTimeInput === "")
          $("#inputStartDate").get()[0].classList.add("missing")
        if (this.endDateTimeInput === "")
          $("#inputEndDate").get()[0].classList.add("missing")
        setTimeout(() => {
          $("#inputStartDate").get()[0].classList.remove("missing")
          $("#inputEndDate").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      if (this.deviceData != {}) {
        let request = {
          id: this.deviceData.id,
          variableLabel: this.historyVariables[this.targetVariableId].label,
          variableType: this.historyVariables[this.targetVariableId].type,
          limit: 0,
          offset: 0,
          startDateTime: api.parseDateUserTimezoneForCharts(this.startDateTimeInput, this.session.user, this.$dayjs),
          endDateTime: api.parseDateUserTimezoneForCharts(this.endDateTimeInput, this.session.user, this.$dayjs)
        }
        let response = await api.getDeviceVariableHistory(request);
        // PARSE HISTORY RESPONSE
        let rawHistory = api.parseVariableHistoryFromResponse(response);
        this.chartHistory[n][this.historyVariables[this.targetVariableId].label + "-" + this.targetDeviceId] = [];
        for (let i in rawHistory) {
          this.chartHistory[n][this.historyVariables[this.targetVariableId].label + "-" + this.targetDeviceId][i] = [
            // DATE
            this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
            // VALUE
            new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
            // POSITION
            targetAxis,
            // STEP
            step,
          ]
        }
        // SET DATA BOUNDRIES
        this.startDateTime[n] = this.startDateTimeInput;
        this.endDateTime[n] = this.endDateTimeInput;
        // SWITCH AXIS TARGET
        this.checkboxTargetAxisRight = !this.checkboxTargetAxisRight;
        this.checkboxTargetAxisLeft = !this.checkboxTargetAxisRight;
        if (callback && typeof callback === "function") callback(this.availableDevices)
      }
    },
    async getMachineListByCompany() {
      this.machineList= [];
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      for (let i in response.maquinas) [
        this.machineList[i] = {
          id: response.maquinas[i].idDcl,
          label: response.maquinas[i].etiqueta,
        }
      ]
    },
    async getMachineData(callback) {
      let response = await api.getMachineData(this.targetDeviceId);
      this.machineData = api.parseMachineFromResponse(response);
      if (Object.entries(this.historyVariables)[0] != undefined) {
        this.targetVariableId = Object.entries(this.historyVariables)[0][0];
      }
      else {
        this.targetVariableId = ""
      }
      if (callback && typeof callback === "function") callback(this.targetMachineData);
    },
    async getMachineVariableHistory(n, callback) {
      let targetAxis = (this.checkboxTargetAxisRight) ? 'right' : 'left';
      let step = (this.checkboxStep) ? true : false;
      if (this.startDateTimeInput === "" || this.endDateTimeInput === "") {
        if (this.startDateTimeInput === "")
          $("#inputStartDate").get()[0].classList.add("missing")
        if (this.endDateTimeInput === "")
          $("#inputEndDate").get()[0].classList.add("missing")
        setTimeout(() => {
          $("#inputStartDate").get()[0].classList.remove("missing")
          $("#inputEndDate").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      if (this.machineData != {}) {
        let request = {
          id: this.machineData.id,
          variableLabel: this.historyVariables[this.targetVariableId].label,
          variableType: this.historyVariables[this.targetVariableId].type,
          limit: 0,
          offset: 0,
          startDateTime: api.parseDateTimeToUTCString(this.startDateTimeInput, this.session.user, this.$dayjs),
          endDateTime: api.parseDateTimeToUTCString(this.endDateTimeInput, this.session.user, this.$dayjs)
        }
        let response = await api.getMachineVariableHistory(request);
        // PARSE HISTORY RESPONSE
        let rawHistory = api.parseVariableHistoryFromResponse(response);
        this.chartHistory[n][this.historyVariables[this.targetVariableId].label + "-" + this.targetDeviceId] = [];
        for (let i in rawHistory) {
          this.chartHistory[n][this.historyVariables[this.targetVariableId].label + "-" + this.targetDeviceId][i] = [
            // DATE
            this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
            // VALUE
            new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
            // POSITION
            targetAxis,
            // STEP
            step,
          ]
        }
        // SET DATA BOUNDRIES
        this.startDateTime[n] = this.startDateTimeInput;
        this.endDateTime[n] = this.endDateTimeInput;
        // SWITCH AXIS TARGET
        this.checkboxTargetAxisRight = !this.checkboxTargetAxisRight;
        this.checkboxTargetAxisLeft = !this.checkboxTargetAxisRight;
        if (callback && typeof callback === "function") callback(this.machineList)
      }
    },
    getModulesFromUrl() {
      try {
        const url = new URL(window.location.href);

        switch (true) {
          case url.href.includes("smartLockers"):
            return 1;
          case url.href.includes("devices"):
            return 2;
          /* Si vas a usar `pathParts` como en el comentario original,
             puedes hacerlo así: */
          /*
          case url.pathname.includes("fabric"):
            return 2;
          case url.pathname.includes("storage"):
            return 7;
          */
          default:
            return null;
        }
      } catch (error) {
        console.error("Error parsing URL:", error);
        return null;
      }

    },
  },
  async created() {
    this.moduleAvailable = this.getModulesFromUrl()
    if (this.moduleAvailable === 2) {
      await this.getDeviceListByCompany();
      if (this.availableDevices[0] != undefined) {
        this.targetDeviceId = this.availableDevices[0].id;
        await this.getDeviceData();
      }
      else {
        this.targetDeviceId = ""
      }
    } else if (this.moduleAvailable === 1) {
      await this.getMachineListByCompany()
      if (this.machineList[0] != undefined) {
        this.targetDeviceId = this.machineList[0].id;
        await this.getMachineData();
      }
      else {
        this.targetDeviceId = ""
      }
    }
    if (Object.entries(this.historyVariables)[0] != undefined) {
      this.targetVariableId = Object.entries(this.historyVariables)[0][0];
    }
    else {
      this.targetVariableId = ""
    }
  }
}
</script>

<style>
#cardRelaysChartsContainer {
  display: flex;
  gap: 1rem;
}

#cardDigitalInputsContainer {
  display: flex;
  gap: 1rem;
}

.btnSizeChart:hover {
  color: var(--main-dark) !important;
}

.dateInput {
  color-scheme: dark;
}

.dateInput:active {
  color-scheme: light;
}

@media (max-width: 992px) {

  #cardOverall,
  #cardCurrentCharts,
  #cardSCC {
    padding-right: 1rem !important;
  }

  #cardRelaysChartsContainer,
  #cardDigitalInputsContainer {
    display: block;
  }
}
</style>