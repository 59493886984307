<template>
<!-- SECTION Lockers-->
  <div id="lockersSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

<!-- Selector de Sistema -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-menu border-1 rounded-5 box sombra">
          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="file-tray-stacked" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Lockers"][session.user.language]}}
              <span v-if="targetEmplacementData.label != undefined"> : <br> {{targetEmplacementData.label}} </span>
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr v-if="this.$route.params.currentDisplay==='lockers'" class="m-0 p-0">
              
              <select v-if="this.$route.params.currentDisplay==='lockers'"
              :value="currentCompany.nifCif"
              class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>
              
              <hr class="m-0 p-0 mb-3">

              <div class="row justify-content-around m-0 p-0">
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('blueprint')"
                  :style="(displayType==='blueprint')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="locate-outline" 
                    :style="(displayType==='blueprint')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('table')"
                  :style="(displayType==='table')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="list-outline" 
                    :style="(displayType==='table')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('cards')"
                  :style="(displayType==='cards')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="documents-outline" 
                    :style="(displayType==='cards')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
              </div>

              <hr class="m-0 p-0">

              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewLockerModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                @click="toggleNewLockerModal">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir locker"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>
            
          </div>
        </div>

      </div>
  
      <!-- COMPONENT Lockers -->
            <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
              <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">         
                <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1 d-flex justify-content-between align-items-center">
        
          <span v-if="displayType === 'blueprint'"
                @click="prevLocker"
                :disabled="currentIndex === 0"
                class="btn btn-link text-white">
            Anterior
          </span>
          <div class="d-flex flex-grow-1 justify-content-center">
            <ion-icon name="file-tray-stacked-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            <span>{{ dictionary["Lockers"][session.user.language] }}</span>
            <span v-if="this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId === ''">
              {{ ": "+currentCompany.name }}
            </span>
            <span v-else-if="this.targetEmplacementData.label !== undefined">
              {{ ": "+targetEmplacementData.label }}
            </span>
          </div>
          <span v-if="displayType === 'blueprint'"
                @click="nextLocker"
                :disabled="currentIndex === lockerList.length - 1"
                class="btn btn-link text-white">
            Siguiente
          </span>
        </div>
        <!-- BLUEPRINT Lockers -->
        <div v-show="displayType === 'blueprint'"
              id="lockersBlueprintContent"
              class="col-12 m-0 p-2 h-auto"
              :style="(!display.showEverything) ? 'height: 15rem' : ''">
            <div class="blueprintCanvas col-9 m-0 p-0">
              <img id="emplacementBlueprintForLockers"
                  class="w-100 m-0 p-2"
                  :src="displayLockersBlueprint()">
              
              <!-- Mostrar solo el locker actual -->
              <div v-if="lockerList.length > 0" :key="currentLocker.id"
                  class="bg-caja rounded-3 p-2 lockerBlueprintMarker"
                  :style="bluePrintMarkerPosition(currentLocker)">
                <div class="d-flex flex-column" style="min-width: 9rem;">
                  <span :data-id="currentLocker.id"
                        class="btnTargetLocker c-pointer tx-bold tx-grey m-0 p-0">
                    {{ currentLocker.label }}
                  </span>
                  <div class="m-0 p-0 d-flex flex-row justify-content-between">
                    <text class="w-100"> {{ currentLocker.location }} </text>
                    <ion-icon name="create-outline"
                              :data-id="currentLocker.id"
                              class="btnEditLocker col-2 m-0 ps-1 p-0 btn tx-24 tx-primary float-end"></ion-icon>
                    <ion-icon name="trash-outline"
                              :data-id="currentLocker.id"
                              class="btnDeleteLocker col-2 m-0 p-0 btn tx-24 tx-danger float-end"></ion-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

<!-- TABLE Lockers -->
          <div v-show="displayType==='table'" class="m-0 p-3 h-auto"
          id="lockersTableContent"
          :style="(!display.showEverything)?'height: 15rem':''">
            <table id="lockersDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in lockersColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap p-2">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>

<!-- CARDS Lockers -->
          <div v-show="displayType==='cards'&&this.lockerList[0]!=undefined"
          id="lockersCardsContent" class="col-12 m-0 p-2 h-auto"
          :style="(!display.showEverything)?'height: 15rem':''">
            <div class="col-12 m-0 p-0 d-flex flex-row flex-wrap">
              <div v-for="locker in lockerCardsByPages[lockerCardsPage]" :key="locker"
              class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6 class="tx-left tx-clomos-white text-center text-nowrap m-0 p-2 ps-1 pt-2 gradient-tablas rounded-top-3">
                  <span class="m-auto">{{(locker.label.length>18)?(locker.label.substring(0,18)+'...'):locker.label}}</span>
                </h6>

                <div class="col-12 border-1 rounded-bottom-3 m-0 p-3">
                  <div class="col-12 m-0 p-0 d-flex space-between">
                    <ion-icon name="pulse-outline" :data-id="locker.id" class="btnTargetLocker col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="create-outline" :data-id="locker.id" class="btnEditLocker col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="locker.id" class="btnDeleteLocker col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                  </div>
                  <hr class="col-12 mt-2 mb-2">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 5rem;">
                    <span class="tx-13 tx-bold">{{dictionary['Tipo'][session.user.language]+": "}}</span>
                    <span class="tx-11 tx-start">{{locker.type}}</span>
                    <span class="tx-13 tx-bold">{{dictionary['Identificador'][session.user.language]+": "}}</span>
                    <span class="tx-11 tx-start">{{locker.id}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 p-2">
                <div v-for="page,index in lockerCardsByPages" :key="page" 
                class="border rounded c-pointer m-0 me-2 tx-13" :class="(lockerCardsPage===index)?'border-dark':''" style="height:2.5rem; width:2.5rem; padding: inherit;"
                @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index+1  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-show="displayType==='cards'&&this.lockerList[0]==undefined" class="m-3 p-3 border rounded">
            <span class="tx-13"> 
              {{dictionary['No hay lockers disponibles'][session.user.language]}} 
            </span> 
          </div>

        </div>
      </div>

    </div>
  </div>

<!-- SECTION Lockers -->
  <!-- <StatusComponent v-if="this.$route.params.currentDisplay==='lockers'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :requestedLockerId="targetLockerId"
  :dataTableOptions="dataTableOptions">
  </StatusComponent> -->

<!-- MODAL New Locker -->
  <NewLockerModal
  v-if="display.NewLockerModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  v-on:toggleNewLockerModal="toggleNewLockerModal">
  </NewLockerModal> 

<!-- MODAL Edit Locker -->
  <EditLockerModal
  v-if="display.editLockerModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :lockerId="targetLockerId"
  v-on:toggleEditLockerModal="toggleEditLockerModal">
  </EditLockerModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteLockerModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Locker a eliminar'][session.user.language] + ': '"
  :alertTarget="targetLockerId"
  v-on:acceptAlert="onDeleteLocker"
  v-on:dismissAlert="toggleDeleteLockerModal">
  </TextAlertModal>

  <!-- SECTION Slots -->
  <SlotsComponent v-if="this.$route.params.currentDisplay==='lockers'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :targetLockerId="targetLockerId"
  :dataTableOptions="dataTableOptions">
  </SlotsComponent>

</template>

<script>
import $ from 'jquery';
import SlotsComponent from '@/components/lockers/SlotsComponent';
import NewLockerModal from '@/components/lockers/NewLockerModal';
import EditLockerModal from '@/components/lockers/EditLockerModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "LockersComponent",
  components: { SlotsComponent, NewLockerModal,  EditLockerModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
  },
  watch: { 
    currentCompany: async function() {
      if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
        await this.getLockersDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getLockersDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.lockerList[0] != undefined && this.targetLockerId === "")
        this.targetLockerId = this.lockerList[0].id
    },
    targetEmplacementId: async function() {
      if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
        await this.getLockersDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getLockersDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.lockerList[0] != undefined && this.targetLockerId === "")
        this.targetLockerId = this.lockerList[0].id;
    }
  },
  data() {
    return {
      lockersColumns: [
        {text:"Identificador", data: "lockerId", width: "25%", className:"tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
            +"<span data-id="+data+" class='btnTargetLocker c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Nombre", data: "lockerLabel", width: "15%", className:"tx-left align-middle"},
        {text:"Tipo", data: "lockerType", width: "10%", className:"tx-left align-middle"},
        {text:"Emplazamiento", data: "lockerEmplacement", width: "10%", className:"tx-left align-middle",
          render: (data) => {
            let emplacementLabel = data;
            if (this.emplacementList.length > 0) {
              for (let emplacement in this.emplacementList)
                if (this.emplacementList[emplacement].id === data) 
                  emplacementLabel = this.emplacementList[emplacement].label;
            }
            return emplacementLabel;
          }
        },
        {text:"Fecha de comunicación", data: "lockerCommunicationDateTime", width: "10%", className:"align-middle",
          render: (data) => {
            return (data!="")
            ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
            :"<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {text:"Configuración", data: "lockerId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id="+data+" class='btnEditLocker btn tx-24 tx-primary'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "lockerId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteLocker btn tx-24 tx-danger'></ion-icon>"
          }
        },
      ],
      targetEmplacementData: {},
      targetLockerId: this.$route.params.targetEntity,
      lockerList: [],
      emplacementList: [],
      displayType : (this.$route.params.currentView==='emplacements')?'blueprint':'table',
      lockerCardsPage: 0,
      display: {
        NewLockerModal: false,
        editLockerModal: false,
        linkLockerModal: false,
        deleteLockerModal: false,
      },
      linkAction: "link",
      currentIndex: 0,
    }
  },
  computed: {
    lockerCardsByPages(){
      let lockerCardsByPages = []
      let i = 0; 
      while (i*12 < this.lockerList.length) {
        lockerCardsByPages.push(this.lockerList.slice(i*12,i*12+12));
        i++;
      }
      return lockerCardsByPages;
    },
    currentLocker() {      
      return this.lockerList[this.currentIndex] || {}; 
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    nextLocker() {
      if (this.currentIndex < this.lockerList.length - 1) {
        this.currentIndex += 1;
      }
    },
    prevLocker() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
      }
    },
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(() => {
        if (type === "blueprint") this.displayLockersBlueprint();
        if (type === "table") this.displayLockersDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.lockerCardsPage = index;
      this.setButtonFunctions();
    },
    displayLockersBlueprint() {
      setTimeout(()=>{
        $(".lockerBlueprintMarker").get().forEach(marker => {
          let markerId = marker.getAttribute("data-id");
          for (let i in this.lockerList) {
            if (this.lockerList[i].id === markerId) 
              marker.style = this.bluePrintMarkerPosition(this.lockerList[i]);
          }
        })
      },1000);
      let src = "";
      if (this.targetEmplacementData.variables != undefined
      && this.targetEmplacementData.variables.dynamic != undefined
      && this.targetEmplacementData.variables.dynamic.urlPlano != undefined)
        src = this.targetEmplacementData.variables.dynamic.urlPlano.value;
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    bluePrintMarkerPosition(locker) {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForLockers');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;
        
        let domX = locker.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7 ;
        let domY = locker.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;
  
        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "position: absolute; top: " + markerY +"px; left: " + markerX + "px;"
    },
    displayLockersDataTable() {
      $("#lockersDataTable").DataTable().clear()
      for (let i in this.lockerList) {
        $("#lockersDataTable").DataTable().rows.add([{
          lockerId: this.lockerList[i].id, 
          lockerEmplacement: this.lockerList[i].parent, 
          lockerLabel: this.lockerList[i].label, 
          lockerLocation: this.lockerList[i].parent,
          lockerType: this.lockerList[i].type, 
          lockerStatus: this.lockerList[i].status, 
          lockerCommunicationDateTime: (this.lockerList[i].communicationDateTime != undefined && this.lockerList[i].communicationDateTime != "") ? new Date(this.lockerList[i].communicationDateTime) : "", 
          lockerVersion: this.lockerList[i].version, 
        }]);
      }
      $("#lockersDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($("#lockersDataTable_paginate").get()[0] != undefined) $("#lockersDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleNewLockerModal() {
      if (this.display.NewLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displayLockersDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displayLockersDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.NewLockerModal = !this.display.NewLockerModal;
    },
    async toggleEditLockerModal() {
      if (this.display.editLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displayLockersDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displayLockersDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.editLockerModal = !this.display.editLockerModal;
    },
    async toggleLinkLockerModal() {
      if (this.display.linkLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displayLockersDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displayLockersDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.linkLockerModal = !this.display.linkLockerModal;
    },
    async toggleDeleteLockerModal() {
      if (this.display.deleteLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displayLockersDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displayLockersDataTable();
        }
        if (this.lockerList[0] != undefined) this.targetLockerId = this.lockerList[0].id;
        this.onChangeDisplayType(this.displayType);
      }
      this.display.deleteLockerModal = !this.display.deleteLockerModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetLocker);
        });
        $(".btnEditLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onEditLocker);
        });
        $(".btnLinkLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkLocker);
        });
        $(".btnUnlinkLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkLocker);
        });
        $(".btnDeleteLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteLocker);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getLockersDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getLockerListByCompany(request);
      this.lockerList = api.parseLockersFromResponse(response);
      if (callback && typeof callback === "function") callback(this.lockerList);
    },
    async getLockersDataByEmplacement(callback) {
      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByEmplacement(request);
      this.lockerList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.lockerList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    // TARGET 
    onTargetLocker(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetLockerId}})
    },
    // SET 
    onEditLocker(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetLockerId}})
      this.toggleEditLockerModal();
    },
    // LINK 
    onLinkLocker(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetLockerId}})
      this.linkAction = "link"
      this.toggleLinkLockerModal();
    },
    // UNLINK 
    onUnlinkLocker(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetLockerId}})
      this.linkAction = "unlink"
      this.toggleLinkLockerModal();
    },
    // DELETE
    async onDeleteLocker(event) {
      if (!this.display.deleteLockerModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetLockerId);
        this.targetLockerId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetLockerId}})
      }
      else {
        let response = await api.deleteLocker(this.targetLockerId);
        if (response["status-code"] === "200") {
          window.alert("El locker ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el locker.");
        }
      }
      await this.toggleDeleteLockerModal();
    }
  },
  async created() {
    await this.getEmplacementList();
    if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
      await this.getLockersDataByCompany();
      this.$emit('getAvailableCompanies',(this.displayLockersDataTable));
    }
    else if (this.targetEmplacementId != ''){
      await this.getEmplacementData();
      await this.getLockersDataByEmplacement();
      this.$emit('getAvailableCompanies',this.onChangeDisplayType(this.displayType));
    }
    if (this.lockerList[0] != undefined && this.targetLockerId === "")
      this.targetLockerId = this.lockerList[0].id;
  },
  mounted() {
    $("#lockersDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.lockersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btnCopyId:hover, .btnTargetLocker:hover, .btnLinkLocker:hover, .btnUnlinkLocker:hover, .btnEditLocker:hover, .btnDeleteLocker:hover {
  color: var(--main-dark) !important;
}
</style>