<template>
<!-- SECTION Orders-->
  <div id="ordersSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      
<!-- HEADER Expeditions -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-menu border-1 rounded-5 box sombra">
          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="basket" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Pedidos"][session.user.language]}}
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr class="m-0 p-0">

              <select
              :value="currentCompany.nifCif"
              class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>
              
              <hr class="m-0 p-0">

              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewExpeditionModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                @click="toggleNewExpeditionModal">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir expedición"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      
      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
      <!--<ion-icon v-if="session.user.bookmark==='users'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
            <ion-icon name="basket-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{dictionary["Pedidos"][session.user.language]}}
          </div>
      
<!-- TABLE Orders -->
          <div class="m-0 p-3 overflow-auto">
            <table id="ordersDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in ordersColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap">
                  {{dictionary[column.text][session.user.language]}}          
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
<!-- MODAL Order Details -->
  <OrderDetailsModal
  v-if="display.orderDetailsModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :orderId="targetOrderId"
  v-on:toggleOrderDetailsModal="toggleOrderDetailsModal">
  </OrderDetailsModal> 

<!-- MODAL New Expedition -->
  <NewExpeditionModal
  v-if="display.newExpeditionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :hasReceipt="false"
  v-on:toggleNewExpeditionModal="toggleNewExpeditionModal">
  </NewExpeditionModal> 
</template>

<script>
import $ from 'jquery';
import OrderDetailsModal from '@/components/orders/OrderDetailsModal.vue';
import NewExpeditionModal from '@/components/expeditions/NewExpeditionModal.vue';
// Clomos
import api from '@/services/api';

export default {
  name: "OrdersComponent",
  components: {OrderDetailsModal, NewExpeditionModal},
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getOrderList();
      this.displayOrdersDataTable();
    }
  },
  data() {
    return {
      ordersColumns: [
        {text:"Producto", data: "orderId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailOrder btn tx-24 tx-primary  m-0 p-0'></ion-icon>";
          }
        },
        {text:"Pedido" , data: "orderIdPedido", width: "40%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
            +"<span data-id="+data+" class='btnTargetProduct c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Fecha de creación", data: "orderCreationDateTime", width: "25%", className:"align-middle",
          render: (data) => {   
            return (data!="")
            ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
            :"<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {text:"Tipo de transporte", data: "orderTipoTransporte", width: "25%", className:"tx-left align-middle",
          render: (data) => {
            switch (data) {
              case 1:
                return "Ultima milla";
              case 2:
                return "Media milla";
              default:
                return "-";
            }
          }
        },
        {text:"Detalles de pedido", data: "orderId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='chevron-forward-circle-outline' data-id="+data+" class='btnOpenDetailModal btn tx-24 tx-primary  m-0 p-0'></ion-icon>";
          }
        }
      ],
      targetOrderId: "",
      orderList: [],
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0,
      },
      bulgeList: [],
      display: {
        orderDetailsModal: false, 
      }
    }
  },
  computed: {
    targetOrder() {
      for (let i in this.orderList) {
        if (this.orderList[i].id === this.targetOrderId)
          return this.orderList[i];
        }
      return {};
    },
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayOrdersDataTable() {
      $("#ordersDataTable").DataTable().clear()
      for (let i in this.orderList) {
        if (this.orderList[i].summary != undefined && this.orderList[i].summary != "") 
        {
          let resumenParseado = JSON.parse(this.orderList[i].summary) 
          $("#ordersDataTable").DataTable().rows.add([{
            orderId: this.orderList[i].id, 
            orderIdPedido: resumenParseado.idPedido, 
            orderCreationDateTime: (this.orderList[i].creationDateTime != undefined && this.orderList[i].creationDateTime != "") ? new Date(this.orderList[i].creationDateTime) : "", 
            orderTipoTransporte: (resumenParseado.tipoTrasporte !== undefined) ? resumenParseado.tipoTrasporte : "-", 
          }]);
        }
      }
      $("#ordersDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleOrderDetailsModal() {
      if (this.display.orderDetailsModal) {
        await this.getOrderList();
        this.displayOrdersDataTable();
      }
      this.display.orderDetailsModal = !this.display.orderDetailsModal;
    },

    async toggleNewExpeditionModal() {
      if (this.display.newExpeditionModal) {
        await this.getOrderList();
        this.displayOrdersDataTable();
      }
      this.display.newExpeditionModal = !this.display.newExpeditionModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetOrder").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetOrder);
        });
        $(".btnDetailOrder").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailOrder)
        });
        $(".btnOpenDetailModal").get().forEach(btn => {
          btn.addEventListener('click', this.onOpenDetailModal);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getOrderList(callback) {
      let request = {
        nifCif: this.session.user.nifCif
      };
      let response = await api.getOrderList(request);
      this.orderList = api.parseOrdersFromResponse(response);
      
      if (callback && typeof callback === "function") callback(this.orderList);
    },
    // TARGET 
    onTargetOrder(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id');
    },
    // DETAILS
    onDetailOrder(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id')
      var tr = $(event.target).closest('tr');
      var row = $("#ordersDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } 
      else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' 
        content = content +
          '<table id="detailTable'+this.targetOrderId+'" cellspacing="0" style="border: solid 1px var(--main-dark-50); width:100%; margin: 0;">' +
            '<tr>' +
              '<td class="tx-bold">Id Producto</td>' +
              '<td class="tx-bold">Producto</td>' +
              '<td class="tx-bold">Cantidad</td>' +
              // '<td class="tx-bold">Talla</td>' +
              // '<td class="tx-bold">Color</td>' +
              // '<td class="tx-bold">Caducidad</td>' +
            '</tr>';
          for (let i in this.targetOrder.params.productos) {
            let product = this.targetOrder.params.productos[i];
            content = content + '<tr>';
            
              if (i !=undefined) content = content + '<td>' + i + '</td>'
                else content = content + '<td>-</td>';
              if (product.etiqueta!=undefined) content = content + '<td>' + product.etiqueta + '</td>'
                else content = content + '<td>-</td>';
              if (product.unidades!=undefined) content = content + '<td>' + product.unidades + '</td>'
                else content = content + '<td>-</td>';
              // if (product.talla!=undefined) content = content + '<td>' + product.talla + '</td>'
              //   else content = content + '<td>-</td>';
              // if (product.color!=undefined) content = content + '<td>' + product.color + '</td>'
              //   else content = content + '<td>-</td>';
              // if (product.fechaCaducidad!=undefined) content = content + '<td>' + product.fechaCaducidad + '</td>'
              //  else content = content + '<td>-</td>';
            '</tr>';
          }
          content = content + '</table>';
        row.child(content).show();
        document.getElementById('detailTable'+this.targetOrderId).parentNode.style.padding = 0;
        tr.addClass('shown');
      }
    },

    onOpenDetailModal(event){
      console.log('OPEN MODAL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetOrderId = event.target.getAttribute('data-id');
      this.toggleOrderDetailsModal();
    },
  },
  async created() {
    await this.getOrderList();
      this.displayOrdersDataTable();
  },
  mounted() {
    $("#ordersDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.ordersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>
<style>
#orderDetailsModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnCopyId:hover, .btnDetailOrder:hover {
  color: var(--main-dark) !important;
}

</style>