<template>
<!-- SECTION Stock-->
  <div id="stockSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
  
<!-- HEADER Stock -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-menu border-1 rounded-5 box sombra">
          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="layers" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Stock"][session.user.language]}}
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr class="m-0 p-0">

              <select
              :value="currentCompany.nifCif"
              class="form-control mt-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>

            </div>
            
          </div>
          
        </div>
      </div>

      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <!--<ion-icon v-if="session.user.bookmark==='products'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
            <ion-icon name="layers-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{dictionary["Stock"][session.user.language]}}
          </div>

          <div class="m-0 p-3 overflow-auto">
            <table id="stocksDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in usersColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>

<!-- MODAL Confirm Regularization -->
  <TextAlertModal
  v-if="display.regularizeStockModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Stock a regularizar'][session.user.language]+': '"
  :alertTarget="this.regularizationForm.targetStockId"
  :alertTexts="[
    String('Operación: ' + this.regularizationForm.operation + Math.abs(this.regularizationForm.targetStockUnitsChange)),
    String('Stock virtual actual: ' + regularizationForm.targetStockVirtualOldUnits),
    String('Nuevo Stock virtual: ' + String( (regularizationForm.targetStockVirtual)?(regularizationForm.operation === '+')?(Number(regularizationForm.targetStockVirtualOldUnits) + Number(regularizationForm.targetStockUnitsChange)):(Number(regularizationForm.targetStockVirtualOldUnits) - Number(regularizationForm.targetStockUnitsChange)):regularizationForm.targetStockVirtualOldUnits) ),
    String('Stock real actual: ' + regularizationForm.targetStockRealOldUnits),
    String('Nuevo Stock real: ' + String((regularizationForm.targetStockReal)?(regularizationForm.operation === '+')?(Number(regularizationForm.targetStockRealOldUnits) + Number(regularizationForm.targetStockUnitsChange)):(Number(regularizationForm.targetStockRealOldUnits) - Number(regularizationForm.targetStockUnitsChange)):regularizationForm.targetStockRealOldUnits)),
    String('Stock disponible actual: ' + regularizationForm.targetStockAvailableOldUnits),
    String('Nuevo Stock disponible: ' + String((regularizationForm.targetStockAvailable)?(regularizationForm.operation === '+')?(Number(regularizationForm.targetStockAvailableOldUnits) + Number(regularizationForm.targetStockUnitsChange)):(Number(regularizationForm.targetStockAvailableOldUnits) - Number(regularizationForm.targetStockUnitsChange)):regularizationForm.targetStockAvailableOldUnits))
  ]"
  v-on:acceptAlert="setNewRegularizationData"
  v-on:dismissAlert="toggleRegularizeStockModal">
  </TextAlertModal>
  
</template>

<script>
import $ from 'jquery';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "StockComponent",
  components: { TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getProductList()
      this.displayStockDataTable();
    }
  },
  data() {
    return {
      usersColumns: [
        {text:"Detalles", data: "productId", width: "5%",  className:"tx-left align-middle",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailsStock btn tx-24 tx-primary p-auto m-auto'></ion-icon>";
          }
        },
        {text:"Identificador", data: "productId", width: "20%",  className:"dt-nowrap tx-left align-middle",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
            + "<span data-id="+data+" class='btnTargetStock c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Referencia", data: "productRef", width: "10%",  className:"tx-left align-middle"},
        {text:"Nombre", data: "productName", width: "15%",  className:"tx-left align-middle"},
        {text:"Empresa", data: "productCompany", width: "20%",  className:"tx-left align-middle",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) 
                if (this.availableCompanies[company].nifCif === data ) 
                  companyName = this.availableCompanies[company].name;
            } 
            return companyName;
          }
        },
        {text:"Conservación", data: "productConservationTemperature", width: "10%",  className:"tx-left align-middle",
          render: (data) => {
            let conservationValue = "";
            switch(data) {
              case "frio": 
                conservationValue = this.dictionary["Frío"][this.session.user.language]
                break;
              case "ambiente": 
                conservationValue = this.dictionary["Ambiente"][this.session.user.language]
                break;
              case "ultracongelado": 
                conservationValue = this.dictionary["Ultracongelado"][this.session.user.language]
                break;
              default:
                break;
            }
            return conservationValue;
          }
        },
        {text:"Stock real", data: "productAmountReal", width: "10%",  className:"align-middle"},
        {text:"Stock virtual", data: "productAmountVirtual", width: "10%",  className:"align-middle"},
        {text:"Stock disponible", data: "productAmountAvailable", width: "10%",  className:"align-middle"}
      ],
      targetProductId: this.$route.params.targetEntity,
      targetStock: {},
      regularizationForm: {
        targetStockId:"",
        targetStockParent:"",
        targetStockVirtualOldUnits:0,
        targetStockRealOldUnits:0,
        targetStockAvailableOldUnits:0,
        targetStockUnitsChange:0,
        targetStockVirtual:true,
        targetStockReal:true,
        targetStockAvailable:true,
        operation:"+"
      },
      productList: [],
      display: {
        regularizeStockModal: false,
      }
    }
  },
  computed: {
    targetProduct() {
      for (let i in this.productList) {
        if (this.productList[i].id === this.targetProductId) 
          return this.productList[i];
        }
      return {};
    },
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayStockDataTable() {
      $("#stocksDataTable").DataTable().clear()
      for (let i in this.productList) {
        $("#stocksDataTable").DataTable().rows.add([{
          productId: this.productList[i].id,
          productName: this.productList[i].label,
          productCompany: this.productList[i].nifCif,
          productRef: (this.productList[i].variables.dynamic.referencia!=undefined)?this.productList[i].variables.dynamic.referencia.value:"", 
          productAmountReal: (this.productList[i].variables.analog.stockFisico!=undefined)?this.productList[i].variables.analog.stockFisico.value:"", 
          productAmountVirtual: (this.productList[i].variables.analog.stockNeto!=undefined)?this.productList[i].variables.analog.stockNeto.value:"", 
          productAmountAvailable: (this.productList[i].variables.analog.stockDisponible!=undefined)?this.productList[i].variables.analog.stockDisponible.value:"", 
          productConservationTemperature: (this.productList[i].variables.dynamic.temperaturaConservacion!=undefined)?this.productList[i].variables.dynamic.temperaturaConservacion.value:"", 
          productPosition: this.productList[i].position
        }]);
      }
      $("#stocksDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleRegularizeStockModal() {
      if (this.display.regularizeStockModal) {
        await this.getProductList();
        this.displayStockDataTable();
      }
      this.display.regularizeStockModal = !this.display.regularizeStockModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetStock").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetStock);
        });
        $(".btnDetailsStock").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailStock);
        });
        $(".btnRegularizeStock").get().forEach(btn => {
          btn.addEventListener('click', this.onRegularizeStock);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    // GET
    async getProductList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getStockTypeList(request);
      this.productList = api.parseStockTypesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.productList);
    },
    // TARGET 
    onTargetStock(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetProductId = event.target.getAttribute('data-id');
    },
    // DETAILS
    async onDetailStock(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetProductId = event.target.getAttribute('data-id');
      var tr = $(event.target).closest('tr');
      var row = $("#stocksDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      }
      else {
        let request = {
          nifCif: this.currentCompany.nifCif,
          productId: this.targetProductId
        }
        let response = await api.getProductStockList(request);
        this.targetProductStocks = api.parseStocksFromResponse(response);
        let content = '';
        if (this.targetProductStocks[0] != undefined) {
          event.target.name = "chevron-up-circle-outline";
          content = '' +
          '<table id="detailTable'+this.targetProductId+'" cellspacing="0" style="border: solid 1px var(--main-dark-50); width:100%; margin: 0;">' +
          '<tr>' +
            '<td class="tx-bold">Hueco</td>' +
            '<td class="tx-bold">Ubicación</td>' +
            '<td class="tx-bold">Perfil logístico</td>' +
            '<td class="tx-bold">Stock Real</td>' +
            '<td class="tx-bold">Stock Virtual</td>' +
            '<td class="tx-bold">Stock Disponible</td>' +
            '<td class="tx-bold">Operación</td>' +
            '<td class="tx-bold text-nowrap">Real / Virtual / Disponible</td>' +
            '<td class="tx-bold">Regularizar</td>' +
          '</tr>';
          for (let i in this.targetProductStocks) {
            content = content + '<tr>';
              if (this.targetProductStocks[i].parent!=undefined) content = content + '<td id="displayStockPadre'+this.targetProductStocks[i].id + '">' + this.targetProductStocks[i].parent + '</td>'
                else content = content + '<td>-</td>';
              if (this.targetProductStocks[i].emplacement!=undefined) content = content + '<td>' + this.targetProductStocks[i].emplacement + '</td>'
                else content = content + '<td>-</td>';
              // Perfil logístico
              content = content + '<td>';
              if (this.targetProductStocks[i].variables.dynamic.fechaCaducidad!=undefined && this.targetProductStocks[i].variables.dynamic.fechaCaducidad.value != "")
                content = content + 'Fecha de caducidad: ' + this.targetProductStocks[i].variables.dynamic.fechaCaducidad.value;
              if (this.targetProductStocks[i].variables.dynamic.talla!=undefined && this.targetProductStocks[i].variables.dynamic.talla.value != "")
                content = content + 'Talla: ' + this.targetProductStocks[i].variables.dynamic.talla.value;
              if (this.targetProductStocks[i].variables.dynamic.color!=undefined && this.targetProductStocks[i].variables.dynamic.color.value != "")
                content = content + 'Color: ' + this.targetProductStocks[i].variables.dynamic.color.value;
              if (this.targetProductStocks[i].variables.dynamic.numeroSerie!=undefined && this.targetProductStocks[i].variables.dynamic.numeroSerie.value != "")
                content = content + 'Número de serie: ' + this.targetProductStocks[i].variables.dynamic.numeroSerie.value;
              if (this.targetProductStocks[i].variables.dynamic.lote!=undefined && this.targetProductStocks[i].variables.dynamic.lote.value != "")
                content = content + 'Lote: ' + this.targetProductStocks[i].variables.dynamic.lote.value;
              if (content.slice(-4) ==="<td>") content = content + "-"; 
              content = content + '</td>';
              if (this.targetProductStocks[i].variables.analog.stockFisico!=undefined) 
                content = content + '<td id="displayStockFisico'+this.targetProductStocks[i].id +'">' + this.targetProductStocks[i].variables.analog.stockFisico.value + '</td>'
                else content = content + '<td>-</td>';
              if (this.targetProductStocks[i].variables.analog.stockNeto!=undefined) 
                content = content + '<td id="displayStockNeto'+this.targetProductStocks[i].id +'">' + this.targetProductStocks[i].variables.analog.stockNeto.value + '</td>'
                else content = content + '<td>-</td>';
              if (this.targetProductStocks[i].variables.analog.stockDisponible!=undefined) 
                content = content + '<td id="displayStockDisponible'+this.targetProductStocks[i].id +'">' + this.targetProductStocks[i].variables.analog.stockDisponible.value + '</td>'
                else content = content + '<td>-</td>';
              if (this.targetProductStocks[i].id!=undefined) content = content + '<td style="width:150px">'
                +'<div style="display:flex;flex-direction:row;">'
                +'<div class="m-0 p-0"><input type="checkbox" id="checkBoxRegularizeStockOperationUp' + this.targetProductStocks[i].id + '" checked style="display:none;"><ion-icon id="btnRegularizeStockOperationUp' + this.targetProductStocks[i].id + '" data-target="'+this.targetProductStocks[i].id +'" class="btnRegularizeStockOperationUp btn tx-24 tx-primary m-0 p-0" name="add-circle-outline"></div>'
                +'<div class="m-0 p-0"><input type="checkbox" id="checkBoxRegularizeStockOperationDown' + this.targetProductStocks[i].id + '" style="display:none;"><ion-icon id="btnRegularizeStockOperationDown' + this.targetProductStocks[i].id + '" data-target="'+this.targetProductStocks[i].id +'" class="btnRegularizeStockOperationDown btn tx-24 tx-primary m-0 p-0" name="remove-circle-outline" style="opacity:0.5;"></div>'
                +'<input id="inputRegularizeStock'+this.targetProductStocks[i].id +'" class="tx-center m-0 p-0 ms-1" style="width: 100px" type=number min=0 value=0></div>'
                +'</td>'
                else content = content + '<td>-</td>';
              if (this.targetProductStocks[i].id!=undefined) content = content + '<td>' 
              + '<input id="inputRegularizeTargetStockReal'+this.targetProductStocks[i].id +'" style="height:17px;width:17px;margin-top:7px;" type=checkbox checked> <span class="tx-20 pe-1" style="vertical-align:bottom">/</span>'
              +' <input id="inputRegularizeTargetStockVirtual'+this.targetProductStocks[i].id +'" style="height:17px;width:17px;margin-top:7px;" type=checkbox checked> <span class="tx-20 pe-1" style="vertical-align:bottom">/</span>' 
              + '<input id="inputRegularizeTargetStockAvailable'+this.targetProductStocks[i].id +'" style="height:17px;width:17px;margin-top:7px;" type=checkbox checked></td>'
                else content = content + '<td>-</td>';
              if (this.targetProductStocks[i].id!=undefined) content = content + '<td><ion-icon data-target="'+this.targetProductStocks[i].id +'" class="btnRegularizeStock btn tx-24 tx-primary p-0 ms-2 m-auto" name="create-outline"></td>'
                else content = content + '<td>-</td>';
            '</tr>';
            }
          content = content + '</table>';
        }
        else { 
          content = '' +
          '<table id="detailTable'+this.targetProductId+'" cellspacing="0" style="border: solid 1px var(--main-dark-50); width:100%; margin: 0;">' +
          '<tr>' +
            '<td class="tx-bold ">No dispone de stocks.</td>' +
          '</tr>' +
          '</table>';
        }
        row.child(content).show();
        tr.addClass('shown');
        $(".btnRegularizeStockOperationUp").get().forEach(btn => {
          btn.addEventListener('click', this.onOperationUp);
        });
        $(".btnRegularizeStockOperationDown").get().forEach(btn => {
          btn.addEventListener('click', this.onOperationDown);
        });
        $(".btnRegularizeStock").get().forEach(btn => {
          if (btn.onclick != null) return;
          btn.addEventListener('click', this.onRegularize);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
      console.log(event);
    },
    onOperationUp(event) {
      let btn = event.target;
      this.regularizationForm.targetStockId = btn.dataset.target;
      if( $("#checkBoxRegularizeStockOperationUp"+this.regularizationForm.targetStockId).get()[0].checked == false) {
        $("#checkBoxRegularizeStockOperationUp"+this.regularizationForm.targetStockId).get()[0].checked = true;
        $("#btnRegularizeStockOperationUp"+this.regularizationForm.targetStockId).get()[0].style.opacity = 1;
        $("#checkBoxRegularizeStockOperationDown"+this.regularizationForm.targetStockId).get()[0].checked = false;
        $("#btnRegularizeStockOperationDown"+this.regularizationForm.targetStockId).get()[0].style.opacity = 0.5;
      }
    },
    onOperationDown(event) {
      let btn = event.target;
      this.regularizationForm.targetStockId = btn.dataset.target;
      if( $("#checkBoxRegularizeStockOperationDown"+this.regularizationForm.targetStockId).get()[0].checked == false) {
        $("#checkBoxRegularizeStockOperationDown"+this.regularizationForm.targetStockId).get()[0].checked = true;
        $("#btnRegularizeStockOperationDown"+this.regularizationForm.targetStockId).get()[0].style.opacity = 1;
        $("#checkBoxRegularizeStockOperationUp"+this.regularizationForm.targetStockId).get()[0].checked = false;
        $("#btnRegularizeStockOperationUp"+this.regularizationForm.targetStockId).get()[0].style.opacity = 0.5;
      }
    },
    onRegularize(event) {
      let btn = event.target;
      this.regularizationForm.targetStockId = btn.dataset.target;
      this.setNewRegularizationData();
    },
    // SET
    async setNewRegularizationData() {
      if (!this.display.regularizeStockModal) {
        console.log('REGULARIZE WHERE ID:');
        console.log(this.regularizationForm.targetStockId);
        $("#inputRegularizeStock"+this.regularizationForm.targetStockId).get().forEach(input => {
          this.regularizationForm.targetStockUnitsChange = input.value;
        });
        $("#inputRegularizeTargetStockVirtual"+this.regularizationForm.targetStockId).get().forEach(input => {
          this.regularizationForm.targetStockVirtual = input.checked;
        });
        $("#inputRegularizeTargetStockReal"+this.regularizationForm.targetStockId).get().forEach(input => {
          this.regularizationForm.targetStockReal = input.checked;
        });
        $("#inputRegularizeTargetStockAvailable"+this.regularizationForm.targetStockId).get().forEach(input => {
          this.regularizationForm.targetStockAvailable = input.checked;
        });
        $("#checkBoxRegularizeStockOperationUp"+this.regularizationForm.targetStockId).get().forEach(input => {
          this.regularizationForm.operation = (input.checked)?"+":"-";
        });
        $("#displayStockFisico"+this.regularizationForm.targetStockId).get().forEach(display => {
          this.regularizationForm.targetStockRealOldUnits = display.innerHTML;
        });
        $("#displayStockNeto"+this.regularizationForm.targetStockId).get().forEach(display => {
          this.regularizationForm.targetStockVirtualOldUnits = display.innerHTML;
        });
        $("#displayStockDisponible"+this.regularizationForm.targetStockId).get().forEach(display => {
          this.regularizationForm.targetStockAvailableOldUnits = display.innerHTML;
        });
        $("#displayStockPadre"+this.regularizationForm.targetStockId).get().forEach(display => {
          this.regularizationForm.targetStockParent = display.innerHTML;
        });
        this.$router.replace({params: {targetEntity: this.regularizationForm.targetStockId}})
      }
      else {
        let regularizationData = {
          stockId: this.regularizationForm.targetStockId,
          slotId: this.regularizationForm.targetStockParent,
          targetStockVirtual: this.regularizationForm.targetStockVirtual,
          targetStockReal: this.regularizationForm.targetStockReal,
          targetStockAvailable: this.regularizationForm.targetStockAvailable,
          operation: this.regularizationForm.operation,
          units: Math.abs(this.regularizationForm.targetStockUnitsChange)
        };
        let response = await api.postNewRegularization(regularizationData);
        if (response["status-code"] === "200") {
          window.alert("La regularización ha sido creada satisfactoriamente.");
        }
        else {
          window.alert("Error al crear la regularización.");
        }
        this.regularizationForm.targetStockId = "",
        this.regularizationForm.targetStockParent = "",
        this.regularizationForm.targetStockVirtualOldUnits = 0,
        this.regularizationForm.targetStockRealOldUnits = 0,
        this.regularizationForm.targetStockAvailableOldUnits = 0,
        this.regularizationForm.targetStockUnitsChange = 0,
        this.regularizationForm.targetStockVirtual = true,
        this.regularizationForm.targetStockReal = true,
        this.regularizationForm.operation = "+"
      }
      this.toggleRegularizeStockModal();
    }
  },
  async created() {
    await this.getProductList();
    this.$emit('getAvailableCompanies',(this.displayStockDataTable));
  },
  mounted() {
    $("#stocksDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.usersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
/*.btnTargetStock:hover,*/ .btnCopyId:hover, .btnChartsStock:hover, .btnRegularizeStockOperationUp:hover, .btnRegularizeStockOperationDown:hover, .btnRegularizeStock:hover, .btnDisplayStockOrders:hover {
  color: var(--main-dark) !important;
}
#orderDetailsModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnDetailsStock:hover {
  color: var(--main-dark) !important;
}
.kpi-container {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 2rem 1rem;
  gap: 1rem;
}
@media (max-width: 991px) {
  .kpi-container {
    display: flex;
    flex-direction: row;
    padding: 0 0 1rem 0;
    gap: 1rem;
  }
}
</style>