<template>
    <!-- MODAL Historic Incidence -->
    <div id="historicIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
      <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
        <!-- HEAD -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
          <div class="col-10">
            <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
              <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              {{ incidenceType.label}}
            </h6>
          </div>
          <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleHistoricIncidenceModal')">
            <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
          </div>
        </div>
  
        <!-- CONTENT Edit Historic -->
        <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
          <div id="editHistoricIncidenceScroll" style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
            <div class="col-12 p-2 pe-0 pb-0">
              <form class="p-0 overflow-hidden d-flex flex-wrap pe-2">
                <div class="col-12 m-0 p-2 border rounded overflow-hidden">
                  <div class="bg-menu text-white p-1 m-0 rounded">
                    {{ dictionary['Incidencia'][session.user.language] + ": " + incidenceId }}
                    <span v-if="!editView" @click="toggleEditMode()" class="pe-3 text-decoration-underline" style="float: right;">Editar</span>
                    <div  v-if="editView" style="float: right;" class="pe-3">
                    <span @click="toggleEditMode()" class="pe-3 text-decoration-underline" >Descartar</span>
                  
                  </div>
                  </div>
                  <div class="row ps-2 m-0 p-0 border">
                    <!-- level -->                   
                    <label class="col-3 text-nowrap pt-4 mt-2">
                      <p class="border border-end-0 p-2 m-1 tx-14"> {{ dictionary['Nivel de gravedad'][session.user.language] }}</p>
                    </label>
                    <div class="col-5 d-flex flex-wrap pt-4">
                      <ul>
                        <li><input type="radio" name="test" id="muyBaja" value="0" v-model="editHistoricIncidenceData.level" :disabled="!editView"/>
                          <label for="muyBaja"><img src="@/assets/img/01-nivel.png" /></label>
                        </li>
                        <li><input type="radio" name="test" id="baja" value="1" v-model="editHistoricIncidenceData.level" :disabled="!editView"/>
                          <label for="baja"><img src="@/assets/img/02-nivel.png" /></label>
                        </li>
                        <li><input type="radio" name="test" id="media" value="2" v-model="editHistoricIncidenceData.level" :disabled="!editView"/>
                          <label for="media"><img src="@/assets/img/03-nivel.png" /></label>
                        </li>
                        <li><input type="radio" name="test" id="alta" value="3" v-model="editHistoricIncidenceData.level" :disabled="!editView"/>
                          <label for="alta"><img src="@/assets/img/04-nivel.png" /></label>
                        </li>
                        <li><input type="radio" name="test" id="critica" value="4" v-model="editHistoricIncidenceData.level" :disabled="!editView"/>
                          <label for="critica"><img src="@/assets/img/05-nivel.png" /></label>
                        </li>
                      </ul>
                    </div>


                    <div class="row col-4 p-1 mt-4 pt-3">
                      <label class="col-4 tx-14 pt-1 text-decoration-underline text-dark text-nowrap">
                        {{ dictionary['Estado'][session.user.language] }}
                      </label>
                      <div class="col-8 m-0 p-0">
                        <select v-model="editHistoricIncidenceData.status" id="inputNewIncidenceLevel"
                          class="form-control form-control-line" style="color:black;" :disabled="!editView">
                          <option v-if="editHistoricIncidenceData.status <= 0" value="0">{{ dictionary['Pendiente de revisión'][session.user.language] }}</option>
                          <option v-if="editHistoricIncidenceData.status <= 1" value="1">{{ dictionary['En investigación'][session.user.language] }}</option>
                          <option v-if="editHistoricIncidenceData.status <= 2" value="2">{{ dictionary['En proceso de resolucion'][session.user.language] }}</option>
                          <option v-if="editHistoricIncidenceData.status <= 3" value="3">{{
                dictionary['Solucionada'][session.user.language] }}</option>
                          <option v-if="editHistoricIncidenceData.status <= 4" value="4">{{
                dictionary['Cerrada'][session.user.language] }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  
                  <div  class="col-12 p-0 ps-1 pe-1">
                    <hr class="tx-clomos-dark ms-2 me-2">
                  </div>
  
                  <div v-if="editView" class="row m-0 ps-1">
                    <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-2">
                      {{ dictionary['Comentarios'][session.user.language] }}
                    </label>
                  </div>
                  <div v-if="editView" class="row col-12 p-1 justify-content-center">
  
                    <div class="col-10 mb-4">
                      <textarea id="inputNewObservation" v-model="editHistoricIncidenceData.newObservations" class="form-control col-12 p-0"
                        style="color:black; min-height:100px;"></textarea>
                    </div>
                   
                  </div>
  
                  <div class="column">
                    
                    <!-- comentarios -->
                    <table class="table table-striped">
                      <thead>
                        <tr class="p-0 m-0 bg-clomos text-white">
                          <th scope="col"  class="p-0 m-0 fw-normal" style="width: 10%;">{{ dictionary["Fecha"][session.user.language] }}</th>
                          <th scope="col"  class="p-0 m-0 fw-normal" style="width: 30%;">{{ dictionary["Usuario"][session.user.language] }}</th>
                          <th scope="col"  class="p-0 m-0 fw-normal" style="width: 60%;">{{ dictionary["Comentarios"][session.user.language] }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(observacion, index) in formattedDescription" :key="index">
                          <td>{{ observacion.fecha }}</td>
                          <td>{{ observacion.usuario }}</td>
                          <td>{{ observacion.texto }}</td>
                        </tr>
                      </tbody>
                    </table>
  
  
                    <div class="col-12 p-0 ps-1 pe-1">
                      <hr class="tx-clomos-dark ms-2 me-2">
                    </div>
                  </div>
  
                  <div v-if="editView" class="col-12 mt-2 mb-2">
                    <!-- SUBMIT SETTINGS -->
                    <button  type="button" @click="setIncidenceConfiguration" id="btnSubmitDeviceConfiguration"
                      class="button-ok gradient-tablas rounded-3" style="height: 2rem !important;">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-14 hydrated"></ion-icon>
                        <span class="text-white mt-0 tx-12">{{ dictionary['Actualizar Incidencia'][session.user.language]
                          }}</span>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  //import $ from 'jquery';
  
  import api from '@/services/api';
  
  export default {
    name: "EditHistoricModal",
    props: {
      session: {
        type: Object,
        required: true,
      },
      dictionary: {
        type: Object,
        required: true,
      },
      countries: {
        type: Object,
        required: true,
      },
      timeZones: {
        type: Object,
        required: true,
      },
      incidenceId: {
        type: String,
        required: true,
      }
     
    },
    data() {
      return {
        editHistoricIncidenceData: {
          id: "",
          label: "",
          observaciones: "",
          status: "",
          level: "",
          typeOfIncidence: "",
          dateInvestigation: "",
          dateClosed: "",
          dateOpen: "",
          dateProgress: "",
          dateSolved: "",
          newObservations: [],
        },
        newObservation: '',
        incidenceType:'',
        editView:false,
      };
    },
    computed: {
      formattedDescription() {
        return Object.keys(this.editHistoricIncidenceData.description || {}).map(key => {
          let observacion = this.editHistoricIncidenceData.description[key];
          return {
            fecha: this.$dayjs(key).format('DD/MM/YYYY'),
            usuario: observacion.usuario,
            texto: observacion.texto
          };
        });
      }
    },
    emits: ["toggleEditHistoricIncidenceModal"],
    methods: {
     
     
      async geteditHistoricIncidenceData() {
        try {
          const response = await api.getHistoricIncidence(this.incidenceId);
          this.editHistoricIncidenceData = api.parseHistoricIncidenceFromResponse(response);
        } catch (error) {
          console.error("Error fetching incidence data:", error);
        }
      },
  
      async setIncidenceConfiguration() {
    try {
      
      const apiCalls = [];
  
      if (this.editHistoricIncidenceData.newObservations) {
        let requestData = {
          id: this.editHistoricIncidenceData.id,
          observaciones: this.editHistoricIncidenceData.newObservations
        };
        apiCalls.push(api.setHistoricComment(requestData));
      }
  
      
      apiCalls.push(api.setHistoricIncidence(this.editHistoricIncidenceData));
  
      
      const responses = await Promise.all(apiCalls);
  
      
      const allSuccessful = responses.every(response => response["status-code"] === "200");
      
      if (allSuccessful) {
        window.alert("Actualizado con exito");
       this.toggleEditMode()
      } else {
        window.alert("Error al actualizar la incidencia");
      }
    } catch (error) {
      window.alert("Error al actualizar la incidencia");
      console.error("Error:", error);
    }
  },
  async getIncidencesByCompany() {    
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split('/');
    
      let request = {
        nifCif: pathParts[2]===this.session.user.nifCif?this.session.user.nifCif:pathParts[2],
        limit: 0,
        offset: 0,

      };
      let response = await api.getIncidencesByCompany(request);
      this.incidenceList = api.parseIncidenceListFromResponse(response);
      this.incidenceType=this.incidenceList.find(item => item.id===this.editHistoricIncidenceData.idIncidence)
    },
    async toggleEditMode(){
      await this.geteditHistoricIncidenceData();
      this.editView=!this.editView
    }
    },
    async created() {
      await this.geteditHistoricIncidenceData();
      await this.getIncidencesByCompany();
    
    },
  
  
  
  }
  </script>
  
  <style>
  #historicIncidenceModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }

  ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="radio"][id^="muyBaja"] {
  display: none;
}
input[type="radio"][id^="baja"] {
  display: none;
}
input[type="radio"][id^="media"] {
  display: none;
}
input[type="radio"][id^="alta"] {
  display: none;
}
input[type="radio"][id^="critica"] {
  display: none;
}

label {
  border: 1px solid #fff;
  padding: 0px;
  display: block;
  position: relative;
  /*margin: 10px;*/
  cursor: pointer;
}

label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 60px;
  width: 60px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + label {
  border-color: #ddd;
}

:checked + label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:checked + label img {
  transform: scale(0.9);
  /*box-shadow: 0 0 5px #333;*/
  z-index: -1;
}

  </style>