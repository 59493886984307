<template>
  <!-- SECTION Expeditions-->
    <div id="expeditionsSection" class="row col-12 m-0 p-0">
      <div class="row col-12 p-2 m-0 section-container justify-content-center">
       
<!-- HEADER Expeditions -->
        <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
          <div class="bg-menu border-1 rounded-5 box sombra">
            
            <div class="col-12 overflow-hidden justify-content-center">
              <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
              <ion-icon name="arrow-redo" class="tx-16 me-2 align-bottom"></ion-icon>
                {{dictionary["Expediciones"][session.user.language]}}
              </h6>
            </div>

            <div class="p-3 pt-0">
<!-- Selector de Sistema -->
              <div class="col-12 m-0 p-0">	
                <hr class="m-0 p-0">

                <select
                :value="currentCompany.nifCif"
                class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
                style="border: solid 2px white ; color: black;"
                @change="onCurrentCompanyChanged">
                  <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                  <option v-for="company in availableCompanies" :key="company.nifCif"
                  :v-if="company.nifCif != session.user.nifCif
                  && company.relation != undefined
                  && company.relation.referenceCompany === this.session.user.nifCif"
                  :value="company.nifCif">
                    {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                  </option>
                </select>
                
                <hr class="m-0 p-0">

                <div class="col-12 p-0 justify-content-center d-flex">
                  <button id="btnDisplayNewExpeditionModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                  @click="toggleNewExpeditionModal">
                    <span class="button-text text-white">
                      <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                      <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir expedición"][session.user.language]}}</span>
                    </span>
                  </button>
                </div>
              </div>
              
            </div>
            
          </div>
        </div>

        <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
          <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
            <!--<ion-icon v-if="session.user.bookmark==='users'" name="bookmark" class="bookmark-button"></ion-icon>
            <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
            <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
              <ion-icon name="arrow-redo-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
              {{dictionary["Expediciones"][session.user.language]}}
            </div>
        
<!-- TABLE Expeditions -->
            <div class="m-0 p-3 overflow-auto">
              <table id="expeditionsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in expeditionsColumns" :key="column.data"
                    :width="column.width" class="fw-medium text-nowrap p-2">
                      {{dictionary[column.text][session.user.language]}}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  
  <!-- MODAL New Expedition -->
    <NewExpeditionModal
    v-if="display.newExpeditionModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :timeZones="timeZones"
    :currentCompany="currentCompany"
    :availableCompanies="availableCompanies"
    :hasReceipt="true"
    v-on:toggleNewExpeditionModal="toggleNewExpeditionModal">
    </NewExpeditionModal> 
  
  <!-- MODAL Prepare Expedition -->
    <PrepareExpeditionModal
    v-if="display.prepareExpeditionModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :timeZones="timeZones"
    :currentCompany="currentCompany"
    :availableCompanies="availableCompanies"
    :expeditionId="targetExpeditionId"
    v-on:togglePrepareExpeditionModal="togglePrepareExpeditionModal">
    </PrepareExpeditionModal> 
  
  <!-- MODAL Generar Etiquetas -->
    <GenerarEtiquetas
    v-if="display.orderLabelsModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :timeZones="timeZones"
    :currentCompany="currentCompany"
    :availableCompanies="availableCompanies"
    :orderId="targetExpeditionId"
    :orderData="targetExpedition"
    v-on:toggleOrderLabelsModal="toggleExpeditionLabelsModal">
  </GenerarEtiquetas>
  
  <!-- MODAL Plan Text Alert -->
    <TextAlertModal
    v-if="display.planExpeditionModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :alertHeader="dictionary['Planificar'][session.user.language]+': '"
    :alertTarget="targetExpeditionId"
    :alertInput="(session.browser === 'firefox') ?'date' :'datetime-local'"
    :alertValue="alertValue"
    v-on:updateAlertValue="onUpdateAlertValue"
    v-on:acceptAlert="onPlanExpedition"
    v-on:dismissAlert="togglePlanExpeditionModal">
    </TextAlertModal>
  
  <!-- MODAL End Text Alert -->
    <TextAlertModal
    v-if="display.endExpeditionModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :alertHeader="dictionary['Expedir'][session.user.language]+': '"
    :alertTarget="targetExpeditionId"
    v-on:acceptAlert="onEndExpedition"
    v-on:dismissAlert="toggleEndExpeditionModal">
    </TextAlertModal>
  
  <!-- MODAL Delete Text Alert -->
    <TextAlertModal
    v-if="display.deleteExpeditionModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :alertHeader="dictionary['Expedición a eliminar'][session.user.language]+': '"
    :alertTarget="targetExpeditionId"
    v-on:acceptAlert="onDeleteExpedition"
    v-on:dismissAlert="toggleDeleteExpeditionModal">
    </TextAlertModal>
  
  </template>
  
<script>
  import $ from 'jquery';
  import NewExpeditionModal from '@/components/expeditions/NewExpeditionModal';
  import PrepareExpeditionModal from '@/components/expeditions/PrepareExpeditionModal';
  import GenerarEtiquetas from '@/components/expeditions/GenerarEtiquetas';
  import TextAlertModal from '@/components/other/TextAlertModal';
  // Clomos
  import api from '@/services/api';
  
  export default {
    name: "ExpeditionsComponent",
    components: { PrepareExpeditionModal, NewExpeditionModal, GenerarEtiquetas, TextAlertModal  },
    props: {
      session: {
        type: Object,
        required: true,
      },
      dictionary: {
        type: Object,
        required: true,
      },
      countries: {
        type: Object,
        required: true,
      },
      timeZones: {
        type: Object,
        required: true,
      },
      currentCompany: {
        type: Object,
        required: true,
      },
      availableCompanies: {
        type: Object,
        required: true,
      },
      visibility: {
        type: Object,
        required: true,
      },
      dataTableOptions: {
        type: Object,
        required: true,
      }
    },
    watch: { 
      currentCompany: async function() {
        await this.getExpeditionList();
        this.displayExpeditionsDataTable();
      }
    },
    data() {
      return {
        expeditionsColumns: [
          {text:"Albarán", data: "expeditionId", width: "5%", className: "align-middle",
            render: (data) => {
              return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailExpedition btn tx-24 tx-primary m-0 p-0'></ion-icon>";
            }
          },
          {text:"Identificador", data: "expeditionId", width: "15%", className: "tx-left align-middle dt-nowrap",
            render: (data) => {
              return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              +"<span data-id="+data+" class='btnTargetProduct c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
            }
          },
          {text:"Estado", data: "expeditionStatus", width: "10%", className: "tx-left align-middle",
            render: (data) => {
              switch (data.status) {
                case "0":
                  return this.dictionary["Nueva"][this.session.user.language];
                case "1":
                  return this.dictionary["Planificada"][this.session.user.language];
                case "2":
                  return this.dictionary["Ejecutando"][this.session.user.language];
                case "3":
                  return this.dictionary["Ejecutada"][this.session.user.language];
                case "4":
                  return this.dictionary["Finalizada"][this.session.user.language];
                case "5":
                  return this.dictionary["Cancelada"][this.session.user.language];
                default:
                  return data.status
              }
            }        
          },
          {text:"Tipo", data: "expeditionType", width: "10%", className: "tx-left align-middle",
            render: (data) => {
              switch (data) {
                case "0":
                  return "Entrada";
                case "1":
                  return "Salida";
                case "2":
                  return "Regularización";
                case "3":
                  return "Devolución";
                case "4":
                  return "Pedido";
              }
            }
          },
          {text:"Fecha de creación", data: "expeditionCreationDateTime", width: "10%", className: "align-middle",
            render: (data) => {   
              return (data!="")
              ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              :"<span style='display: none;'></span> <spam>-</spam>";
            }
          },
          {text:"Planificar", data: "expeditionStatus", width: "10%", className: "align-middle",
            render: (data) => {
              return (data.plannedDateTime== "")
                ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnPlanExpedition btn tx-24 tx-primary m-0 p-0'></ion-icon>"
                : "<span style='display: none;'>" + data.plannedDateTime.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data.plannedDateTime, this.session.user, this.$dayjs) + "</spam>";
            }
          },
          {text:"Preparar", data: "expeditionStatus", width: "5%", className: "align-middle",
            render: (data) => {
              if (Number(data.status)==5)
              {
                return  "<ion-icon name='close-circle-outline' data-id="+data.id+" class='btnPrepareExpedition btn tx-24 tx-danger m-0 p-0' disabled></ion-icon>";
              }
              return (Number(data.status)<1)
                ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 m-0 p-0' disabled></ion-icon>"
                : (Number(data.status)===1 || Number(data.status)===2)
                  ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnPrepareExpedition btn tx-24 tx-primary m-0 p-0'></ion-icon>"
                  : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnPrepareExpedition btn tx-24 tx-success m-0 p-0'></ion-icon>";
            }
          },
          {text:"Etiqueta", data: "expeditionObject", width: "5%", className: "align-middle",
            render: (data) => {
              if (Number(data.status)==5)
              {
                return  "<ion-icon name='document-text-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 m-0 p-0' disabled></ion-icon>";
              }
  
              return (Number(data.status)<3 
              || data.params === undefined
              || data.summary === undefined 
              || data.summary === "")
                ? "<ion-icon name='document-text-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 m-0 p-0' disabled></ion-icon>"
                : "<ion-icon name='document-text-outline' data-id="+data.id+" class='btnDisplayExpeditionLabels btn tx-24 tx-success m-0 p-0'></ion-icon>";
            }
          },
          {text:"Expedir", data: "expeditionStatus", width: "5%", className: "align-middle",
            render: (data) => {
              if (Number(data.status)==5)
              {
                return  "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 m-0 p-0' disabled></ion-icon>";
              }
              return (Number(data.status)<3)
                ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 m-0 p-0' disabled></ion-icon>"
                : (Number(data.status)===3)
                  ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnEndExpedition btn tx-24 tx-primary m-0 p-0'></ion-icon>"
                  : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnExecutedExpedition btn tx-24 tx-success m-0 p-0' disabled></ion-icon>";
            }
          },
          {text:"Eliminar", data: "expeditionStatus", width: "5%", className: "align-middle",
            render: (data) => {
              return (Number(data.status)===0)
                ? "<ion-icon name='trash-outline' data-id="+data.id+" class='btnDeleteExpedition btn tx-24 tx-danger m-0 p-0'></ion-icon>"
                : "<ion-icon name='trash-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 m-0 p-0' disabled></ion-icon>";
            }
          }
        ],
        targetExpeditionId: "",
        expeditionList: [],
        alertValue: '',
        bulgeInput: {
          volume: {
            height: 0,
            width: 0,
            depth: 0
          },
          units: 0
        },
        bulgeList: [],
        display: {
          newExpeditionModal: false,
          planExpeditionModal: false,
          prepareExpeditionModal: false,
          orderLabelsModal: false,
          endExpeditionModal: false,
          deleteExpeditionModal: false
        }
      }
    },
    computed: {
      targetExpedition() {
        for (let i in this.expeditionList) {
          if (this.expeditionList[i].id === this.targetExpeditionId)
            return this.expeditionList[i];
        }
        return {};
      }
    },
    emits: ["setCurrentCompany", "getAvailableCompanies"],
    methods: {
      // DISPLAY
      onCurrentCompanyChanged(event) {
        let value = event.target.value;
        this.$router.replace({params: {currentCompanyNifCif: value}})
        this.$emit('setCurrentCompany',value);
      },
      displayExpeditionsDataTable() {
        $("#expeditionsDataTable").DataTable().clear()
        for (let i in this.expeditionList) {
          $("#expeditionsDataTable").DataTable().rows.add([{
            expeditionObject: this.expeditionList[i],
            expeditionId: this.expeditionList[i].id, 
            expeditionType: this.expeditionList[i].type,
            expeditionStatus: {id: this.expeditionList[i].id, status: this.expeditionList[i].status, plannedDateTime: (this.expeditionList[i].plannedDateTime != undefined && this.expeditionList[i].plannedDateTime != "") ? new Date(this.expeditionList[i].plannedDateTime) : ""}, 
            expeditionExecutionDateTime: (this.expeditionList[i].executionDateTime != undefined && this.expeditionList[i].executionDateTime != "") ? new Date(this.expeditionList[i].executionDateTime) : "",
            expeditionCreationDateTime: (this.expeditionList[i].creationDateTime != undefined && this.expeditionList[i].creationDateTime != "") ? new Date(this.expeditionList[i].creationDateTime) : "",
            expeditionPlannedDateTime: (this.expeditionList[i].plannedDateTime != undefined && this.expeditionList[i].plannedDateTime != "") ? new Date(this.expeditionList[i].plannedDateTime) : "",
            expeditionExecutionEndDateTime: (this.expeditionList[i].executionEndDateTime != undefined && this.expeditionList[i].executionEndDateTime != "") ? new Date(this.expeditionList[i].executionEndDateTime) : ""
          }]);
        }
        $("#expeditionsDataTable").DataTable().draw();
        this.setButtonFunctions();
        if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
        $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
      },
      async toggleNewExpeditionModal() {
        if (this.display.newExpeditionModal) {
          await this.getExpeditionList();
          this.displayExpeditionsDataTable();
        }
        this.display.newExpeditionModal = !this.display.newExpeditionModal;
      },
      async togglePlanExpeditionModal() {
        if (this.display.planExpeditionModal) {
          await this.getExpeditionList();
          this.displayExpeditionsDataTable();
        }
        this.display.planExpeditionModal = !this.display.planExpeditionModal;
      },
      async togglePrepareExpeditionModal() {
        if (this.display.prepareExpeditionModal) {
          await this.getExpeditionList();
          this.displayExpeditionsDataTable();
        }
        this.display.prepareExpeditionModal = !this.display.prepareExpeditionModal;
      },
      async toggleExpeditionLabelsModal() {
        if (this.display.orderLabelsModal) {
          await this.getExpeditionList();
          this.displayExpeditionsDataTable();
        }
        else if (this.targetExpedition.params === undefined
        || this.targetExpedition.summary === undefined 
        || this.targetExpedition.summary === "") {
          return;
        }
        this.display.orderLabelsModal = !this.display.orderLabelsModal;
      },
      async toggleEndExpeditionModal() {
        if (this.display.endExpeditionModal) {
          await this.getExpeditionList();
          this.displayExpeditionsDataTable();
        }
        this.display.endExpeditionModal = !this.display.endExpeditionModal;
      },
      async toggleDeleteExpeditionModal() {
        if (this.display.deleteExpeditionModal) {
          await this.getExpeditionList();
          this.displayExpeditionsDataTable();
        }
        this.display.deleteExpeditionModal = !this.display.deleteExpeditionModal;
      },
      // BUTTONS
      setButtonFunctions() {
        setTimeout(()=>{
          $(".btnTargetExpedition").get().forEach(btn => {
            btn.addEventListener('click', this.onTargetExpedition);
          });
          $(".btnDetailExpedition").get().forEach(btn => {
            btn.addEventListener('click', this.onDetailExpedition)
          });
          $(".btnPlanExpedition").get().forEach(btn => {
            btn.addEventListener('click', this.onPlanExpedition);
          }); 
          $(".btnPrepareExpedition").get().forEach(btn => {
            btn.addEventListener('click', this.onPrepareExpedition);
          }); 
          $(".btnDisplayExpeditionLabels").get().forEach(btn => {
            btn.addEventListener('click', this.onDisplayExpeditionLabels);
          }); 
          $(".btnEndExpedition").get().forEach(btn => {
            btn.addEventListener('click', this.onEndExpedition);
          });
          $(".btnDeleteExpedition").get().forEach(btn => {
            btn.addEventListener('click', this.onDeleteExpedition);
          });
          $(".btnCopyId").get().forEach(btn => {
            btn.addEventListener('click', this.onCopyId);
          });
        }, 300);
      },
      onCopyId(event) {
        navigator.clipboard.writeText(event.target.getAttribute('data-id'));
      },
      // GET
      async getExpeditionList(callback) {
        let request = {
          nifCif: this.currentCompany.nifCif
        };
        let withdrawListResponse = await api.getExpeditionList(request);
        let withdrawList = api.parseOrdersFromResponse(withdrawListResponse)
        let orderListResponse = await api.getOrderList(request);
        let orderList = api.parseOrdersFromResponse(orderListResponse);
  
        this.expeditionList = withdrawList.concat(orderList);
  
        if (callback && typeof callback === "function") callback(this.expeditionList);
      },
      // TARGET 
      onTargetExpedition(event) {
        console.log('TARGET WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetExpeditionId = event.target.getAttribute('data-id');
      },
      // PLAN
      onUpdateAlertValue(value) {
        this.alertValue = value;
      },
      async onPlanExpedition(event) {
        if (!this.display.planExpeditionModal) {
          console.log('PLAN WHERE ID:');
          console.log(event.target.getAttribute('data-id'));
          this.targetExpeditionId = event.target.getAttribute('data-id');
        }
        else {
          if (this.alertValue==='') this.alertValue = new Date();
          let statusData = {
            orderId : this.targetExpeditionId,
            dateTime : this.alertValue,
            status : 1
          }
          let response = await api.setOrderStatus(statusData);
          if (response["status-code"] === "200") {
            window.alert("La expedición ha sido planeada satisfactoriamente.");
          }
          else {
            window.alert("Error al planear el expedición.");
          }
        }
        this.togglePlanExpeditionModal();
      },
      // PREPARE
      onPrepareExpedition(event) {
        console.log('PREPARE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetExpeditionId = event.target.getAttribute('data-id')
        this.togglePrepareExpeditionModal();
      },
      // LABELS
      onDisplayExpeditionLabels(event) {
        console.log('SHOW LABELS FOR ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetExpeditionId = event.target.getAttribute('data-id');
        this.toggleExpeditionLabelsModal();
      },
      // DETAILS
      onDetailExpedition(event) {
        console.log('DETAIL WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetExpeditionId = event.target.getAttribute('data-id')
        var tr = $(event.target).closest('tr');
        var row = $("#expeditionsDataTable").DataTable().row(tr);
        if (row.child.isShown()) {
          event.target.name = "list-circle-outline";
          row.child.hide();
          tr.removeClass('shown');
        } 
        else {
          event.target.name = "chevron-up-circle-outline";
          let content = '' 
          if (this.targetExpedition.summary != "") {
            content = content + '<div class="ms-auto me-auto mb-1 p-0 border-1 overflow-auto"> <text class="tx-bold pe-1">ID Pedido:</text>'+JSON.parse(this.targetExpedition.summary)["idPedido"] +' </div>';
          }
          content = content +
            '<table id="detailTable'+this.targetExpeditionId+'" cellspacing="0" style="border: solid 1px var(--main-dark-50); width:100%; margin: 0;">' +
              '<tr>' +
                '<td class="tx-bold col-3">Referencia</td>' +
                '<td class="tx-bold">Nombre</td>' +
                '<td class="tx-bold">Cantidad</td>' +
                '<td class="tx-bold">Lote</td>' +
                '<td class="tx-bold">Nº de serie</td>' +
                '<td class="tx-bold">Talla</td>' +
                '<td class="tx-bold">Color</td>' +
                '<td class="tx-bold">Fecha de caducidad</td>' +
              '</tr>';
            for (let i in this.targetExpedition.params.productos) {
              let product = this.targetExpedition.params.productos[i];
              content = content + '<tr>';
                if (product.referencia!=undefined&&product.referencia!="") content = content + '<td>' + product.referencia + '</td>'
                  else content = content + '<td>-</td>';
                if (product.etiqueta!=undefined&&product.etiqueta!="") content = content + '<td>' + product.etiqueta + '</td>'
                  else content = content + '<td>-</td>';
                if (product.unidades!=undefined&&product.unidades!="") content = content + '<td>' + product.unidades + '</td>'
                  else content = content + '<td>-</td>';
                if (product.lote!=undefined&&product.lote!="") content = content + '<td>' + product.lote + '</td>'
                  else content = content + '<td>-</td>';
                if (product.numeroSerie!=undefined&&product.numeroSerie!="") content = content + '<td>' + product.numeroSerie + '</td>'
                  else content = content + '<td>-</td>';
                if (product.talla!=undefined&&product.talla!="") content = content + '<td>' + product.talla + '</td>'
                  else content = content + '<td>-</td>';
                if (product.color!=undefined&&product.color!="") content = content + '<td>' + product.color + '</td>'
                  else content = content + '<td>-</td>';
                if (product.fechaCaducidad!=undefined&&product.fechaCaducidad!="") content = content + '<td>' + product.fechaCaducidad + '</td>'
                  else content = content + '<td>-</td>';
              '</tr>';
            }
            content = content + '</table>';
          row.child(content).show();
          document.getElementById('detailTable'+this.targetExpeditionId).parentNode.style.padding = 0;
          tr.addClass('shown');
        }
      },
      // END
      async onEndExpedition(event) {
        if (!this.display.endExpeditionModal) {
          console.log('END WHERE ID:');
          console.log(event.target.getAttribute('data-id'));
          this.targetExpeditionId = event.target.getAttribute('data-id');
        }
        else {
          let statusData = {
            orderId : this.targetExpeditionId,
            dateTime : new Date(),
            status : 4
          }
          let response = await api.setOrderStatus(statusData);
          if (response["status-code"] === "200") {
            this.$emit('toggleNewExpeditionModal');
            window.alert("La expedición ha finalizado satisfactoriamente.");
          }
          else {
            window.alert("Error al finalizar la expedición.");
          }
        }
        this.toggleEndExpeditionModal();
      },
      // DELETE
      async onDeleteExpedition(event) {
        if (!this.display.deleteExpeditionModal) {
          console.log('DELETE WHERE ID:');
          console.log(event.target.getAttribute('data-id'));
          this.targetExpeditionId = event.target.getAttribute('data-id');
        }
        else {
          let response = await api.deleteOrder(this.targetExpeditionId);
          if (response["status-code"] === "200") {
            this.$emit('toggleNewExpeditionModal');
            window.alert("La expedición ha sido eliminada satisfactoriamente.");
          }
          else {
            window.alert("Error al eliminar la expedición.");
          }
        }
        this.toggleDeleteExpeditionModal();
      }
    },
    async created() {
      await this.getExpeditionList()
      this.$emit('getAvailableCompanies',(this.displayExpeditionsDataTable));
    },
    mounted() {
      $("#expeditionsDataTable").DataTable(
        {
          pagingType: 'full_numbers',
          pageLength: 10,
          processing: true,
          dom: 'Bfrtip',
          buttons: [
            { "extend": 'colvis', "className": 'btn' },
            { "extend": 'copy', "className": 'btn' },
            { "extend": 'csv', "className": 'btn' },
            { "extend": 'print', "className": 'btn me-0' },
          ],
          columns: this.expeditionsColumns,
          select: this.dataTableOptions.select,
          language: this.dataTableOptions.language[this.session.user.language],
          //stateSave: this.dataTableOptions.stateSave,
          order: [[4,'desc']]
        }
      );
      $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
    }
  }
</script>
<style>
  #prepareExpeditionModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }
  
  .btnCopyId:hover, .btnDetailExpedition:hover, .btnPlanExpedition:hover,  .btnPrepareExpedition:hover, .btnEndExpedition:hover, .btnDisplayExpeditionLabels:hover, .btnDeleteExpedition:hover {
    color: var(--main-dark) !important;
  }
  .btnPlannedExpedition:hover, .btnPreparedExpedition:hover, .btnExecutedExpedition:hover {
    color: var(--main-dark-50) !important;
  }
</style>