<template>
<!-- SECTION Receptions-->
  <div id="receptionSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      
<!-- HEADER Receptions -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5" >
        <div class="bg-menu border-1 rounded-5 box sombra">
          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="arrow-undo" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Recepciones"][session.user.language]}}
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr class="m-0 p-0">

              <select
              :value="currentCompany.nifCif"
              class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>
              
              <hr class="m-0 p-0">

              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewReceptionModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                @click="toggleNewReceptionModal">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir recepción"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>

      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <!--<ion-icon v-if="session.user.bookmark==='users'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
            <ion-icon name="arrow-undo-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{dictionary["Recepciones"][session.user.language]}}
          </div>

<!-- TABLE Receptions -->
          <div class="m-0 p-3 overflow-auto">
            <table id="receptionsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in receptionsColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap p-2">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>

<!-- MODAL New Reception -->
  <NewReceptionModal
  v-if="display.newReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  v-on:toggleNewReceptionModal="toggleNewReceptionModal">
  </NewReceptionModal> 

<!-- MODAL Prepare Reception -->
  <PrepareReceptionModal
  v-if="display.prepareReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :receptionId="targetReceptionId"
  v-on:togglePrepareReceptionModal="togglePrepareReceptionModal">
  </PrepareReceptionModal> 

<!-- MODAL Plan Text Alert -->
  <TextAlertModal
  v-if="display.planReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Planificar'][session.user.language] + ': '"
  :alertTarget="targetReceptionId"
  :alertInput="(session.browser === 'firefox') ?'date' :'datetime-local'"
  :alertValue="alertValue"
  v-on:updateAlertValue="onUpdateAlertValue"
  v-on:acceptAlert="onPlanReception"
  v-on:dismissAlert="togglePlanReceptionModal">
  </TextAlertModal>

<!-- MODAL End Text Alert -->
  <TextAlertModal
  v-if="display.endReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Recepcionar'][session.user.language] + ': '"
  :alertTarget="targetReceptionId"
  v-on:acceptAlert="onEndReception"
  v-on:dismissAlert="toggleEndReceptionModal">
  </TextAlertModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Recepción a eliminar'][session.user.language] + ': '"
  :alertTarget="targetReceptionId"
  v-on:acceptAlert="onDeleteReception"
  v-on:dismissAlert="toggleDeleteReceptionModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewReceptionModal from '@/components/receptions/NewReceptionModal';
import PrepareReceptionModal from '@/components/receptions/PrepareReceptionModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "ReceptionsComponent",
  components: { PrepareReceptionModal, NewReceptionModal, TextAlertModal  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getReceptionList();
      this.displayReceptionsDataTable();
    }
  },
  data() {
    return {
      receptionsColumns: [
        {text:"Albarán", data: "receptionId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailReception btn tx-24 tx-primary m-0 p-0'></ion-icon>";
          }
        },
        {text:"Identificador", data: "receptionId", width: "15%", className:"tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
            +"<span data-id="+data+" class='btnTargetProduct "+/*"btn "+*/"tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Estado", data: "receptionStatus", width: "10%", className:"tx-left align-middle",
          render: (data) => {
            switch (data.status) {
              case "0":
                return this.dictionary["Creada"][this.session.user.language];
              case "1":
                return this.dictionary["Planificada"][this.session.user.language];
              case "2":
                return this.dictionary["Ejecutando"][this.session.user.language];
              case "3":
                return this.dictionary["Ejecutada"][this.session.user.language];
              case "4":
                return this.dictionary["Finalizada"][this.session.user.language];
              case "5":
                return this.dictionary["Cancelada"][this.session.user.language];
              default:
                return data.status
            }
          }        
        },
        {text:"Tipo", data: "receptionType", width: "10%", className:"tx-left align-middle",
          render: (data) => {
            switch (data) {
              case "0":
                return "Entrada";
              case "1":
                return "Salida";
              case "2":
                return "Regularización";
              case "3":
                return "Devolución";
              case "4":
                return "Pedido";
            }
          }
        },
        {text:"Fecha de creación", data: "receptionCreationDateTime", width: "10%", className:"align-middle",
          render: (data) => {   
            return (data!="")
            ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
            :"<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {text:"Planificar", data: "receptionStatus", width: "5%", className:"align-middle",
          render: (data) => {
            return (data.plannedDateTime == "")
              ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnPlanReception btn tx-24 tx-primary m-0 p-0'></ion-icon>"
              : "<span style='display: none;'>" + data.plannedDateTime.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data.plannedDateTime, this.session.user, this.$dayjs) + "</spam>";
          }
        },
        {text:"Preparar", data: "receptionStatus", width: "5%", className:"align-middle",
          render: (data) => {
            if (Number(data.status)==5)
            {
              return  "<ion-icon name='close-circle-outline' data-id="+data.id+" class='btnPrepareReception btn tx-24 tx-danger opacity-50 m-0 p-0' disabled></ion-icon>";
            }
            return (Number(data.status)<1)
              ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 m-0 p-0' disabled></ion-icon>"
              : (Number(data.status)===1 || Number(data.status)===2)
                ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnPrepareReception btn tx-24 tx-primary m-0 p-0'></ion-icon>"
                : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnPrepareReception btn tx-24 tx-success m-0 p-0' disabled></ion-icon>";
          }
        },
        // {text:"Recepcionar", data: "receptionStatus", width: "5%", className:"align-middle",
        //   render: (data) => {
        //     return (Number(data.status)<3)
        //       ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 m-0 p-0' disabled></ion-icon>"
        //       : (Number(data.status)===3)
        //         ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnEndReception btn tx-24 tx-primary m-0 p-0'></ion-icon>"
        //         : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnExecutedReception btn tx-24 tx-success m-0 p-0' disabled></ion-icon>";
        //   }
        // },
        {text:"Eliminar", data: "receptionStatus", width: "5%", className:"align-middle",
          render: (data) => {
            return (Number(data.status)===0)
              ? "<ion-icon name='trash-outline' data-id="+data.id+" class='btnDeleteReception btn tx-24 tx-danger m-0 p-0'></ion-icon>"
              : "<ion-icon name='trash-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 m-0 p-0' disabled></ion-icon>";
          }
        }
      ],
      targetReceptionId: "",
      receptionList: [],
      alertValue: '',
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0
      },
      bulgeList: [],
      display: {
        newReceptionModal: false,
        planReceptionModal: false,
        prepareReceptionModal: false,
        endReceptionModal: false,
        deleteReceptionModal: false
      }
    }
  },
  computed: {
    targetReception() {
      for (let i in this.receptionList) {
        if (this.receptionList[i].id === this.targetReceptionId)
          return this.receptionList[i];
      }
      return {};
    },
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayReceptionsDataTable() {
      $("#receptionsDataTable").DataTable().clear()
      for (let i in this.receptionList) {
        $("#receptionsDataTable").DataTable().rows.add([{
          receptionId: this.receptionList[i].id, 
          receptionType: this.receptionList[i].type,
          receptionStatus: {id: this.receptionList[i].id, status: this.receptionList[i].status, plannedDateTime: (this.receptionList[i].plannedDateTime != undefined && this.receptionList[i].plannedDateTime != "") ? new Date(this.receptionList[i].plannedDateTime) : ""}, 
          receptionExecutionDateTime: (this.receptionList[i].executionDateTime != undefined && this.receptionList[i].executionDateTime != "") ? new Date(this.receptionList[i].executionDateTime) : "",
          receptionCreationDateTime: (this.receptionList[i].creationDateTime != undefined && this.receptionList[i].creationDateTime != "") ? new Date(this.receptionList[i].creationDateTime) : "",
          receptionPlannedDateTime: (this.receptionList[i].plannedDateTime != undefined && this.receptionList[i].plannedDateTime != "") ? new Date(this.receptionList[i].plannedDateTime) : "",
          receptionExecutionEndDateTime: (this.receptionList[i].executionEndDateTime != undefined && this.receptionList[i].executionEndDateTime != "") ? new Date(this.receptionList[i].executionEndDateTime) : ""
        }]);
      }
      $("#receptionsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleNewReceptionModal() {
      if (this.display.newReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.newReceptionModal = !this.display.newReceptionModal;
    },
    async togglePlanReceptionModal() {
      if (this.display.planReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.planReceptionModal = !this.display.planReceptionModal;
    },
    async togglePrepareReceptionModal() {
      if (this.display.prepareReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.prepareReceptionModal = !this.display.prepareReceptionModal;
    },
    async toggleEndReceptionModal() {
      if (this.display.endReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.endReceptionModal = !this.display.endReceptionModal;
    },
    async toggleDeleteReceptionModal() {
      if (this.display.deleteReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.deleteReceptionModal = !this.display.deleteReceptionModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetReception").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetReception);
        });
        $(".btnDetailReception").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailReception)
        });
        $(".btnPlanReception").get().forEach(btn => {
          btn.addEventListener('click', this.onPlanReception);
        }); 
        $(".btnPrepareReception").get().forEach(btn => {
          btn.addEventListener('click', this.onPrepareReception);
        }); 
        $(".btnEndReception").get().forEach(btn => {
          btn.addEventListener('click', this.onEndReception);
        });
        $(".btnDeleteReception").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteReception);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getReceptionList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif
      };
      let entryListResponse = await api.getReceptionList(request);
      let entryList = api.parseOrdersFromResponse(entryListResponse)
      let refundListResponse = await api.getRefundList(request);
      let refundList = api.parseOrdersFromResponse(refundListResponse);
      
      this.receptionList = entryList.concat(refundList);

      if (callback && typeof callback === "function") callback(this.receptionList);
    },
    // TARGET 
    onTargetReception(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetReceptionId = event.target.getAttribute('data-id');
    },
    // PLAN
    onUpdateAlertValue(value) {
      this.alertValue = value;
    },
    async onPlanReception(event) {
      if (!this.display.planReceptionModal) {
        console.log('PLAN WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetReceptionId = event.target.getAttribute('data-id');
      }
      else {
        if (this.alertValue==='') this.alertValue = new Date();
        let statusData = {
          orderId : this.targetReceptionId,
          dateTime : this.alertValue,
          status : 1
        }
        let response = await api.setOrderStatus(statusData);
        if (response["status-code"] === "200") {
          window.alert("La recepción ha sido planeada satisfactoriamente.");
        }
        else {
          window.alert("Error al planear el recepción.");
        }
      }
      this.togglePlanReceptionModal();
    },
    // PREPARE
    onPrepareReception(event) {
      console.log('PREPARE WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetReceptionId = event.target.getAttribute('data-id')
      this.togglePrepareReceptionModal();
    },
    // DETAILS
    onDetailReception(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetReceptionId = event.target.getAttribute('data-id')
      var tr = $(event.target).closest('tr');
      var row = $("#receptionsDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } 
      else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' 
        content = content +
          '<table id="detailTable'+this.targetReceptionId+'" cellspacing="0" style="border: solid 1px var(--main-dark-50); width:100%; margin: 0;">' +
            '<tr>' +
              '<td class="tx-bold col-3">Referencia</td>' +
              '<td class="tx-bold">Nombre</td>' +
              '<td class="tx-bold">Cantidad</td>' +
              '<td class="tx-bold">Lote</td>' +
              '<td class="tx-bold">Nº de serie</td>' +
              '<td class="tx-bold">Talla</td>' +
              '<td class="tx-bold">Color</td>' +
              '<td class="tx-bold">Fecha de caducidad</td>' +
            '</tr>';
          for (let i in this.targetReception.params.productos) {
            let product = this.targetReception.params.productos[i];
            content = content + '<tr>';
              if (product.referencia!=undefined&&product.referencia!="") content = content + '<td>' + product.referencia + '</td>'
                else content = content + '<td>-</td>';
              if (product.etiqueta!=undefined&&product.etiqueta!="") content = content + '<td>' + product.etiqueta + '</td>'
                else content = content + '<td>-</td>';
              if (product.unidades!=undefined&&product.unidades!="") content = content + '<td>' + product.unidades + '</td>'
                else content = content + '<td>-</td>';
              if (product.lote!=undefined&&product.lote!="") content = content + '<td>' + product.lote + '</td>'
                else content = content + '<td>-</td>';
              if (product.numeroSerie!=undefined&&product.numeroSerie!="") content = content + '<td>' + product.numeroSerie + '</td>'
                else content = content + '<td>-</td>';
              if (product.talla!=undefined&&product.talla!="") content = content + '<td>' + product.talla + '</td>'
                else content = content + '<td>-</td>';
              if (product.color!=undefined&&product.color!="") content = content + '<td>' + product.color + '</td>'
                else content = content + '<td>-</td>';
              if (product.fechaCaducidad!=undefined&&product.fechaCaducidad!="") content = content + '<td>' + product.fechaCaducidad + '</td>'
                else content = content + '<td>-</td>';
            '</tr>';
          }
          content = content + '</table>';
        row.child(content).show();
        document.getElementById('detailTable'+this.targetReceptionId).parentNode.style.padding = 0;
        tr.addClass('shown');
      }
    },
    // END
    async onEndReception(event) {
      if (!this.display.endReceptionModal) {
        console.log('END WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetReceptionId = event.target.getAttribute('data-id');
      }
      else {
        let statusData = {
          orderId : this.targetReceptionId,
          dateTime : new Date(),
          status : 4
        }
        let response = await api.setOrderStatus(statusData);
        if (response["status-code"] === "200") {
          this.$emit('toggleNewReceptionModal');
          window.alert("La recepción ha finalizado satisfactoriamente.");
        }
        else {
          window.alert("Error al finalizar la recepción.");
        }
      }
      this.toggleEndReceptionModal();
    },
    // DELETE
    async onDeleteReception(event) {
      if (!this.display.deleteReceptionModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetReceptionId = event.target.getAttribute('data-id');
      }
      else {
        let response = await api.deleteOrder(this.targetReceptionId);
        if (response["status-code"] === "200") {
          this.$emit('toggleNewReceptionModal');
          window.alert("La recepción ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar la recepción.");
        }
      }
      this.toggleDeleteReceptionModal();
    }
  },
  async created() {
    await this.getReceptionList()
    this.$emit('getAvailableCompanies',(this.displayReceptionsDataTable));
  },
  mounted() {
    $("#receptionsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.receptionsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[4,'desc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>
<style>
#prepareReceptionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnCopyId:hover, .btnDetailReception:hover, .btnPlanReception:hover,  .btnPrepareReception:hover, .btnEndReception:hover, .btnDeleteReception:hover {
  color: var(--main-dark) !important;
}
.btnPlannedReception:hover, .btnPreparedReception:hover, .btnExecutedReception:hover {
  color: var(--main-dark-50) !important;
}
</style>