<template>
<!-- MODAL New User -->
  <div id="newUserModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
      
<!-- HEAD New User -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="person" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary['Nuevo usuario'][session.user.language]}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewUserModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New User -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-3 pe-0 pb-0" style="display: flex; flex-flow: wrap;">

<!-- FORM User -->
            <div class="col-lg-8 col-md-12 col-12 p-0 pe-3 pb-3 m-0">
              <form class="m-0 p-1 border rounded form-material">

                <div class="bg-menu text-white p-1 m-0 rounded"> 
                  {{dictionary['Datos personales'][session.user.language]}}
                </div>

                <div class="m-0 p-2">
                  <div class="row justify-content-center">
                    
<!-- NIF CIF -->
                    <div class="form-group col-md-8 col-12 p-1">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{dictionary['Empresa'][session.user.language]}}
                      </label>
                      <div class="col-12 p-0">
                        <select
                        v-model="newUserData.nifCif"
                        class="form-control form-control-line p-2 tx-13 text-dark p-1">
                          <option v-for="company in availableCompanies" :key="company.nifCif"
                          :v-if="company.nifCif != session.user.nifCif
                          && company.relation != undefined
                          && company.relation.referenceCompany === this.session.user.nifCif"
                          :value="company.nifCif">
                            {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                          </option>
                        </select>
                      </div>
                    </div>
                    
<!-- NOMBRE -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{dictionary['Nombre'][session.user.language]}}
                      </label>
                      <div class="col-12 p-0">
                        <input v-model="newUserData.name" id="inputNewUserName" type="text" 
                        class="form-control form-control-line p-1" style="color:black;">
                      </div>
                    </div>
                  
<!-- APELLIDOS -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{dictionary['Apellidos'][session.user.language]}}
                      </label>
                      <div class="col-12 p-0">
                        <input v-model="newUserData.lastName" id="inputNewUserLastName" type="text" 
                        class="form-control form-control-line p-1" style="color:black;">
                      </div>
                    </div>
             
<!-- CORREO -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{dictionary['Correo electrónico'][session.user.language]}}
                      </label>
                      <div class="col-12 p-0">
                        <input v-model="newUserData.email" id="inputNewUserEmail" type="text" 
                        class="form-control form-control-line p-1" style="color:black;">
                      </div>
                    </div>
                    
<!-- CORREO RE -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{dictionary['Repetir correo'][session.user.language]}}
                      </label>
                      <div class="col-12 p-0">
                        <input v-model="newUserData.emailRe" id="inputNewUserEmailRe" type="text" 
                        class="form-control form-control-line p-1" style="color:black;">
                      </div>
                    </div>
<!-- MODULOS -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                        <label class="p-0 tx-13 text-dark tx-bold">
                            {{ dictionary['Módulos'][session.user.language] }}
                        </label>
                        <div class="col-12 p-0">
                          <select id="selectNewUserModuleRol" class="form-control form-control-line p-1" 
                              style="color:black;" @change="checkRolByModules($event.target.value)" v-model="selectedModule">
                        
                        <option value="" disabled>{{ dictionary['Seleccionar módulo'][session.user.language] }}</option>
                        
                        <option v-for="(module, moduleKey) in session.authorisation.availableModules" 
                                :key="moduleKey" :value="moduleKey">
                          {{ moduleKey.substring(0,1).toUpperCase() + moduleKey.substring(1, moduleKey.length) }}
                        </option>
                      </select>
                        </div>
                    </div>
<!-- ROL -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                    <label class="p-0 tx-13 text-dark tx-bold">
                        {{ dictionary['Rol'][session.user.language] }}
                    </label>
                    <div class="col-12 p-0">
                        <select v-model="newUserData.rol" id="selectNewUserRol" class="form-control form-control-line p-1" style="color:black;">
                          <option value="" disabled selected>{{ dictionary['Seleccionar rol'][session.user.language] }}</option>
                            <option v-for="(rolInfo, index) in rol" :key="index" :value="rolInfo.numero">
                           {{dictionary[rolInfo.nombre][session.user.language] }}                                
                            </option>
                        </select>
                    </div>
                    </div>


<!-- TELÉFONO -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{dictionary['Teléfono'][session.user.language]}}
                      </label>
                      <div class="col-12 p-0 d-flex flex-row">
                        <div class="col-5 m-0 ps-0 pe-3">
                          <select v-model="newUserData.telephonePrefix"
                          id="selectNewUserTelephonePrefix"
                          class="form-control p-1 me-2 text-black" >
                            <option v-for="country in countries" :key="country['dialCode']" :value="country['dialCode']">
                              {{country['code']+' '+country['dialCode']}}
                            </option>
                          </select>
                        </div>
                        <div class="w-100">
                          <input v-model="newUserData.telephone" id="inputNewUserTelephone" type="tel"
                          class="form-control form-control-line p-1" style="color:black;">
                        </div>
                      </div>
                    
                    </div>
<!-- PROVINCIA -->
                    <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                      <label class="p-0 tx-13 text-dark tx-bold">
                        {{dictionary['Provincia'][session.user.language]}}
                      </label>
                      <div class="col-12 p-0">
                        <input v-model="newUserData.province" id="inputNewUserState" type="text" 
                        class="form-control form-control-line p-1" style="color:black;">
                      </div>
                    </div>
                  
<!-- PAÍS -->
                      <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                        <label class="p-0 tx-13 text-dark tx-bold">
                          {{dictionary['País'][session.user.language]}}
                        </label>
                        <div class="col-12 p-0">
                          <select v-model="newUserData.country"
                          id="selectNewUserCountry"
                          class="form-control form-control-line p-1" style="color:black;">
                            <option v-for="country in countries" :key="country['code']" :value="country['code']">
                              {{country['name'][session.user.language]}}
                            </option>
                          </select>
                        </div>
                      </div>
<!-- DIRECCIÓN -->
                      <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                        <label class="p-0 tx-13 text-dark tx-bold">
                          {{dictionary['Dirección'][session.user.language]}} 1
                        </label>
                        <div class="col-12 p-0">
                          <textarea v-model="newUserData.adress1" id="inputEditUserAdress1" rows="1"
                          class="form-control p-1" style="color: black; padding: 0.7rem;"/>
                        </div>
                      </div>
                      
                      <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                        <label class="p-0 tx-13 text-dark tx-bold">
                          {{dictionary['Dirección'][session.user.language]}} 2
                        </label>
                        <div class="col-12 p-0">
                          <textarea v-model="newUserData.adress2" id="inputEditUserAdress2" rows="1"
                        class="form-control p-1" style="color: black; padding: 0.7rem;"/>
                        </div>
                      </div>
                      
            
                      <div class="col-12 p-0 ps-3 pe-3">
                        <hr class="tx-clomos-dark">
                      </div>
                      
                        
                      <div class="col-12 pt-0">
                        <label class="col-12 ps-2 tx-16 tx-clomos-dark tx-bold">
                          {{dictionary['Preferencias'][session.user.language]}}:
                        </label>
                      </div>
                      
<!-- IDIOMA -->
                      <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                        <label class="p-0 tx-13 text-dark tx-bold">
                          {{dictionary['Idioma'][session.user.language]}}
                        </label>
                        <div class="col-12 p-0">
                          <select v-model="newUserData.language"
                          id="selectNewUserLanguage"
                          class="form-control form-control-line p-1" style="color:black;">
                            <option value="es">
                              {{dictionary['Español'][session.user.language]}}
                            </option>
                            <option value="en">
                              {{dictionary['Inglés'][session.user.language]}}
                            </option>
                          </select>
                        </div>
                      </div>
                    
<!-- SEPARADOR DE COMAS CSV -->
                      <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                        <label class="p-0 tx-13 text-dark tx-bold">
                          {{dictionary['Separador de comas CSV'][session.user.language]}}
                        </label>
                        <div class="col-12 p-0">
                          <select v-model="newUserData.fieldSeparator"
                          id="slectNewUserFieldSeparator"
                          class="form-control form-control-line p-1" style="color:black;">
                            <option value=",">
                              {{dictionary['Coma'][session.user.language]}} ","
                            </option>
                            <option value=";">
                              {{dictionary['Punto y coma'][session.user.language]}} ";"
                            </option>
                          </select>
                        </div>
                      </div>
              
<!-- TEMPERATURA -->
                      <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                        <label class="p-0 tx-13 text-dark tx-bold">
                          {{dictionary['Temperatura'][session.user.language]}}
                        </label>
                        <div class="col-12 p-0">
                          <select v-model="newUserData.unitTemperature"
                          id="selectNewUserTemperature"
                          class="form-control form-control-line p-1" style="color:black;">
                            <option value="C">Cº (Celsius)</option>
                            <option value="F">Fº (Fahrenheit)</option>
                          </select>
                        </div>
                      </div>
              
<!-- FORMATO -->
                      <div class="form-group col-md-6 col-12 m-0 p-3 pt-1 pb-0 tx-left">
                        <label class="p-0 tx-13 text-dark tx-bold">
                          {{dictionary['Formato'][session.user.language]}}
                        </label>
                        <div class="col-12 p-0">
                          <select v-model='newUserData.dateTimeFormat'
                          id="selectNewUserDateTimeFormat"
                          class="form-control form-control-line p-1" style="color:black;">
                          <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                          </select>
                        </div>
                      </div>
<!-- ZONA HORARIA -->
                      <div class="form-group col-md-6 col-12 m-0 mb-3 p-3 pt-1 pb-0 tx-left">
                        <label class="p-0 tx-13 text-dark tx-bold">
                          {{dictionary['Zona horaria'][session.user.language]}}
                        </label>
                        <div class="col-12 p-0">
                          <select v-model="newUserData.dateTimeZone"
                          id="selectNewUserDateTimeZone"
                          class="form-control form-control-line p-1" style="color:black;">
                            <option v-for="timeZone in timeZones" :key="timeZone['value']" :value="timeZone['value']">
                              {{timeZone['value'].replace("_"," ")}}
                            </option>
                          </select>
                        </div>
                      </div>

<!-- SUBMIT -->
                      <div class="form-group col-md-6 col-12 mt-auto">
                        <div class="col-12 m-0 ps-2 pe-2">
                          <button type="button" @click="onCreateNewUser"
                          id="btnSubmitCreateNewUser" class="button-ok gradient-tablas rounded-3">
                            <span class="button-text text-white">
                              <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                              <span class="text-white mt-0">{{dictionary['Crear nuevo usuario'][session.user.language]}}</span>
                            </span>
                          </button>
                              
                        </div>
                      </div>

                    </div>

                  </div>
                </form>
              </div>

              <div class="col-lg-4 col-md-12 col-12 p-0 pe-3 m-0 d-flex flex-column">
                <div class="bg-menu rounded-5 col-12 mb-3">
<!-- MÓDULOS -->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-white tx-14 pt-2">
                      {{dictionary['Módulos permitidos'][session.user.language]}}
                    </label>
                    
                    <div class="col-12 p-1">
                      <div v-for="module, moduleKey in session.authorisation.availableModules" :key="moduleKey"
                      class="row">

                      <div class="col-12 p-0 pt-2">
                        <hr class="tx-white mt-0">
                      </div>

                      <div class="btn modules-button w-100 m-0 mb-2"
                        :class="(newUserData.authorisation.availableModules[moduleKey] != undefined)?'active': ''"
                        @click="(newUserData.authorisation.availableModules[moduleKey] != undefined)? delete newUserData.authorisation.availableModules[moduleKey] : newUserData.authorisation.availableModules[moduleKey]={}">
                          {{ moduleKey.toUpperCase() }} 
                      </div>
                      <div class="row mb-2 d-flex flex-wrap justify-content-start gap-1">
                        <div v-for="page, pageKey in module" :key="pageKey" 
                        class="btn modules-button w-auto m-0"
                        :class="(newUserData.authorisation.availableModules[moduleKey] != undefined)? (newUserData.authorisation.availableModules[moduleKey][pageKey] != undefined)? 'active': '' : 'disabled'"
                        @click="(newUserData.authorisation.availableModules[moduleKey] != undefined && newUserData.authorisation.availableModules[moduleKey][pageKey] != undefined)? delete newUserData.authorisation.availableModules[moduleKey][pageKey] : newUserData.authorisation.availableModules[moduleKey][pageKey] = 1">
                          {{ pageKey.substring(0,1).toUpperCase()+pageKey.substring(1,pageKey.length) }}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewUserModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    modules:{
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      newUserData: {
        nifCif: this.$route.params.currentCompanyNifCif,
        name: "",
        lastName: "",
        email: "",
        emailRe: "",
        telephone: "",
        city: "",
        province: "",
        country: "",
        rol: "",
        language: this.session.user.language,
        fieldSeparator: this.session.user.fieldSeparator,
        unitTemperature: this.session.user.unitTemperature,
        dateTimeFormat: this.session.user.dateTimeFormat,
        dateTimeZone: this.session.user.dateTimeZone,
        authorisation: {
          availableModules: {}
        }
      },      
    rol:[],
    selectedModule:''
  }
  },
  mounted() {
    const availableModules = Object.keys(this.session.authorisation.availableModules);    
    if (availableModules.length === 1) {
      this.selectedModule = availableModules[0];  
    } 
  },
  emits: ["toggleNewUserModal"],
  methods: {
    async onCreateNewUser() {
      let valid = true;
      if (this.newUserData.email != this.newUserData.emailRe
      || this.newUserData.email === ""
      || this.newUserData.emailRe === ""
      || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.newUserData.email))
      || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.newUserData.emailRe) ) 
      ) {
        $("#inputNewUserEmail").get()[0].classList.add("missing")
        $("#inputNewUserEmailRe").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newUserData.name === "") {
        $("#inputNewUserName").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newUserData.lastName === "") {
        $("#inputNewUserLastName").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newUserData.rol === "") {
        $("#selectNewUserRol").get()[0].classList.add("missing")
        valid = false;
      }
      if(this.newUserData.lastName===""){       
        $("#selectNewUserModuleRol").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewUserName").get()[0].classList.remove("missing")
          $("#inputNewUserLastName").get()[0].classList.remove("missing")
          $("#inputNewUserEmail").get()[0].classList.remove("missing")
          $("#inputNewUserEmailRe").get()[0].classList.remove("missing")
          $("#selectNewUserRol").get()[0].classList.remove("missing")
          $("#selectNewUserModuleRol").get()[0].classList.remove("missing")
          
        }, 3000);
        return;
      }
      let response = await api.postNewUser(this.newUserData);      
      if (response["email"] === this.newUserData.email) {
        this.$emit('toggleNewUserModal');
        window.alert("El usuario ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear el usuario.");
      }
    },
    async checkRolByModules(module){
      this.rol=[]
      let userRol= this.session.user.rol
      for (let selectedRol in this.modules[module]) {
      if ((parseInt(userRol) | parseInt(selectedRol)) === parseInt(userRol)) {
      let rolInfo = {
        numero: selectedRol,
        nombre: this.modules[module][selectedRol]
      };      
      this.rol.push(rolInfo);
      }
    }     
    }
  },
}
</script>

<style>

#newUserModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>